import MakeupClass from 'class/makeupClass/MakeupClass';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<MakeupClass>
 */
export default class MakeupClassService extends BaseService {
    static urlBase = "reposicao-de-aula";
    constructor() {
        super(MakeupClassService.urlBase);
    }
}