import * as React from 'react';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputLabel,
  Grid,
  OutlinedInput,
  Button
} from '@mui/material';
import AddressService from 'service/verbify/address/AddressService';
import Address from 'class/address/Address';
import { LoadingButton } from '@mui/lab';

export default function FormAddress({ stateAddress, callback }) {
  const state = React.useState(new Address());
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [address, setAddress] = stateAddress ?? state
  const addressService = new AddressService();

  function searchAddressByCep(cep) {
    addressService.searchAddressByCep(cep)
      .then(resp => {
        const result = new Address(address).formatAddressClass(resp.data);
        setAddress(result);
      })
  }

  function save(e) {
    e.preventDefault();
    e.stopPropagation();

    setButtonLoading(true);

    const isUpdate = address?.id != undefined && address?.id != null;

    isUpdate ? addressService.update(address) : addressService.insert(address)
      .then(resp => {
        if (resp?.data?.model?.id > 0) setAddress({
          ...address,
          id: resp.data.model.id
        })
        if (callback) callback(resp.data.model.id)
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        if (setOpenModal) setOpenModal(false);
        setButtonLoading(false);
      });
  }
  return (
    <>
      <form
        onSubmit={save.bind(this)}
      >
        <Grid container>
          <Grid item xl={6} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-name">Titulo do Endereço</InputLabel>
              <OutlinedInput
                type="text"
                label="Titulo do Endereço"
                value={address?.title}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    title: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={6} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-postal-code">CEP</InputLabel>
              <OutlinedInput
                type="text"
                label='CEP'
                // onBlur={searchAddressByCep}
                value={address?.postalCode || ''}
                onChange={(e) => {
                  if(e.target.value.length === 8) searchAddressByCep(e.target.value)
                  else setAddress({
                    ...address,
                    postalCode: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={10} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-public-place">Logradouro</InputLabel>
              <OutlinedInput
                type="text"
                label='Logradouro'
                value={address?.publicPlace || ''}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    publicPlace: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={2} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-public-place">Número</InputLabel>
              <OutlinedInput
                type="text"
                label='Número'
                value={address?.number || ''}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    number: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={5} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-district">Bairro</InputLabel>
              <OutlinedInput
                type="text"
                label='Bairro'
                value={address?.district || ''}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    district: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={5} md={8} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-city">Cidade</InputLabel>
              <OutlinedInput
                type="text"
                label='Cidade'
                value={address?.city || ''}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    city: e.target.value
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={2} md={4} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="address-state">UF</InputLabel>
              <OutlinedInput
                type="text"
                value={address?.state || ''}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    state: e.target.value
                  });
                }}
                label='UF' />
            </FormControl>
          </Grid>
          <Grid item padding={0.7} xl={12} md={12}>
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={buttonLoading}
              fullWidth
            >{"Salvar"}</LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
