import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import { useState } from "react";
import { Box } from '@mui/material';
import FormSearchMysPerson from '../FormSearchMysPerson';
import FormSeachLanguage from 'ui-component/populated/secretery/language/FormSeachLanguage';
import PersonLanguage from 'class/person/language/PersonLanguage';
import PersonLanguageService from 'service/verbify/user/person/language/PersonLanguageService';
/**
 * @param {Object} props 
 * @param {number?} props.id
 * @param {(item: Task) => void} props.updateList
 * @returns {React.JSX.Element}
 */
export default function FormPersonLanguage({ statePersonLanguage, data, updateList, callback }) {
    const personLanguageService = new PersonLanguageService();

    /**
     * @type {[PersonLanguage, (personLanguage:PersonLanguage) => void]}
     */
    const state = useState(data ?? new PersonLanguage());
    const [personLanguage, setPersonLanguage] = statePersonLanguage ?? state;

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();

        setButtonLoading(true);

        const isUpdate = personLanguage?.id != undefined && personLanguage?.id != null;

        (isUpdate ? personLanguageService.update(personLanguage) : personLanguageService.insert(personLanguage))
            .then((response) => {
                const data = {
                    ...personLanguage,
                    id: response?.data?.model?.id
                }
                if (callback) callback(isUpdate ? JSON.parse(JSON.stringify(personLanguage)) : data)
                if (updateList) updateList(isUpdate ? JSON.parse(JSON.stringify(personLanguage)) : data)
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >
                <Stack direction={'row'} flexWrap={"wrap"} gap={1.5}>

                    <Box
                        width={"49%"}
                    >
                        <FormSeachLanguage
                            label="Idioma"
                            language={personLanguage?.language}
                            setLanguage={(language) => {
                                setPersonLanguage(prev => {
                                    return {
                                        ...prev,
                                        language: { ...language }
                                    }
                                })
                            }}
                        ></FormSeachLanguage>
                    </Box>
                    <Box
                        width={"49%"}
                    >
                        <FormSearchMysPerson
                            label="Responsavel"
                            person={personLanguage?.person}
                            setPerson={(person) => {
                                setPersonLanguage(prev => {
                                    return {
                                        ...prev,
                                        person: { ...person }
                                    }
                                })
                            }}
                        />
                    </Box>
                    <TextField
                        required
                        multiline
                        rows={5}
                        sx={{ width: "100%" }}
                        value={personLanguage?.languageExperiences || ''}
                        onChange={(e) => setPersonLanguage(prev => {
                            return {
                                ...prev,
                                languageExperiences: e.target.value
                            }
                        })}
                        label="Experiencias no Idioma"
                        placeholder="Experiencias no Idioma"
                        variant="outlined"
                    ></TextField>
                    <TextField
                        required
                        multiline
                        rows={5}
                        sx={{ width: "100%" }}
                        value={personLanguage?.topicsOfInterest || ''}
                        onChange={(e) => setPersonLanguage(prev => {
                            return {
                                ...prev,
                                topicsOfInterest: e.target.value
                            }
                        })}
                        label="Como você gostaria de aprender"
                        placeholder="Como você gostaria de aprender"
                        variant="outlined"
                    ></TextField>
                </Stack>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Stack>
        </form>
    );
}