import ObjectResponseList from "class/ObjectResponseList";
import LeiComplementar116 from "class/omie/servico/LeiComplementar116";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class LeiComplementar116Service extends BaseService {
    /**
     * @private
     */
    static _baseUrl = 'omie/servico/leiComplmentar116';
    constructor () {
        super(LeiComplementar116Service._baseUrl);
    }
    /**
     * @returns {Promise<import("axios").AxiosResponse<ObjectResponseList<LeiComplementar116>, any>>}
     */
    list () {
        return verbify.get(`${this.baseUrl}`);
    }
}