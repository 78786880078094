import ObjectRequest from "class/ObjectRequest";
import Item from "class/schedule/Item";
import ClasseModule from "class/secretary/classe/ClasseModule";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import { useEffect, useState } from "react";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import StudentModuleService from "service/verbify/user/student/StudentModuleService";
import DataGridAbsence from "./absence/DataGridAbsence";
import FrequencySheetService from "service/verbify/secretary/frequency/FrequencySheetService";
import { LoadingButton } from "@mui/lab";
import { Box, FormControlLabel, Grid, Stack, Switch, Typography, useTheme } from "@mui/material";
import AbsenceService from "service/verbify/secretary/frequency/AbsenceService";
import Assessment from "class/secretary/frequency/assessment/Assessment";
import AssessmentService from "service/verbify/secretary/frequency/assessment/AssessmentService";
import RichText from "ui-component/richText/RichText";
import FormAbsence from "./absence/FormAbsence";
import FormItem from "ui-component/populated/schedule/item/FormItem";
import TypeService from "service/verbify/schedule/item/TypeService";
import Absence from "class/secretary/frequency/Absence";

/**
 * @param {Object} props 
 * @param {Item} props.item
 * @param {(classeModule) => void} props.climbDiceClasseModule
 */
export default function FormFrequencySheet({
    itemData,
    climbDiceClasseModule
}) {
    /** @type {[FrequencySheet, React.Dispatch<React.SetStateAction<FrequencySheet>>]} */
    const [frequencySheet, setFrequencySheet] = useState(new FrequencySheet());

    /** @type {[Assessment, React.Dispatch<React.SetStateAction<Assessment>>]} */
    const [assessment, setAssessment] = useState(new Assessment());

    /** @type {[Item, React.Dispatch<React.SetStateAction<Item>>]} */
    const [item, setItem] = useState(itemData ?? new Item());

    /** @type {[Absence, React.Dispatch<React.SetStateAction<Absence>>]} */
    const [absence, setAbsence] = useState(new Absence());

    /** @type {[ClasseModule|null, React.Dispatch<React.SetStateAction<ClasseModule|null>>]} */
    const [classeModule, setClasseModule] = useState(null);

    /** @type {[ClasseModule|null, React.Dispatch<React.SetStateAction<ClasseModule|null>>]} */
    const [buttonLoading, setButtonLoading] = useState(false);

    /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
    const [loading, setLoading] = useState(false);

    /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
    const [pageLoading, setPageLoading] = useState(false);

    const classeModuleService = new ClasseModuleService();
    const frequencySheetService = new FrequencySheetService();
    const assessmentService = new AssessmentService();
    const typeService = new TypeService();
    const absenceService = new AbsenceService(frequencySheet);
    /**
     * 
     * @param {FrequencySheet} frequencySheet 
     */
    async function searchAssessment(frequencySheet) {
        setLoading(true);
        try {
            if (frequencySheet.id > 0) {
                const response = await assessmentService.search(frequencySheet.id);
                if (response.data.id > 0) setAssessment(prev => {
                    const data = {
                        ...prev,
                        ...response.data
                    }
                    return data
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    async function initPage() {
        try {
            setLoading(true);

            let initFrequencySheet = new FrequencySheet();
            const requestObject = new ObjectRequest();
            requestObject.perPage = 5000;

            const responseFrequency = await frequencySheetService.buscarPorItem({
                scheduleItem: item,
                date: item.startDate
            });

            const isUpdate = isNumber(responseFrequency.data?.id) && responseFrequency.data?.id > 0;

            if (!isUpdate) {
                initFrequencySheet.scheduleItem = item;
                initFrequencySheet.date = item.startDate.toISOString().substring(0, 10);
                initFrequencySheet.start = item.startTime;
                initFrequencySheet.end = item.endTime;
            }
            else {
                initFrequencySheet = {
                    ...responseFrequency.data
                };
                const absenceService = new AbsenceService({ frequencySheet: initFrequencySheet });
                const responseStudentsAbsence = await absenceService.list(requestObject);
                initFrequencySheet.studentsAbsence = responseStudentsAbsence.data.rows ?? [];
            }
            //#endregion

            const responseClassModule = await classeModuleService.searchFor(
                "/buscar/turma-module",
                initFrequencySheet.scheduleItem.schedule.id,
                "classeModule",
                "fkClasseModuleScheduleId"
            );

            // Subi o classe module para uso em outros componentes
            if (climbDiceClasseModule && responseClassModule.data.totalRows > 0) climbDiceClasseModule(responseClassModule.data.rows[0])

            const dataClasseModule = responseClassModule.data.rows.find(f => f);
            setClasseModule((prev) => {
                return {
                    ...dataClasseModule
                };
            });

            const studentModuleService = new StudentModuleService({ classeModule: dataClasseModule });
            // const teacherModuleService = new TeacherModuleService({ classeModule: dataClasseModule });

            const responseStudents = await studentModuleService.list(requestObject);
            // const responseTeacher = await teacherModuleService.list(requestObject);


            setFrequencySheet((prev) => {
                return {
                    ...prev,
                    ...initFrequencySheet,
                    studentsModule: responseStudents.data.rows//,
                    // teacher: responseTeacher.data.rows.find(f => f).teacher,
                    // teacherPay: responseTeacher.data.rows.find(f => f).price
                }
            });

        } catch (ex) {
            console.log(ex);
        } finally {
            setLoading(false);
            setPageLoading(true)
        }
    }

    useEffect(() => {
        (() => {
            initPage();
            setAbsence(prev => {
                return{
                    ...prev,
                    frequencySheet: frequencySheet,
                    scheduleItem: item,

                }
            })
        })();
    }, []);

    useEffect(() => {
        if (frequencySheet.id > 0) searchAssessment(frequencySheet);
        if (frequencySheet.id > 0) setItem(prev => {
            const data = {
                ...prev,
                id: null,
                title: prev.title + " - Reposição",
                schedule: frequencySheet?.scheduleItem?.schedule,
                teacher: frequencySheet?.teacher,
                type: typeService.search("label", 3),
                dayOfTheWeek: {
                    disabled: true
                }
            }
            console.log("DATADATA", data);
            return data
        })
    }, [frequencySheet.id]);

    /**
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} ev
     * @param {FrequencySheet} data
     */
    function save(ev, fs) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        const data = fs ?? frequencySheet;

        const isUpdate = isNumber(data.id) && data.id > 0;
        setButtonLoading(true);

        (isUpdate
            ? frequencySheetService.update(data)
            : frequencySheetService.insert(data)
        ).then((response) => {
            if (!isUpdate)
                setFrequencySheet((prev) => {
                    return {
                        ...prev,
                        id: response.data.model.id
                    };
                });
        }).catch((ex) => console.log(ex)
        ).finally(() => setButtonLoading(false));

    }

    useEffect(() => {
        console.log("DATAABSENCE", absence);
    }, [absence])
    function callbackSaveItem(itemResult) {

        setButtonLoading(true);

        const data = absence;
            data.scheduleItem = itemResult ?? absence.scheduleItem;

        const isUpdate = isNumber(data.id) && data.id > 0;

        (isUpdate
            ? absenceService.update(data)
            : absenceService.insert(data)
        ).then(resp => {
            if (!isUpdate) setAbsence(prev => {
                return {
                    ...prev,
                    id: resp.data.model.id
                };
            });
        }).catch((ex) => console.log(ex)
        ).finally(() => setButtonLoading(false));
    }
    function saveAssessment(e) {
        e.preventDefault();
        e.stopPropagation();

        const isUpdate = isNumber(assessment.id) && assessment.id > 0;
        setButtonLoading(true);


        (isUpdate
            ? assessmentService.update(assessment)
            : assessmentService.insert(assessment)
        ).then(resp => {
            if (!isUpdate) setAssessment(prev => {
                return {
                    ...prev,
                    id: resp.data.model.id
                };
            });
        }).catch((ex) => console.log(ex)
        ).finally(() => setButtonLoading(false));
    }

    const theme = useTheme();

    return <Box>
        {
            pageLoading &&
            <Grid container xl={12} spacing={2}>
                <Grid item xl={4}>
                    {
                        frequencySheet?.status != 0 &&
                        <Box
                            borderRadius={theme.shape.borderRadius}
                            bgcolor={theme.palette.primary.light}
                            p={2}
                            component={"form"}
                            onSubmit={save.bind(this)}
                        >
                            <Typography variant="h2" mb={1.5} >Código da Folha de Frequencia: {frequencySheet.id}</Typography>
                            {
                                frequencySheet?.id > 0 && <Stack direction={"row"} p={1.5}>
                                    <FormControlLabel
                                        control={<Switch
                                            aria-label="Aula foi dada?"
                                            checked={frequencySheet?.status > 0 ? true : false}
                                            onChange={e => setFrequencySheet(prev => {
                                                const data = {
                                                    ...prev,
                                                    status: e.target.checked
                                                }
                                                save(false, data)
                                                return data
                                            })}
                                        ></Switch>
                                        }
                                        label={"Aula foi dada"}
                                    >
                                    </FormControlLabel>
                                    <FormControlLabel
                                        control={<Switch
                                            aria-label="Foi uma conversa de aprendizagem?"
                                            checked={frequencySheet?.learningConversation > 0 ? true : false}
                                            onChange={e => setFrequencySheet(prev => {
                                                const data = {
                                                    ...prev,
                                                    learningConversation: e.target.checked
                                                }
                                                save(false, data)
                                                return data
                                            })}
                                        ></Switch>
                                        }
                                        label={"Conversa de aprendizagem?"}
                                    >
                                    </FormControlLabel>

                                </Stack>
                            }
                            <RichText
                                height={100}
                                stateContent={[frequencySheet.description, (content) => setFrequencySheet(prev => {
                                    return {
                                        ...prev,
                                        description: content
                                    }

                                })]}
                            ></RichText>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={buttonLoading}
                                sx={{
                                    width: '100%',
                                    mt: 1.5
                                }}
                            >{isNumber(frequencySheet.id) && frequencySheet.id > 0 ? "Atualizar folha de frequência" : "Criar folha de frequência"}</LoadingButton>
                        </Box>
                    }
                    {
                        frequencySheet?.status == 0 && <Box>
                            <Typography variant="h2" mt={1.5} >Motivo do Cancelamento</Typography>
                            <FormAbsence 
                                sx={{
                                    borderRadius: theme.shape.borderRadius,
                                    bgcolor: theme.palette.primary.light,
                                    p: 2,
                                    mt: 1.5
                                }}
                                stateAbsence={[absence, setAbsence]}
                                frequencySheet={frequencySheet}
                            ></FormAbsence>
                        </Box>
                    }
                    {
                        frequencySheet?.status != 0 &&
                        <Box
                            borderRadius={theme.shape.borderRadius}
                            bgcolor={theme.palette.primary.light}
                            p={2}
                            mt={2}
                            component={"form"}
                            onSubmit={saveAssessment.bind(this)}
                        >
                            <Typography variant="h2" mb={1.5} >Código da Avaliação: {assessment.id}</Typography>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={buttonLoading}
                                disabled={frequencySheet.id > 0 ? (assessment.id > 0 ? true : false) : true}
                                style={{ width: '100%' }}
                            >{isNumber(frequencySheet.id) && frequencySheet.id > 0 ? (assessment.id > 0 ? "Avaliação já adicionada" : "Adicionar avaliação") : "Indisponivel, gere a folha de frequencia para poder atribuir as notas"}</LoadingButton>
                        </Box>
                    }
                </Grid >
                {
                    (frequencySheet?.status != null && frequencySheet?.status != 0 && frequencySheet?.status != false) ?
                        <Grid item xl={8}>
                            <DataGridAbsence
                                loading={loading}
                                stateFrequencySheet={[frequencySheet, setFrequencySheet]}
                                stateAssessment={[assessment, setAssessment]}
                            />
                        </Grid>
                        :
                        absence.id > 0 && (absence.absenceType?.allowsReplacement > 0 || absence.absenceType?.allowsReplacement == true) && <FormItem
                            stateItem={[item, setItem]}
                            schedule={frequencySheet?.scheduleItem?.schedule?.id}
                            callback={callbackSaveItem}
                        ></FormItem>
                }
            </Grid>
        }
    </Box>;
}