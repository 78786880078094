import { Box, Button, Stack, TextField } from "@mui/material";
import FormSeachModule from "ui-component/populated/secretery/module/FormSeachModule";
import { useState } from "react";
import ClasseModule from "class/secretary/classe/ClasseModule";
import DurationInput from "ui-component/extended/InputMask/DurationInput";
import { LoadingButton } from "@mui/lab";
import DataGridModuleClasse from "./DataGridModuleClasse";
import Duration from "class/utils/Duration";
import ScheduleService from "service/verbify/schedule/ScheduleService";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import FormSeachClasse from "ui-component/populated/secretery/classe/FormSeachClasse";
import ClasseService from "service/verbify/secretary/ClasseService";
import ObjectResponseList from "class/ObjectResponseList";
import Proposal from "class/proposal/Proposal";
import ProposalService from "service/verbify/proposal/ProposalService";
import FormSearchProposal from "ui-component/populated/proposal/FormSearchProposal";

/**
 * @param {Object} props
 * @param {ClasseModule} props.classeModuleData
 * @param {Proposal} props.proposal
 * @param {(classeModule:ClasseModule) => ObjectResponseList<ClasseModule>} props.updateList
 * @returns 
 */
export default function FormClasseModule({ stateClasseModule, classeModuleData, updateList }) {
    /**
     * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);

    const scheduleService = new ScheduleService();
    const classeModuleService = new ClasseModuleService();
    const classeService = new ClasseService();
    const proposalService = new ProposalService();

    const state = useState(classeModuleData ?? new ClasseModule());
    const [ classeModule, setClasseModule ] = stateClasseModule ?? state;

    /**
     * @param {string} value 
     */
    let setDurationInput = (value) => { };

    async function save(evento) {
        evento.preventDefault();
        evento.stopPropagation();

        try {
            setButtonLoading(true);

            let newClasseModule = { ...classeModule };

            //Verifica se o calendario já está cadastrado, caso não esteja cadastra
            const isUpdateAgenda =
                isNumber(newClasseModule?.schedule?.id)
                && newClasseModule.schedule.id > 0;

            const responseAgenda =
                isUpdateAgenda
                    ? await scheduleService.update(newClasseModule.schedule)
                    : await scheduleService.insert(newClasseModule.schedule);

            if (!isUpdateAgenda)
                newClasseModule.schedule.id = responseAgenda.data.model.id;

            const isUpdate =
                isNumber(newClasseModule?.id)
                && newClasseModule.id > 0;

            const response =
                isUpdate
                    ? await classeModuleService.update(newClasseModule)
                    : await classeModuleService.insert(newClasseModule);

            if (!isUpdate)
                newClasseModule = {
                    ...newClasseModule,
                    id: response.data.model.id
                };

            if (setItem) setItem(newClasseModule);

            updateList(newClasseModule);

        } catch (ex) {
            console.log(ex);
        } finally {
            setButtonLoading(false);
        }
    }

    return <form
        onSubmit={save.bind(this)}
    >
        <Stack
            spacing={2}
            marginTop={1}
        >
            {(classeModule.classe == undefined && <FormSeachClasse
                classe={classeModule.classe ?? classe}
                setClasse={(newClasse) => {
                    setClasseModule(prev => {
                        return {
                            ...prev,
                            classe: newClasse
                        }
                    })
                }}
                loadList={(...props) => classeService.searchFor(...props)}
                required
            ></FormSeachClasse>)}
            <TextField
                fullWidth
                value={classeModule.schedule.title}
                onChange={(e) => setClasseModule(prev => {
                    return {
                        ...prev,
                        schedule: {
                            ...prev.schedule,
                            title: e.target.value
                        }
                    }
                })}
                label="Nome"
                placeholder="Nome"
                variant="outlined"
                inputProps={{ maxLength: 45 }}
            ></TextField>

            <FormSeachModule
                label="Nível"
                required={true}
                module={classeModule.module}
                setModule={(module) => {
                    setClasseModule(prev => {
                        return {
                            ...prev,
                            module: module
                        }
                    })
                }}
            ></FormSeachModule>
            <FormSearchProposal
                proposal={classeModule.proposal}
                loadList={(searchPatch, search, searchTable, searchColumn) => proposalService.searchFor(searchPatch, search, searchTable, searchColumn)}
                setProposal={proposal => {
                    setClasseModule(prev => {
                        return {
                            ...prev,
                            proposal: proposal
                        }
                    })
                }}
            ></FormSearchProposal>
            <DurationInput
                duration={classeModule.duration}
                setDuration={(duration) => {
                    setClasseModule(prev => {
                        return {
                            ...prev,
                            duration: new Duration(duration)
                        }
                    })
                }}
                setDurationInput={(action) => setDurationInput = action}
                label={'Duração'}
                placeholder={'Duração'}
            />
            <LoadingButton
                type="submit"
                variant="outlined"
                loading={buttonLoading}
            >{"Salvar"}</LoadingButton>
        </Stack>
    </form>
}