import { LoadingButton } from "@mui/lab";
import { Box, FormControlLabel, Grid, Switch, Typography, useTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Stack, display } from "@mui/system";
import { GlobalContext } from "GlobalContext";
import User from "class/person/user/User";
import { useContext } from "react";
import { useEffect, useState } from "react";
import UserService from "service/verbify/user/UserService";
import InputPhone from "ui-component/InputPhone";
import FormAddress from "ui-component/populated/address/FormAddress";

/**
 * @param {{id?:number, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormUser({ stateUser, userData, setOpenModal }) {
    const userService = new UserService();
    /**
     * @type {[User, (user:User) => void]}
     */
    const { person, setPerson } = useContext(GlobalContext).person
    const [user, setUser] = useState(new User());
    useEffect(() => {
        console.log(userData);
        setUser(userData);
    }, [])
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();

        setButtonLoading(true);

        const isUpdate = user?.id != undefined && user?.id != null;

        (isUpdate ? userService.update(user) : userService.insert(user))
            .then((response) => {
                if (setOpenModal) setOpenModal(false);
                setItem(isUpdate ? user : response.data.model);
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(function () {
        if (userData) setUser(userData);
        //Carraga os dados da languagem caso tenha o id
        if (user?.id != undefined && user?.id != null && isNumber(user?.id))
            userService
                .search(user?.id)
                .then(response => {
                    setUser(response.data)
                })
                .catch(ex => { })
                .finally();
    }, []);
    const theme = useTheme()
    return (<>
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                mt={2}
                mb={2}
                direction={'row'}
            >

                <TextField
                    sx={{ width: "50%" }}
                    required
                    fullWidth
                    value={user?.email}
                    onChange={(e) => setUser(prev => {
                        return {
                            ...prev,
                            email: e.target.value
                        }
                    })}
                    label="E-mail"
                    placeholder="E-mail"
                    variant="outlined"
                    type="email"
                    inputProps={{ maxLength: 45 }}
                ></TextField>
                <InputPhone
                    width="50%"
                    label="Whatsapp"
                    value={user?.whatsapp}
                    handleChange={
                        (value) => setUser(prev => {
                            return {
                                ...prev,
                                whatsapp: value
                            }
                        })
                    }
                >
                </InputPhone>
            </Stack>
            {
                person?.user?.admin > 0 && <Box>
                    <Typography variant="h3" mb={1} >Tipos de perfil</Typography>
                    <Grid container xl={12} gap={1} mb={2}>
                        <Grid item xl={3} sx={{ p: 1, bgcolor: "primary.light", color: "grey.900", borderRadius: theme.shape.borderRadius }}><FormControlLabel control={<Switch checked={user?.student > 0 ? true : false} onChange={(e) => {
                            setUser(prev => {
                                return { ...prev, student: e.target.checked }
                            })
                        }}></Switch>} label={"Aluno"}></FormControlLabel></Grid>
                        <Grid item xl={3} sx={{ p: 1, bgcolor: "primary.light", color: "grey.900", borderRadius: theme.shape.borderRadius }}><FormControlLabel control={<Switch checked={user?.employee > 0 ? true : false} onChange={(e) => {
                            setUser(prev => {
                                return { ...prev, employee: e.target.checked }
                            })
                        }}></Switch>} label={"Funcionario / RH"}></FormControlLabel></Grid>
                        <Grid item xl={3} sx={{ p: 1, bgcolor: "primary.light", color: "grey.900", borderRadius: theme.shape.borderRadius }}><FormControlLabel control={<Switch checked={user?.teacher > 0 ? true : false} onChange={(e) => {
                            setUser(prev => {
                                return { ...prev, teacher: e.target.checked }
                            })
                        }}></Switch>} label={"Professor"}></FormControlLabel></Grid>
                        <Grid item xl={3} sx={{ p: 1, bgcolor: "primary.light", color: "grey.900", borderRadius: theme.shape.borderRadius }}><FormControlLabel control={<Switch checked={user?.admin > 0 ? true : false} onChange={(e) => {
                            setUser(prev => {
                                return { ...prev, admin: e.target.checked }
                            })
                        }}></Switch>} label={"Administrador"}></FormControlLabel></Grid>
                    </Grid>
                </Box>
            }

            <LoadingButton
                type="submit"
                variant="outlined"
                loading={buttonLoading}
                fullWidth
            >{"Salvar"}</LoadingButton>
        </form>
    </>

    );
}