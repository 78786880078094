export default class User {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    email = '';
    /**
     * @type {string}
     * @public
     */
    whatsapp = '';
    /**
     * @type {number}
     * @public
     */
    status = 0;
    /**
     * @type {boolean}
     * @public
     */
    student = false;
    /**
     * @type {boolean}
     * @public
     */
    employee = false;
    /**
     * @type {boolean}
     * @public
     */
    teacher = false;
    /**
     * @type {boolean}
     * @public
     */
    admin = false;
}