import { Alert } from "@mui/material";
import axios from "axios";
import Cookies from 'js-cookie';
import { createRoot } from "react-dom/client";

axios.defaults.withCredentials = true;

const verbify = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

verbify.interceptors.request.use((request) => {
    request.maxBodyLength = Infinity;
    request.headers.setContentType('application/json');
    request.headers.setAuthorization('Bearer ' + Cookies.get('token'));
    if (request.data) request.data = JSON.stringify(request.data);
    return request;
})


verbify.interceptors.response.use((response) => {
    const method = ['put', 'post', 'delete'];
    if (method.includes(response.config.method)) {
        
        const containerElement = document.getElementById('alerts');
        const notificationElement = document.createElement("div");
        containerElement.appendChild(notificationElement);

        response.status 
        const root = createRoot(notificationElement);
        root.render(
            response.data.success || (response.config.method == "delete" && response.status == 200) ? <Alert  severity="success" sx={{ width: 250 }}>
                Sucesso
            </Alert>
            :
            <Alert  severity="error" sx={{ width: 250 }}>
                Erro! Tente mais tarde ou contate o suporte se o erro per
            </Alert>
        );

        setTimeout(() => {
            root.unmount();
            containerElement.removeChild(notificationElement);
        }, 5000);
    }
    return response;
});

export default verbify;