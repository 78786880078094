import DialogBase from "ui-component/populated/base/DialogBase";
import FormStudentModule from "ui-component/populated/user/student/module/FormStudentModule";
import DataGridStudentModule from "./DataGridStudentModule";
import ObjectResponseList from "class/ObjectResponseList";
import StudentModule from "class/person/student/StudentModule";
import { useState } from "react";

export default function DialogFormStudentModule({
    title,
    buttonOpenModal,
    classeModule
}) {
    /**
     * @type {[ObjectResponseList<StudentModule>, React.Dispatch<ObjectResponseList<StudentModule>>]}
     */
    const [ studentsModules, setStudentsModules ] = useState(new ObjectResponseList());
     /**
     * @type {[ObjectResponseList<StudentModule>, React.Dispatch<ObjectResponseList<StudentModule>>]}
     */
    const [ studentClasse, setStudentClasse ] = useState(new StudentModule());
    /**
     * @param {StudentModule} studentClasseParam 
     */
    function updateList(studentModuleParam) {
        const index = studentsModules.rows.findIndex(any => any.id == studentModuleParam.id);
        if (index > -1)
            setStudentsModules(prev => {
                let newList = [ ...prev.rows ];
                newList[index] = studentModuleParam;
                return {
                    ...prev,
                    rows : newList
                };
            });
        else
            setStudentsModules(prev => {
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        studentModuleParam,
                        ...prev.rows
                    ]
                };
            });
    }
    return <DialogBase title={title} buttonOpenModal={buttonOpenModal}>
        <div style={{ margin : "30px" }}>
            <FormStudentModule classeModule={classeModule} stateStudentModule={[studentClasse, setStudentClasse]} updateList={updateList}></FormStudentModule>
            <div style={{ margin : '10px 0px' }}>
                <DataGridStudentModule classeModule={classeModule} stateStudentModule={[studentClasse, setStudentClasse]} stateList={[ studentsModules, setStudentsModules ]}></DataGridStudentModule>
            </div>
        </div>
    </DialogBase>;
}