import Absence from "class/secretary/frequency/Absence";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

/**
 * @extends {BaseService<Absence>}
 */
export default class AbsenceService extends BaseService {
    static urlBase = "folha-de-frequencia/{frequencySheet}/turma-modulo-aluno";
    /** 
     * @param {Object} props
     * @param {FrequencySheet} props.frequencySheet
     */
    constructor({
        frequencySheet
    }) {
        super(AbsenceService.urlBase.replace('{frequencySheet}', (frequencySheet?.id ?? 0).toString()));
    }

    /**
     * @param {Absence} absence 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update(absence) {
        console.log("update: ", absence);
        return verbify.put(`${AbsenceService.urlBase.replace('{frequencySheet}', absence.frequencySheet.id.toString())}/${absence.classeModuleStudent?.id?.toString()}`, {
            ...absence,
            hoursWorked : absence.hoursWorked.padEnd(8, ':00')
        });
    }

    /**
     * @param {number} frequencySheetId 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    showByFrequencySheet(frequencySheetId) {
        return verbify.get(`/folha-de-frequencia/${frequencySheetId}/ausencia/`);
    }
}