export default class Address {
    constructor(endereco) {
        if(!endereco) return; 
        this.title = endereco?.title;
        this.publicPlace = endereco?.publicPlace;
        this.district = endereco?.district;
        this.city = endereco?.city;
        this.ibgeCity = endereco?.ibgeCity;
        this.state = endereco?.state;
        this.number = endereco?.number;
        this.postalCode = endereco?.postalCode;
    }
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = null;
    /**
     * @type {string}
     * @public
     */
    publicPlace = null;
    /**
     * @type {string}
     * @public
     */
    complement = null;
    /**
     * @type {string}
     * @public
     */
    district = null;
    /**
     * @type {string}
     * @public
     */
    city = null;
    /**
     * @type {string}
     * @public
     */
    ibgeCity = null;
    /**
     * @type {string}
     * @public
     */
    state = null;
    /**
     * @type {string}
     * @public
     */
    postalCode = null;
    /**
    * @type {string}
    * @public
    */
    number = null;

    formatAddressClass({ logradouro, bairro, localidade, uf, cep, ibge }) {
        this.publicPlace = logradouro;
        this.district = bairro;
        this.city = localidade;
        this.ibgeCity = ibge;
        this.state = uf;
        this.postalCode = cep;
        return this
    }
}