import { IconButton } from "@mui/material";
import { IconPencil } from "@tabler/icons";
import ObjectRequest from "class/ObjectRequest";
import ObjectResponseList from "class/ObjectResponseList";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import { useState } from "react";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import { formatNumberCurrency } from "utils/global";
import ModalFormFrequencySheet from "./ModalFormFrequencySheets";
import DialogFrequencySheet from "./DialogFrequencySheet";

/**
 * @type {import("@mui/x-data-grid").GridColDef<FrequencySheet, any, any>[]}
 */
const columns = [
    { field: 'id', headerName: 'Código', width: 70 },
    { field : 'ff', headerName: 'Editar', width: 70, renderCell: ({row}) => {
            return <DialogFrequencySheet id={row?.id} label={<IconButton><IconPencil></IconPencil></IconButton>}></DialogFrequencySheet>
        } 
    },
    { field: 'date', headerName: 'Data', valueGetter : (params) => (typeof params.row.date === 'string' ? new Date(params.row.date + " 00:00:00") : params.row.date).toLocaleDateString(), width: 100 },
    { field: 'start', headerName: 'Início', width: 100 },
    { field: 'end', headerName: 'Fim', width: 100 },
    { field: 'totalValue', headerName: 'Valor total (R$)', valueGetter: (params) => formatNumberCurrency.format(params.row.teacherPay), width: 140 },
    { field: 'value', headerName: 'Valor hora (R$)', valueGetter: (params) => new Intl.NumberFormat('pt-Br', { style: 'currency', currency : 'BRL' }).format(Number(params.row.teacherPay)), width: 140 },
    { field : 'classe', headerName: 'Classe', width: 250, valueGetter: (params) => `${params.row.classeModuleTeacher?.classeModule?.classe?.title} - ${params.row.classeModuleTeacher?.classeModule?.module.title} (${params.row.classeModuleTeacher?.classeModule?.module?.language?.title})` },
    { field : 'scheduleItem', headerName: 'Reagendar', width: 250, valueGetter: (params) => `${params.row.classeModuleTeacher?.classeModule?.classe?.title} - ${params.row.classeModuleTeacher?.classeModule?.module.title} (${params.row.classeModuleTeacher?.classeModule?.module?.language?.title})` },
    { field : 'ff', headerName: 'Editar FF', width: 250, renderCell: ({row}) => {
        return <DialogFrequencySheet id={row?.id} label={<IconButton><IconPencil></IconPencil></IconButton>}></DialogFrequencySheet>
    } }
];
/**
 * 
 * @param {Object} props 
 * @param {[FrequencySheet[], React.Dispatch<React.SetStateAction<FrequencySheet[]>>]} props.stateList
 * @param {(page: ObjectRequest) => Promise<import("axios").AxiosResponse<ObjectResponseList<FrequencySheet>, any>>} props.loadList
 * @returns 
 */
export default function DataGridFrequencySheet({
    loadList,
    stateList: stateListParam,
    height,
    ...props
}) {
    /**
     * @type {[FrequencySheet[], React.Dispatch<React.SetStateAction<FrequencySheet[]>>]} 
     */
    const stateList = useState([]);

    const [ FrequencySheets, setFraquencySheets ] = stateListParam == null ? stateList : stateListParam;
    /**
     * @param {FrequencySheet} frequencySheet 
     */
    function getKey(frequencySheet) {
        return frequencySheet.id;
    }

    loadList = loadList ?? function (page) {
        throw new Error("Metodo \"loadList\" não foi implementado.");
    };

    return <DataGridBase
        height={height}
        keyExtractor={getKey} 
        columns={columns}
        functionListItens={loadList}
        stateItens={[FrequencySheets, setFraquencySheets]}
        mapRow={(row) => Object.assign(new FrequencySheet(), row)}
    />
}