import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import Ace from "class/ace/Ace";
import { useEffect, useState } from "react";
import Person from "class/person/Person";
import ClasseModuleStudentPriceService from "service/verbify/secretary/classe/module/student/price/ClasseModuleStudentPriceService";
import ClasseModuleStudentPrice from "class/secretary/classe/student/price/ClasseModuleStudentPrice";
import InputCurrency from "ui-component/extended/InputCurrency";
import { TextField } from "@mui/material";

/**
 * @param {Object} props 
 * @param {number?} props.id
 * @param {(item: Ace) => void} props.setItem
 * @param {Person} props.person
 * @param {ClasseModuleStudentPrice} props.classeModuleStudentPriceData
 * @returns {React.JSX.Element}
 */
export default function FormClasseModuleStudentPrice({ id, setItem, item, classeModuleStudentPriceData }) {
    /**
     * @type {[ClasseModuleStudentPrice, (classeModuleStudentPrice:ClasseModuleStudentPrice) => ClasseModuleStudentPrice]}
     */
    const [classeModuleStudentPrice, setClasseModuleStudentPrice] = useState(classeModuleStudentPriceData ?? new ClasseModuleStudentPrice());

    const classeModuleStudentPriceService = new ClasseModuleStudentPriceService(classeModuleStudentPriceData);


    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();
        evento.stopPropagation();

        setButtonLoading(true);

        const isUpdate = classeModuleStudentPrice.id != undefined && classeModuleStudentPrice.id != null;

        (isUpdate ? classeModuleStudentPriceService.update(classeModuleStudentPrice) : classeModuleStudentPriceService.insert(classeModuleStudentPrice))
            .then((response) => {
                setItem(isUpdate ? classeModuleStudentPrice : { ...classeModuleStudentPrice, id: response?.data?.model?.id });
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(function () {
        console.log("TESTANDOSTUDENTPRICE", classeModuleStudentPriceData);
        if (classeModuleStudentPriceData?.classeModuleStudent?.id > 0 && !classeModuleStudentPrice?.id > 0)
            classeModuleStudentPriceService
                .search(id, "item")
                .then(response => { 
                    if(response?.data?.id > 0) setClasseModuleStudentPrice(prev => {
                        return {
                            ...prev,
                            ...response.data,
                            classeModuleStudent: {
                                ...prev.classeModuleStudent,
                                id: response.data?.classeModuleStudent?.id
                            }
                        }
                    })
                })
                .catch(ex => { })
                .finally();
        else if (classeModuleStudentPriceData?.id > 0) setClasseModuleStudentPrice(classeModuleStudentPriceData)
    }, []);

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >
                <InputCurrency
                    label={"Valor total"}
                    stateValue={[classeModuleStudentPrice.totalValue, (value) => setClasseModuleStudentPrice(prev => {
                        return {
                            ...prev,
                            totalValue: value
                        }
                    })]}
                ></InputCurrency>

                <TextField
                    label={"Percentual do estudande"}
                    required
                    type="number"
                    inputProps={{
                        min: "0",
                        max: "100",
                        step: "5"
                    }}
                    onChange={(e) => {
                        setClasseModuleStudentPrice(prev => {
                            if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0)
                                prev.percentageOfStudent = Number(e.target.value);
                            return {
                                ...prev
                            };
                        });
                    }}
                    value={classeModuleStudentPrice?.percentageOfStudent || 0}
                ></TextField>

                <TextField
                    label={"Quantidade de Parcelas"}
                    required
                    type="number"
                    onChange={(e) => {
                        setClasseModuleStudentPrice(prev => {
                            return {
                                ...prev,
                                installmentOfStudent: e.target.value
                            };
                        });
                    }}
                    value={classeModuleStudentPrice?.installmentOfStudent || 0}
                ></TextField>
                <InputCurrency
                    label={"Valor total AVA"}
                    stateValue={[classeModuleStudentPrice.totalValueAVA, (value) => setClasseModuleStudentPrice(prev => {
                        return {
                            ...prev,
                            totalValueAVA: value
                        }
                    })]}
                ></InputCurrency>

                <TextField
                    label={"Percentual do estudande AVA"}
                    required
                    type="number"
                    inputProps={{
                        min: "0",
                        max: "100",
                        step: "5"
                    }}
                    onChange={(e) => {
                        setClasseModuleStudentPrice(prev => {
                            if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0)
                                prev.percentageOfStudentAVA = Number(e.target.value);
                            return {
                                ...prev
                            };
                        });
                    }}
                    value={classeModuleStudentPrice?.percentageOfStudentAVA || 0}
                ></TextField>

                <TextField
                    label={"Quantidade de Parcelas AVA"}
                    required
                    type="number"
                    onChange={(e) => {
                        setClasseModuleStudentPrice(prev => {
                            return {
                                ...prev,
                                installmentOfStudentAVA: e.target.value
                            };
                        });
                    }}
                    value={classeModuleStudentPrice?.installmentOfStudentAVA || 0}
                ></TextField>

                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Stack>
        </form>
    );
}