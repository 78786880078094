// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'GlobalContext';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [ menuAdmin ] = useContext(GlobalContext).menus
  const { person } = useContext(GlobalContext).person
  const [ menu, setMenu ] = useState(menuItem)

  useEffect(() => {
    setMenu(prev => {
      const items = {...prev}
      const i = items.items.find(item => {
        if(item.id === 'school') item.children = menuAdmin?.children;
        return item.id === 'school';
      })

      if(!i) {
        items.items.splice(1, 0, menuAdmin)
      }
      return items
    })
  }, [person?.id])

  const navItems = menu.items.map((item) => {
    if (item === null || item === undefined) return
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
