import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

/**
 * @extends {BaseService<FrequencySheet>}
 */
export default class FrequencySheetService extends BaseService {
    static urlBase = "folha-de-frequencia";
    constructor() {
        super(FrequencySheetService.urlBase);
    }
    /**
     * 
     * @param {ObjectRequest} param0 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    vListFrequencySheetByClasse({
        column,
        order,
        page,
        perPage
    }, classeModuleId, status) {
        return verbify.get(`vListFrequencySheetByClasse?column=${column}&order=${order}&page=${page}&perPage=${perPage}&classeModule=${classeModuleId}&status=${status}`);
    }
    /**
     * @param {FrequencySheet} folha
     */
    buscarPorItem(folha) {
        return verbify.get(`${FrequencySheetService.urlBase}\\item?item=${folha.scheduleItem.id}&data=${folha.date.toJSON().substring(0, 10)}`);
    }
    /**
     * @param {Object} props
     * @param {Array<number>} props.idFrequencys
     * @returns {Promise<import("axios").AxiosResponse<FrequencySheet, any>>} 
     */
    listFrequencyIds({
        idFrequencys
    }) {
        return verbify.get(`${FrequencySheetService.urlBase}\\pagamento?frequencyIds=${encodeURI(idFrequencys)}`);
    }
}