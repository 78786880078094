export default class Language {
    /**
     * @type {number}
     * @public
     */
    id = null;
     /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {string}
     * @public
     */
    description = '';
}