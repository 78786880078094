export default class Document {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    name = "";
    /**
     * @type {string}
     * @public
     */
    codigo = "";
    /**
     * Número do documento
     * @type {string}
     * @public
     */
    type = "";
    /**
     * @type {string}
     * @public
     */
    urlFile = "";

    setDocument (document) {
        console.log(this, document);
    }
}
