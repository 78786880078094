import { lazy } from "react";

import Loadable from 'ui-component/Loadable';
import MainLayout from "layout/MainLayout";
import ProfileStudent from "views/profiles/ProfileStudent";
import BillsToPay from "views/profiles/billsToPay";
import BillToPay from "views/profiles/billsToPay";
import PayTheBill from "views/payment";
import MyPersonView from "views/profiles/MyPersonView";
import MyCustomView from "views/profiles/customer/MyCustomerView";
import PersonlanguageView from "views/profiles/personLanguage/PersonLanguageView";

const ProfileUser = Loadable(lazy(() => import('views/profiles/ProfileUser')))
const ProfileTeacher = Loadable(lazy(() => import('views/profiles/ProfileTeacher')))
const ProfileEmployee = Loadable(lazy(() => import('views/profiles/ProfileEmployee')))
const AddUser = Loadable(lazy(() => import('views/profiles/AddUser')))

const ProfileRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        {
            path: '/usuario',
            element: <MyPersonView />
        },
        {
            path: '/usuario/cliente',
            element: <MyCustomView />
        },
        {
            path: '/usuario/novo', 
            element: <AddUser />
        },
        {
            path: '/usuario/experiencia-com-idioma', 
            element: <PersonlanguageView />
        },
        {
            path: '/usuario/professor', 
            element: <ProfileTeacher />
        },
        {
            path: '/usuario/funcionario', 
            element: <ProfileEmployee />
        },
        {
            path: '/usuario/aluno', 
            element: <ProfileStudent />
        },
        {
            path: '/usuario/contas-pagar', 
            element: <BillsToPay />
        },
        {
            path: '/pagamento/:payment',
            element: <PayTheBill />
        }
    ]
}

export default ProfileRoutes;