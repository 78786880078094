/**
 * Agrupa a lista 
 * @template T
 * @template K
 * @param {(item:T) => K} keyExtract 
 * @returns {{ key:K, itens:T[] }}
 */
Array.prototype.groupBy = function (keyExtract) {
    return this.reduce((acumulador, atual, index, vetor) => {
        //Obtem a chave o item atual
        const key = keyExtract(atual);

        //Verifica se o item existe
        const indexKey = acumulador.findIndex(f => f.key == key);

        //Caso a chave não exista cria uma nova
        if (indexKey < 0) {
            const newKey = {
                key: key,
                itens: [atual]
            };
            acumulador.push(newKey);
        }
        else //Caso exista apenas adiciona o item a chave existente
            acumulador[indexKey].itens.push(atual);

        //Retorna o acumulador
        return acumulador;
    }, []);
};
/**
 * Remove um item da lista 
 * @template T
 * @param {(value: T, index: number, obj: T[]) => number} indexExtract
 * @returns {T}
 */
Array.prototype.remove = function (indexExtract) {
    const index = this.findIndex(indexExtract);
    if (index > -1) {
        const itemRemove = this[index];
        this.splice(index, 1);
        return itemRemove;
    }
    return null;
}

/**
 * Indica se o value é um number
 * @param {any} value 
 * @returns {bool}
 */
window.isNumber = (value) => !isNaN(value);


export const formatNumberCurrency = new Intl.NumberFormat('pt-Br', { style: 'currency', currency : 'BRL' });