import { Autocomplete, Box, CircularProgress, FormControl, Grid, TextField, createFilterOptions } from "@mui/material";
import { cloneElement, useEffect, useState } from "react";
import { IconPlus } from '@tabler/icons';
import { AxiosResponse } from "axios";
/**
 * @template T
 * @param {Object} param0
 * @param {string} param0.label 
 * @param {T|null} param0.item
 * @param {(option:T|null) => void} param0.setItem
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<{ rows: D[]}, any>} param0.loadList
 * @param {string} param0.searchPatch Caminho da rota para consultar os dados
 * @param {string} param0.searchTable Tabela que contem a propriedade que será filtrada por searchColumn
 * @param {[string]} param0.outheParams Tabela que contem a propriedade que será filtrada por searchColumn
 * @param {(option:T) => string} param0.valueExtractor
 * @param {(option:T) => string} param0.textExtractor
 * @param {string} [param0.textNotOption=Informe o nome.] param0.textNotOption
 * @param {number} param0.minimumLength
 * @param {boolean} param0.disabled
 * @param {React.JSX.Element} param0.modal
 * @param {[[], React.Dispatch<React.SetStateAction<[]>>]} param0.stateList
 * @param {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} param0.stateLodingParam
 * @returns {React.JSX.Element}
 */
function FormSearch({
    label,
    item,
    setItem,
    loadList,
    searchPatch,
    searchTable,
    searchColumn,
    outheParams,
    valueExtractor,
    textExtractor,
    textNotOption = 'Informe o nome.',
    disabled = false,
    required = false,
    minimumLength = 3,
    modal = null,
    stateList,
    fullWidth,
    stateLodingParam
}) {
    /**
     * @type {[T[], (list:T[]) => void]}
     */
    const state = useState([]);
    const [list, setList] = stateList ?? state;
    const [search, setSearch] = useState('');
    const stateLoding = useState(false);
    const [loading, setLoading] = stateLodingParam ?? stateLoding;
    const [textNotOptions, setTextNotOptions] = useState(textNotOption);
    const [open, setOpen] = useState(false);

    function carregarList() {
        setLoading(true);
        loadList(searchPatch, search, searchTable, searchColumn, outheParams).then(function (response) {
            let result = response.data.rows;
            if (result.msgErro) result = [];
            if (!result)
                setTextNotOptions('Nenhum resultado foi encontrado.');
            setList(result);
        }).catch(function (ex) {
            setList([]);
        }).finally(() => {
            setLoading(false);
        });
    }

    /**
     * @param {T} option 
     * @returns {string}
     */
    function renderValue(option) {
        if (valueExtractor) {
            if (typeof valueExtractor == 'string')
                return option[valueExtractor];

            return valueExtractor(option);
        }

        if (option)
            return option[searchColumn];

        return '';
    }

    /**
     * @param {T} option 
     * @returns {string}
     */
    function renderText(option) {
        if (!option)
            return '';

        if (option?.id === 0)
            return option[searchColumn]

        if (textExtractor) {
            if (typeof textExtractor == 'string')
                return option[textExtractor];
            const indice = list.findIndex(f => renderValue(f) == renderValue(option));
            
            return textExtractor(indice == -1 ? option : list[indice]);
        }

        return option[searchColumn];
    }

    function getOptionLabel(option) {
        if (option) {
            // e.g value selected with enter, right from the input
            if (option.inputValue)
                return option.inputValue;

                
            if (textExtractor) {
                const indice = list.findIndex(f => renderValue(f) == renderValue(option));
                return textExtractor(indice == -1 ? option : list[indice]);
            }

            if (typeof option === 'string')
                return option;

            return option[searchColumn];
        }

        return '';
    }
    function renderInput(params) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    {...params}
                    label={label}
                    onChange={(e) => {
                        setSearch(prev => {
                            return e.target.value;
                        });
                    }}
                    value={search ?? ''}
                    required={required}
                >
                </TextField>
            </Box>
        )
    }

    function RenderOption(
        props,
        option
    ) {
        return (
            <li
                key={renderValue(option)}
                {...props}
                value={renderValue(option)}
                onClickCapture={(e) => {
                    if (e.target.value === 0) setOpen(!open);
                }}
            >
                {option?.id === 0 && modal !== null ? option[searchColumn] : renderText(option)}
            </li>
        )
    }

    useEffect(function () {
        if (search?.length == minimumLength)
            carregarList();
        else if (search?.length < minimumLength) {
            setTextNotOptions(textNotOption);
            setList([]);
        }
    }, [search]);

    const filter = createFilterOptions();

    function filterOptions(options, params) {
        console.log("Homebrew3", options, params);
        const filtered = filter(options, params);
        if (modal !== null) {
            filtered.push({
                id: 0,
                inputValue: params?.inputValue,
                [searchColumn]: `Adicionar ${params?.inputValue}`,
            });
        }

        return filtered;
    }

    return (
        <>
            <Autocomplete
                options={list?.filter(f => {
                    if (f)
                        return renderText(f)?.match(search) ?? undefined != undefined;
                    return true;
                }) ?? []}
                filterOptions={filterOptions}
                value={item}
                disabled={disabled ?? false}
                onChange={(evt, value, details) => {
                    if (details === 'clear')
                        setItem({id: null});
                    else if (details === "selectOption");
                        setItem(value);
                }}
                fullWidth={fullWidth ?? false}
                renderInput={(params) => renderInput(params)}
                renderOption={(props, option) => RenderOption(props, option)}
                noOptionsText={textNotOptions}
                loading={loading}
                loadingText={<div style={{
                    textAlign: 'center'
                }}>
                    <CircularProgress size={24} />
                </div>}
                getOptionLabel={(options, params) => getOptionLabel(options)}
            >
            </Autocomplete>
            <Box display={'none'}>
                {modal !== null && cloneElement(modal, { stateOpen: [ open, setOpen ] })}
            </Box>
        </>

    );
}


export default FormSearch;