import { Box, Button, IconButton } from "@mui/material";
import { IconEdit, IconQuestionCircle } from "@tabler/icons";
import ObjectResponseList from "class/ObjectResponseList";
import StudentModule from "class/person/student/StudentModule";
import { useState } from "react";
import StudentModuleService from "service/verbify/user/student/StudentModuleService"
import ModalAceForm from "ui-component/populated/ace/ModalAceForm";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import FormReportFrequencySheet from "./student/report/FormReportFrequencySheet";
import ModalFormClasseModuleStudentPrice from "ui-component/populated/secretery/classe/module/student/price/ModalFormClasseModuleStudentPrice";
import DialogCustomFormResponse from "ui-component/populated/customForm/DialogCustomFormResponse";

/**
 * @param {Object} props
 * @param {[ObjectResponseList<StudentModule>, React.Dispatch<ObjectResponseList<StudentModule>>]} props.stateList
 * @param {ClasseModule} props.classeModule
 * @param {[StudentModule, React.Dispatch<StudentModule>]} props.stateStudentModule
 * @returns 
 */
export default function DataGridStudentModule({
    stateList,
    classeModule,
    stateStudentModule,
    height
}) {
    const studentModuleService = new StudentModuleService({ classeModule });
    /**
     * @type {[ObjectResponseList<StudentModule>, React.Dispatch<ObjectResponseList<StudentModule>>]}
     */
    const [items, setItems] = useState(stateList ? null : new ObjectResponseList());
    const stateStudentModules = stateList ?? [items, setItems];
 

    function callbackSave(customFormResponse) {
        if( customFormResponse?.id > 0 && stateStudentModule[0]?.id > 0 ){
            const data = {
                ...stateStudentModule[0],
                customFormResponse: {
                    ...stateStudentModule[0]?.customFormResponse,
                    ...customFormResponse
                }
            }
            stateStudentModule[1](data)
            studentModuleService.update(data)
        }
    }
    return <DataGridBase
        columns={[
            {
                field: 'option',
                renderCell: (params) => <Button
                    variant={'text'} autoFocus
                    onClick={(e) => stateStudentModule[1](params.row)}
                >
                    <IconEdit size={20}></IconEdit>
                </Button>,
                headerName: 'Opções',
                width: 85
            },
            { field: 'id', valueGetter: (params) => params.row.id, headerName: 'Código', width: 200 },
            { field: 'student.academicTitle', valueGetter: (params) => params.row.student.person.name, headerName: 'Estudante', width: 200 },
            { field: 'classeModule.module.title', valueGetter: (params) => params.row.classeModule.module.title, headerName: 'Nível', width: 100 },
            { field: 'percentageOfStudent', headerName: '% do estudante', width: 180 },
            { field: 'percentageOfStudentAVA', headerName: '% do estudante AVA', width: 180 },
            { field: 'starDate', valueGetter: (params) => params.row.startDate ? (typeof params.row.startDate == 'string' ? new Date(params.row.startDate + ' 00:00:00').toLocaleDateString() : params.row.startDate) : '-', headerName: 'início', width: 120 },
            { field: 'endDate', valueGetter: (params) => params.row.endDate ? (typeof params.row.endDate == 'string' ? new Date(params.row.endDate + ' 00:00:00').toLocaleDateString() : params.row.endDate) : '-', headerName: 'Fim', width: 120 },
            {
                field: 'ace',
                renderCell: (params) => <Box>
                    <ModalAceForm
                        label={"ACE"}
                        classeModuleStudentData={params.row}
                    ></ModalAceForm>
                </Box>,
                headerName: 'Opções',
                width: 100
            },
            {
                field: 'frequency',
                renderCell: (params) => <Box>
                    <FormReportFrequencySheet
                        classeModule={params.row.classeModule}
                        stateStudentModule={[params.row, () => { }]}
                    ></FormReportFrequencySheet>
                </Box>,
                headerName: 'Frequência',
                width: 100
            },
            {
                field: 'price',
                renderCell: (params) => <Box>
                    <ModalFormClasseModuleStudentPrice
                        label={"Ver"}
                        classeModuleStudentData={params.row}
                    ></ModalFormClasseModuleStudentPrice>
                </Box>,
                headerName: 'Financeiro',
                width: 100
            },
            {
                field: 'vpg',
                renderCell: (params) => <Button onClick={(e) => {
                    stateStudentModule[1](params.row)
                    }}>
                    <DialogCustomFormResponse
                        idCustomForm={10}
                        idCustomFormResponse={params.row?.customFormResponse?.id}
                        callbackSave={(formResponse) => callbackSave(formResponse)}
                        buttonOpenModal={<Button>VPG</Button>}
                        title={"VPG do Aluno"}
                    ></DialogCustomFormResponse>
                </Button>,
                headerName: 'VPG',
                width: 100
            },
        ]}
        functionListItens={(page) => studentModuleService.list(page)}
        stateItens={stateStudentModules}
        height={height}
    />
}