import User from 'class/person/user/User';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<User>
 */
export default class UserService extends BaseService {
    static baseUrl = "usuario";
    constructor () {
        super(UserService.baseUrl);
    }
}