import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
/** 
 * @param {Object} param0 
 * @param {[number, (number) => void]} param0.stateValue 
 * @param {string} param0.label 
**/
const InputCurrency = ({ stateValue, label }) => {
  const state = useState(0);
  const [valor, setValor] = stateValue ?? state;

  const cleanNumber = (valor) => {
    let novoValor = valor;
    if(isNaN(novoValor)) novoValor = novoValor?.replace("R$", "")
    if(isNaN(novoValor)) novoValor = novoValor?.replace("NaN", "")
    if(isNaN(novoValor)) novoValor = novoValor?.replace("null", "")
    return novoValor
  }
  const formatarMoeda = (valor) => {
    let valorFormatado = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(cleanNumber(valor));

    return valorFormatado;
  };

  const handleChange = (event) => {
    let novoValor = event.target.value;

    setValor(novoValor);
//    novoValor = new Intl.NumberFormat("en-IN", {}).format(cleanNumber(novoValor));

  };
  return (
    <TextField
      label={label ?? "Valor em BRL"}
      type='number'
      value={valor}
      onChange={handleChange}
      InputProps={{
        // startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
    />
  );
};

export default InputCurrency;
