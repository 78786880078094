import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import Person from "class/person/Person";
import User from "class/person/user/User";
import FormCustomer from "./FormCustomer";

/**
 * 
 * @param {{label:string, person?:Person, user?:User}} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormCustomer({ stateList, label, customer, stateOpen }) {
    const openState = useState(false);
    const [ open, setOpen ] = stateOpen ?? openState;

    const [customers, setCustomers] = useState(stateList ? null : {
        rows: [],
        totalRows: 0
    });

    const stateListCustomers = stateList ?? [customers, setCustomers];

    function updateList(customer) {
        stateListCustomers[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == customer.id)) {
                const index = newListItens.findIndex(f => f.id == customer.id);

                newListItens[index] = customer;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        ...newListItens,
                        customer
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Adicionar cliente"}
            description={"Informe os dados da cliente nos campos abaixo:"}
            buttonLabel={label}
            width={'md'}
            open={open}
            setOpen={setOpen}
            form={<FormCustomer customerData={customer} setOpenModal={setOpen} setList={updateList} ></FormCustomer>}
        >
        </ModalForm>
    );
}