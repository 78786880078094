import { Checkbox, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers";
import StudentModule from "class/person/student/StudentModule";
import Absence from "class/secretary/frequency/Absence";
import Duration from "class/utils/Duration";
import { useState } from "react";
import AbsenceService from "service/verbify/secretary/frequency/AbsenceService";
import DurationInput from "ui-component/extended/InputMask/DurationInput";

/**
 * 
 * @param {Object} props
 * @param {number} props.frequencySheetId
 * @param {[StudentModule, React.Dispatch<React.SetStateAction<StudentModule>>]} props.stateStudentModule
 * @param {[Absence[], React.Dispatch<React.SetStateAction<Absence[]>>]} props.stateAbsences
 * @returns 
 */
export default function ListItemFormAbsence({
    frequencySheetId,
    stateStudentModule,
    stateAbsences
}) {
    const absenceService = new AbsenceService({ frequencySheet : { id : frequencySheetId } });

    /** @type {[StudentModule, React.Dispatch<React.SetStateAction<StudentModule>>]} */
    const [ studentModule ] = stateStudentModule;
    /** @type {[Absence[], React.Dispatch<React.SetStateAction<Absence[]>>]} */
    const [ absences, setAbsences ] = stateAbsences;

    const formEnable = isNumber(frequencySheetId) && frequencySheetId > 0;
    const boolAbsence = absences?.some(s => s.classeModuleStudent.id == studentModule.id && s.frequencySheet.id == frequencySheetId) ?? false;

    /** @type {Absence|null} */
    const absence = absences.find(f => f.classeModuleStudent.id == studentModule.id);

    function changeAbasence(ev) { 
        //#region Atualização de state
        if (ev.target.checked) {
        //     /** @type {Absence} */
            const absenceDelete = absences.find(f => f.classeModuleStudent.id == studentModule.id);
            console.log(absences, studentModule, frequencySheetId);
            absenceService
                .delete(absenceDelete.id)
                .then((resp) => {
                    if (resp.status == 200) {
                        const newListAbsence = [ ...absences ];
                        newListAbsence.remove(f => f.classeModuleStudent.id == studentModule.id);
                        setAbsences(newListAbsence);
                    }
                }).catch((ex) => {

                });
        } else {
            const newAbsence = new Absence();
            newAbsence.classeModuleStudent = studentModule;
            newAbsence.frequencySheet = { id : frequencySheetId };
            newAbsence.hoursWorked = '00:00'
            
            absenceService
                .insert(newAbsence)
                .then((resp) => {
                    if (resp.data.success) {
                        const newListAbsence = [ 
                            ...absences,
                            { 
                                ...newAbsence, 
                                id : resp.data.model.id 
                            }
                        ];

                        setAbsences(newListAbsence);
                    }
                }).catch((ex) => {
                   
                });
        }
        //#endregion
    }

    function changePresentDuraction (ev) {
        if (
            ev.target.value.split(':').every(all => Number(all) >= 0)
        ) {
            const newAbsence = {
                ...absence,
                classeModuleStudent : studentModule,
                frequencySheet : { id : frequencySheetId },
                hoursWorked : ev.target.value
            };
    
            absenceService
                .update({
                    hoursWorked : ev.target.value,
                    frequencySheet : newAbsence.frequencySheet,
                    classeModuleStudent : newAbsence.classeModuleStudent
                })
                .then((resp) => {
                    console.log("update: ", resp.data);
                    
                    const newListAbsence = [ ...absences ];
                    const indice = newListAbsence.findIndex(f => f.classeModuleStudent.id == studentModule.id);
                    newListAbsence[indice] = newAbsence;
            
                    setAbsences(newListAbsence);
                }).catch((ex) => {
            });
        }
    }

    return <Box sx={{ display : 'flex', justifyContent: 'center', alignItems : 'center' }}>
        <Checkbox 
            checked={formEnable ? !boolAbsence : true}
            disabled={!formEnable}
            onChange={changeAbasence}
        />
        {boolAbsence && <TextField
            type="time"
            value={absence.hoursWorked}
            onChange={changePresentDuraction}
        />}
    </Box>;
}