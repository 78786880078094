export default class Duration {
    /**
     * @type {string}
     * @public
     */
    horas = '00'
    /**
     * @type {string}
     * @public
     */
    minutos = '00'

    constructor(data) {
        const [ horas, minutos ] = (data ?? '').toString().split(':');// (data ?? '').split(':');
        
        this.horas = horas;
        this.minutos = minutos;
    }

    setDuration(horas, minutos, segundos) {
        this.horas = horas;
        this.minutos = minutos;
    }

    addDuration(horas, minutos, segundos) {
        this.horas += horas;
        this.minutos += minutos;

        // Ajustar os minutos e segundos se forem maiores que 60
        if (this.minutos >= 60) {
            this.horas += Math.floor(this.minutos / 60);
            this.minutos %= 60;
        }
    }
    toString() {
        // if (typeof this.horas == 'string')
        //     return this.horas;
        return `${this.horas ? this.horas  : '00'}:${this.minutos ? this.minutos : '00'}:00`;
    }

    toJSON() {
        return this.toString();
    }    
}