import ObjectResponseList from "class/ObjectResponseList";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class AccountService extends BaseService {
    static _baseUrl = 'payment/bank/account';
    constructor () {
        super(AccountService._baseUrl);
    }
    /**
     * 
     * @returns {ObjectResponseList}
     */
    listOmie() {
        return verbify.get(`${AccountService._baseUrl}/listOmie`);
    }
}