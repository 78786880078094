import { LoadingButton } from "@mui/lab";
import { Switch, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Box, Stack } from "@mui/system";
import Ace from "class/ace/Ace";
import { useEffect, useState } from "react";
import FormSearchCustomForm from "../customForm/FormSearchCustomForm";
import Person from "class/person/Person";
import StudentModule from "class/person/student/StudentModule";
import AceService from "service/verbify/ace/AceService";

/**
 * @param {Object} props 
 * @param {number?} props.id
 * @param {(item: Ace) => void} props.setItem
 * @param {Person} props.person
 * @param {StudentModule} props.classeModuleStudentData
 * @returns {React.JSX.Element}
 */
export default function FormAce({ id, setItem, item, classeModuleStudentData }) {
    const aceService = new AceService();
    /**
     * @type {[Ace, (ace:Ace) => Ace]}
     */
    const [ace, setAce] = useState(new Ace());
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();
        evento.stopPropagation();

        setButtonLoading(true);

        const isUpdate = ace.id != undefined && ace.id != null;

        (isUpdate ? aceService.update(ace) : aceService.insert(ace))
            .then((response) => {
                setItem(isUpdate ? ace : { ...ace, id: response.data.model.id });
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(function () {
        if(classeModuleStudentData) setAce(prev => {
            return {
                ...prev,
                classeModuleStudent: classeModuleStudentData
            }
        })
        const isUpdate = id != undefined && id != null;
        //Carraga os dados da languagem caso tenha o id
        if (isUpdate && isNumber(id) && id > 0)
            aceService
                .search(id)
                .then(response => setAce(response.data))
                .catch(ex => { })
                .finally();
    }, []);

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >
                <FormSearchCustomForm
                    customForm={ace?.customForm ?? null}
                    setCustomForm={(customForm) => setAce(prev => {
                        return {
                            ...prev,
                            customForm: customForm
                        }
                    })}
                    required={true}
                ></FormSearchCustomForm>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Stack>
        </form>
    );
}