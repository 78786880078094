import Person from "../Person";
import Language from "class/secretary/Language";

export default class PersonLanguage {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    languageExperiences = '';
    /**
     * @type {string}
     * @public
     */
    topicsOfInterest = '';
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
    /**
     * @type {Language}
     * @public
     */
    language = new Language();
}