import { Dialog, AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import React, { useState, cloneElement } from "react";
import { IconX } from "@tabler/icons";
import { useTheme } from '@mui/material/styles';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.buttonOpenModal
 * @param {React.ReactNode} props.children
 * @param {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} props.stateOpen
 */
export default function DialogBase({
    buttonOpenModal,
    title,
    children,
    headOptions,
    stateOpen
}) {
    const stateOpenDialog = useState(false);
    const [open, setOpen] = stateOpen || stateOpenDialog;
    const theme = useTheme();

    return <>
        {buttonOpenModal && cloneElement(buttonOpenModal, { onClick : (e) => setOpen(true) })}
        <Dialog
            fullScreen
            open={open}
        >
            <AppBar color="primary" sx={{ position: 'relative' }}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <IconButton onClick={(e) => setOpen(false)} edge="start">
                            <IconX color={theme.palette.primary.light} />
                        </IconButton>

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div" color={'primary.light'}>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                        {headOptions}
                    </Box>
                </Toolbar>
            </AppBar>
            {
                children
            }
        </Dialog>
    </>;
}