import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { IconSchool } from '@tabler/icons';

const icons = {
    LocalLibraryOutlinedIcon
};

const student = {
    id: 'student',
    title: 'Estudante',
    type: 'collapse',
    icon: icons.LocalLibraryOutlinedIcon,
    children: [
        {
            id: 'studentSchedule',
            title: 'Agenda',
            type: 'item',
            icon: IconSchool,
            url: '/aluno/agenda',
            target: false,
            breadcrumbs: false
        }
    ]
};

export default student;