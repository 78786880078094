import ObjectResponseList from "class/ObjectResponseList";
import Item from "class/schedule/Item";
import ItemService from "service/verbify/schedule/ItemService";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import { useState } from 'react';
import DataDayOfWeek from "data/utils/DataDayOfWeek";
import DataType from "data/schedule/item/DataType";
import DialogFormItem from "./DialogFormItem";
import { IconEdit, IconListCheck } from "@tabler/icons";
import { Button } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { EnumType } from "class/schedule/item/Type";

/**
 * Retorana uma tabela populada com os itens da agenda informada
 * @param {Object} props
 * @param {[Item, React.Dispatch<Item>]} props.stateItem
     * @param {[ObjectResponseList<Item>, React.Dispatch<ObjectResponseList<Item>>]} props.stateList 
 * @returns {React.JSX.Element}
 */    
export default function DataGridItem({
    stateItem,
    stateList,
    height
}) {
    const itemService = new ItemService();
    const [ item, setItem ] = stateItem;
    /**
     * @type {[ObjectResponseList<Item>, React.Dispatch<ObjectResponseList<Item>>]}
     */
    const [ items, setItems ] = useState(stateList ? null : new ObjectResponseList());

    const stateItens = stateList ?? [ items, setItems ];

    return <DataGridBase
        stateItens={stateItens}
        columns={[
            { 
                field : 'option', 
                renderCell : (/** @type {{ row:Item }} */ { row }) => <>
                    <Button 
                        variant={'text'} 
                        autoFocus
                        onClick={(e) => {
                            if(setItem) setItem(row);
                        }}
                        lang="Editar"
                    >
                        <IconEdit size={20} />
                    </Button>
                </>,
                headerName: 'Opções', 
                width : 150
            },
            { field : 'title', headerName: 'Nome', valueGetter : ({row}) => `${row.title} (${DataDayOfWeek.find(f => f.numberDay == row.dayOfTheWeek)?.label})`, width : 300 },
            { field : 'type', headerName : 'Tipo', valueGetter : ({ row }) => DataType.find(f => f.id == row.type).label, width : 100 },
            { field : 'periodo', headerName : 'Periodo', valueGetter : ({ row }) => `${toDate(row.startDate).toLocaleDateString()} - ${toDate(row.endDate).toLocaleDateString()}`, width : 200 },
            { field : 'horario', headerName : 'Horário', 
            valueGetter : ({ row }) => `${row.startTime} - ${row.endTime}`, width : 200}
        ]}
        functionListItens={(page) => itemService.list(page, item)}
        height={height ?? undefined}
    ></DataGridBase>;
}

function toDate(data) {
    if (typeof data === "string")
        return new Date(data + " 00:00:00")
    return data;
}