import ObjectRequest from "class/ObjectRequest";
import ObjectResponseList from "class/ObjectResponseList";
import Item from "class/schedule/Item";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class ItemService extends BaseService {
    static _baseUrl = 'agenda/item';
    constructor () {
        super(ItemService._baseUrl);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {Item} item 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Item>, any>>}
     */
    list (currentPage, item) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`agenda/${item.schedule.id}/item?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {Item} item 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert (item) {
        return verbify.post(`agenda/${item.schedule.id}/item`, item);
    }
    /**
     * @param {Item} item 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update (item) {
        return verbify.put(`agenda/${item.schedule.id}/item/${item.id}`, item);
    }
}