import { Box, Button, Collapse, List, ListItem, ListItemButton } from "@mui/material";
import { IconCreditCard } from "@tabler/icons";
import Payment from "class/payment/Payment";
import PaymentFetlock from "class/payment/PaymentFetlock";
import PaymentPix from "class/payment/PaymentPix";
import StudentModule from "class/person/student/StudentModule";
import { useEffect, useState } from "react";
import { useLocation, useParams, useRoutes } from "react-router";
import PaymentFetlockService from "service/verbify/payment/PaymentFetlockService";
import PaymentPixService from "service/verbify/payment/PaymentPixService";
import PaymentService from "service/verbify/payment/PaymentService";
import MainCard from "ui-component/cards/MainCard";

const PaymentType = {
    PIX : 1,
    BOLETO : 3
}

export default function PayTheBill() {
    const paymentService = new PaymentService();
    /** @type {[Payment<StudentModule>, React.Dispatch<Payment<StudentModule>>]} */
    const params = useParams();
    const paymentId = params.payment;

    const [payment, setPayment] = useState(null);
    const [paymentType, setPaymentType] = useState(null);

    useEffect(function () {
        paymentService
            .getPaymentId({
                identificador : paymentId
            })
            .then((response) => {
                setPayment(Object.assign(new Payment(), response.data));
            }).catch((ex) => {
                console.log(ex);
            }).finally()
    }, [ paymentId ]);

    return payment !== null && <MainCard
        title="Pagar"
        sx={{ 
            maxWidth : {
                xs: "100%",
                md : '800px'
            },
            margin: "0px auto"
        }}  
    >
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flex : 1,
                width : '100%',
                justifyContent: 'space-around'
            }}
        >
            <Box
                sx={{
                    flex : 1
                }}
            >
                <List
                    sx={{ width: '100%', padding : 0, paddingRight : '24px' }}
                    component="nav"
                >
                    <ListItemButton
                        selected={paymentType === PaymentType.PIX} 
                        onClick={() => setPaymentType(PaymentType.PIX)}
                    >
                        Pix
                    </ListItemButton>
                    <Collapse
                        in={paymentType === PaymentType.PIX} 
                        timeout="auto" 
                        unmountOnExit
                    >
                        <Box
                            sx={{
                                display : 'flex',
                                flexDirection : 'column',
                                flexGrow : 1,
                                padding : 2.5,
                                borderWidth : 1,
                                border : "1px solid rgba(0, 0, 0, 0.23) ",
                                color : "#364152"
                            }}
                        >
                            <PixMenager paymentId={paymentId} />
                        </Box>
                    </Collapse>
                    <ListItemButton 
                        selected={paymentType === PaymentType.BOLETO} 
                        onClick={() => setPaymentType(PaymentType.BOLETO)}
                    >
                        Boleto
                    </ListItemButton>
                    <Collapse 
                        in={paymentType === PaymentType.BOLETO} 
                        timeout="auto" 
                        unmountOnExit
                    >
                        <Box
                            sx={{
                                display : 'flex',
                                flexDirection : 'column',
                                flexGrow : 1,
                                padding : 2.5,
                                borderWidth : 1,
                                border : "1px solid rgba(0, 0, 0, 0.23) ",
                                color : "#364152"
                            }}
                        >
                            <FetlockMenager paymentId={paymentId} />
                        </Box>
                    </Collapse>   
                </List>
            </Box>
            <Box
                sx={{
                    display : 'flex',
                    flexDirection : 'column'
                }}
            >
                <Box sx={{
                        display : 'flex',
                    alignItems : 'center',
                    padding : 2.5,
                    minWidth : 250,
                    borderWidth : 1,
                    border : "1px solid rgba(0, 0, 0, 0.23) ",
                    color : "#364152"
                }}>
                    <IconCreditCard style={{ marginRight : 5 }} color="#364152" />
                    <span style={{
                        lineHeight : 'normal',
                        color : '#364152'
                    }}>{payment?.renderTextReference()} ({payment?.numberInstallment}º parcela)</span>
                </Box>
                
                <Box sx={{
                    display : 'flex',
                    flexDirection : 'column',
                    padding : 2.5,
                    minWidth : 250,
                    borderWidth : 1,
                    border : "1px solid rgba(0, 0, 0, 0.23) ",
                    color : "#364152"
                }}>
                    <div style={{ lineHeight : 2 }}>{payment?.beneficiary?.name}</div>
                    <div style={{ lineHeight : 2 }}>Vencimento em {payment?.dueDate}</div>
                    <div style={{ lineHeight : 2 }} >Valor à pagar R$ {new Intl.NumberFormat('pr-Br', { style: 'currency', currency : 'BRL' }).format(payment?.valuePredicted ?? 0)}</div>
                </Box>
            </Box>
        </Box>
    </MainCard> || "Nenhum pagamento encontrado";
}


/**
 * Gegencia o pagamento por boleto de um titulo
 * @param {Object} props 
 * @param {number} props.paymentId
 */
function FetlockMenager({
    paymentId,
    ...props
}) {
    const paymentFetlockService = new PaymentFetlockService();
    /** @type {[PaymentFetlock, React.Dispatch<React.SetStateAction<PaymentFetlock>>]} */
    const [ paymentFetlock, setPaymentFetlock ] = useState(null);

    useEffect(() => {
        paymentFetlockService.get({
            identificador : paymentId
        }).then((response) => {
            setPaymentFetlock(response.data ? response.data : null);
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }, [paymentId]);

    /**
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} ev 
     */
    function gerar(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        paymentFetlockService.generate({
            paymentId : paymentId
        }).then((response) => {
            setPaymentFetlock(response.data ? response.data : null);
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }

    function abrirBoleto(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        window.open(paymentFetlock.link);
    }

    function copiarCodigoDeBarras(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        navigator.clipboard.writeText(paymentFetlock.codigoBarras).then((response) => {
            alert("Código de barras copiiado com sucesso.");
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }

    return <>
        {(paymentFetlock === null || paymentFetlock === undefined) && <Box>
            <Button style={{ width : '100%' }} onClick={gerar}>Gerar boleto</Button>    
        </Box>}

        {(paymentFetlock !== null && paymentFetlock !== undefined) && <Box>
            <Button style={{ width : '100%' }} onClick={abrirBoleto}>Abrir boleto</Button>
            {(navigator.clipboard && navigator.clipboard.writeText) && <Button style={{ width : '100%' }} onClick={copiarCodigoDeBarras}>Copiar código de barras</Button>}    
        </Box>}
    </>;
}

/**
 * Gegencia o pagamento por boleto de um titulo
 * @param {Object} props 
 * @param {number} props.paymentId
 */
function PixMenager({
    paymentId,
    ...props
}) {
    const paymentPixService = new PaymentPixService();
    /** @type {[PaymentPix, React.Dispatch<React.SetStateAction<PaymentPix>>]} */
    const [ paymentPix, setPaymentPix ] = useState(null);

    useEffect(() => {
        paymentPixService.get({
            identificador : paymentId
        }).then((response) => {
            setPaymentPix(response.data ? response.data : null);
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }, [paymentId]);

    /**
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} ev 
     */
    function gerar(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        paymentPixService.generate({
            paymentId : paymentId
        }).then((response) => {
            setPaymentPix(response.data ? response.data : null);
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }

    function abrirBoleto(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        window.open(paymentPix.cUrlPix);
    }

    function copiarCodigoDeBarras(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        navigator.clipboard.writeText(paymentPix.cCopiaCola).then((response) => {
            alert("Código de barras copiiado com sucesso.");
        }).catch((ex) => {
            alert("Ocorreu um erro, tente novamente mais tarde.");
        });
    }

    return <>
        {(paymentPix === null || paymentPix === undefined) && <Box>
            <Button style={{ width : '100%' }} onClick={gerar}>Gerar pix</Button>    
        </Box>}

        {(paymentPix !== null && paymentPix !== undefined) && <Box>
            <Button style={{ width : '100%' }} onClick={abrirBoleto}>Abrir pix</Button>
            {(navigator.clipboard && navigator.clipboard.writeText) && <Button style={{ width : '100%' }} onClick={copiarCodigoDeBarras}>Copiar pix</Button>}    
        </Box>}
    </>;
}