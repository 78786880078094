import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import Product from "class/opportunity/product/Product";
import ProductService from "service/verbify/product/ProductService";
import InputCurrency from "ui-component/InputCurrencyOld";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import FormSearchProductType from "./FormSearchProductType";
import ProductTypeService from "service/verbify/product/data/ProductTypeService";
import FormSearchCategoria from "../omie/geral/categoria/FormSearchCategoria";
import FormSearchCidade from "../omie/geral/cidade/FormSearchCidade";
import FormSearchLeiComplementar116 from "../omie/geral/servico/leiComplementar116/FormSearchLeiComplementar116";
import FormSearchTipoTributacao from "../omie/geral/servico/tipoTributacao/FormSearchTipoTributacao";
import FormSearchServicoMunicipal from "../omie/geral/servico/servicoMunicipal/FormSearchServicoMunicipal";
import ObjectRequest from "class/ObjectRequest";

/**
 * @param {{productData?:Product, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */

function InputImpostos({ stateImposto, stateRetido, label, require, disable }) {
    const [imposto, setImposto] = stateImposto;
    const [retido, setRetido] = stateRetido;

    return <Stack direction={'row'} width={"33%"} gap={2} p={1.5} >
        <TextField
            id="outlined-multiline-flexible"
            label={label}
            type="number"
            fullWidth
            value={imposto}
            step="0.01"
            onChange={e => setImposto(e.target.value)}
        />
        <FormControlLabel
            label={"Retido"}
            required={require}
            disabled={disable}
            control={
                <Checkbox
                    type="checkbox"
                    checked={retido}
                    onChange={e => setRetido(e.target.checked)}
                />
            }
        >
        </FormControlLabel>

    </Stack>
}
export default function FormProduct({ productData, setOpenModal, setItem }) {
    const [product, setProduct] = useState(new Product());
    const productService = new ProductService();

    const [ type, setType ] = useState({
        id: null,
        label: ''
    })
    const productTypeService = new ProductTypeService();

    useEffect(() => {
        if (productData) setProduct(productData);
        if (productData?.type > 0) setType(productTypeService.search('id', productData?.type))
    }, [])

    useEffect(() => {
        setProduct(prev => {
            return {
                ...prev,
                type: type?.id
            }
        })
    }, [type])

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();
        evento.stopPropagation();

        setButtonLoading(true);

        const isUpdate = product?.id != undefined && product?.id != null;

        (isUpdate ? productService.update(product) : productService.insert(product))
            .then((response) => {
                if (setOpenModal) setOpenModal(false);
                if (setItem) setItem(isUpdate ? product : { ...product, id: response?.data?.model?.id  } );
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={1.5}
                mt={2}
                mb={2}
                direction={'column'}
            >
                
                <TextField
                    id="outlined-multiline-flexible"
                    label="Titúlo"
                    value={product.title}
                    onChange={
                        e => {
                            setProduct(prev => {
                                return {
                                    ...prev,
                                    title: e.target.value
                                }
                            })
                        }
                    }
                />
                <FormSearchProductType
                    item={type}
                    setItem={setType}
                    loadList={(searchPatch, search, searchTable, searchColumn) => productTypeService.searchFor(searchPatch, search, searchTable, searchColumn)}
                >
                </FormSearchProductType>

                <TextField
                    id="outlined-multiline-flexible"
                    label="Descrição"
                    multiline
                    rows={3}
                    value={product.description}
                    onChange={
                        e => {
                            setProduct(prev => {
                                return {
                                    ...prev,
                                    description: e.target.value
                                }
                            })
                        }
                    }
                />

                <TextField
                    id="outlined-multiline-flexible"
                    label="Valor padrão"
                    type="number"
                    value={product.price}
                    step="0.01"
                    onChange={
                        e => {
                            setProduct(prev => {
                                return {
                                    ...prev,
                                    price: e.target.value
                                }
                            })
                        }
                    }
                />
                
                <Stack p={3} direction={'column'} gap={2} bgcolor={'primary.light'} borderRadius={2}>
                    <Typography variant="h3">Impostos</Typography>

                    <FormSearchCidade 
                        cidade={{
                            cUF : product?.uf ?? '',
                            cCod : product?.fkProductOmieCidadeId ?? ''
                        }}
                        setCidade={(cidade) => {
                            setProduct(prev => {
                                return {
                                    ...prev,
                                    uf : cidade?.cUF ?? '',
                                    fkProductOmieCidadeId : cidade?.cCod ?? ''
                                }
                            });
                        }}
                    />
                    
                    <FormSearchTipoTributacao 
                        tipoTributacao={{
                            cIdTrib : product.fkProductOmieTipoTributacaoId
                        }}
                        setTipoTributacao={(tipoTributacao) => {
                            setProduct((prev) => {
                                return {
                                    ...prev,
                                    fkProductOmieTipoTributacaoId : tipoTributacao?.cIdTrib
                                };
                            });
                        }}
                    />
                    
                    <FormSearchServicoMunicipal
                        servicoMunicipal={{
                            cCodServMun : product.fkProductOmieServicoMunicipalId
                        }}
                        setServicoMunicipal={(servicoMunicipal) => {
                            setProduct((prev) => {
                                return {
                                    ...prev,
                                    fkProductOmieServicoMunicipalId : servicoMunicipal?.cCodServMun
                                }
                            });
                        }}
                        required
                    />

                    <FormSearchLeiComplementar116
                        leiComplementar={{
                            cCodigo : product.fkProductOmieLeiComplementar116
                        }}
                        setLeiComplementar={(leiComplementar) => {
                            setProduct((prev) => {
                                return {
                                    ...prev,
                                    fkProductOmieLeiComplementar116 : leiComplementar?.cCodigo
                                }
                            });
                        }}
                        required
                    />
                    <FormSearchCategoria 
                        categoria={{
                            codigo : product.fkProductOmieCategoriaId
                        }}
                        setCategoria={(categoria) => {
                            setProduct((prev) => {
                                return {
                                    ...prev,
                                    fkProductOmieCategoriaId : categoria?.codigo
                                }
                            });
                        }}
                        required
                    />
                        
                    <Stack
                        direction={'row'}
                        width={"100%"}
                        flexWrap={'wrap'}
                    >
                        <InputImpostos
                            label={'PIS'}
                            stateImposto={[product?.alicotaPIS, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaPIS: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoPIS, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoPIS: retido
                                }
                            })]}
                        />
                        <InputImpostos
                            label={'CSLL'}
                            stateImposto={[product?.alicotaCSLL, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaCSLL: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoCSLL, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoCSLL: retido
                                }
                            })]}
                        />
                        <InputImpostos
                            label={'ISS'}
                            stateImposto={[product?.alicotaISS, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaISS: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoISS, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoISS: retido
                                }
                            })]}
                        />
                        <InputImpostos
                            label={"COFINS"}
                            stateImposto={[product?.alicotaCOFINS, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaCOFINS: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoCOFINS, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoCOFINS: retido
                                }
                            })]}
                        />
                        <InputImpostos
                            label={"IR"}
                            stateImposto={[product?.alicotaIR, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaIR: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoIR, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoIR: retido
                                }
                            })]}
                        />
                        <InputImpostos
                            label={"INSS"}
                            stateImposto={[product?.alicotaINSS, (alicota) => setProduct(prev => {
                                return {
                                    ...prev,
                                    alicotaINSS: alicota
                                }
                            })]}
                            stateRetido={[product?.retidoINSS, (retido) => setProduct(prev => {
                                return {
                                    ...prev,
                                    retidoINSS: retido
                                }
                            })]}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <LoadingButton
                type="submit"
                variant="outlined"
                loading={buttonLoading}
                fullWidth
            >{"Salvar"}</LoadingButton>
        </form>
    );
}