import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import ClasseModule from "class/secretary/classe/ClasseModule";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import ObjectResponseList from "class/ObjectResponseList";
import Proposal from "class/proposal/Proposal";

/**
 * @param {Object} props
 * @param {ClasseModule} props.classeModuleData
 * @param {Proposal} props.proposal
 * @param {(classeModule:ClasseModule) => ObjectResponseList<ClasseModule>} props.updateList
 * @param {(classeModule:ClasseModule) => void} props.callback
 * @param {(open: boolean) => void} props.setOpenModal
 * @returns 
 */
export default function FormClasseModuleStatus({ classeModuleData, updateList, callback, setOpenModal, size}) {
    /**
     * @type {[boolean, React.Dispatch<boolean>]} state
    */

    const classeModuleService = new ClasseModuleService();

    const [classeModule, setClasseModule] = useState(classeModuleData ?? new ClasseModule());

    function save(e) {
        const info = {
            id: classeModule?.id,
            classe: {
                ...classeModule?.classe,
            },
            module: {
                ...classeModule?.module
            },
            status: e.target.value
        };

        const isUpdate = info?.id > 0;

        (isUpdate ? classeModuleService.update(info) : classeModuleService.insert(info))
            .then((response) => {

                if (response?.data?.model?.id) info.id = response?.data?.model?.id
                setClasseModule({ ...classeModule, ...info });

                if (callback) callback(info)
                if (updateList) updateList({ ...classeModule, ...info })
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                if (setOpenModal) setOpenModal(false);
            });
    }

    return <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth size={size ?? "small"}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={classeModule.status}
                onChange={(e) => save(e)}
            >
                <MenuItem value={0}>Em criação</MenuItem>
                <MenuItem value={1}>Criado</MenuItem>
                <MenuItem value={2}>Em andamento</MenuItem>
                <MenuItem value={3}>Concluído</MenuItem>
                <MenuItem value={4}>Pausado</MenuItem>
                <MenuItem value={5}>Cancelado</MenuItem>
            </Select>
        </FormControl> 
    </Box>

}