import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useContext, useEffect } from 'react';
import { GlobalContext } from 'GlobalContext';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'js-cookie';
// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { persons } = useContext(GlobalContext).persons

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      console.log(Cookies.get('token'), persons, "TOKEN");
      if(!Cookies.get('token')) navigate('/login')
      else if((Cookies.get('token') || persons[0]?.user?.id > 0) && (location.pathname === "/login" || location.pathname === "/cadastro")) navigate('/')
  }, [location.pathname, persons])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
