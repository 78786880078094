import CustomFormResponse from "class/customForm/response/CustomFormResponse";
import StudentModule from "class/person/student/StudentModule";

export default class ClasseModuleStudentPrice {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {number}
     * @public
     */
    totalValue = 0;
    /**
     * @type {number}
     * @public
     */
    percentageOfStudent = 0;
    /**
     * @type {number}
     * @public
     */
    installmentOfStudent = 0;
    /**
     * @type {number}
     * @public
     */
    totalValueAVA = 0;
    /**
     * @type {number}
     * @public
     */
    percentageOfStudentAVA = 0;
    /**
     * @type {number}
     * @public
     */
    installmentOfStudentAVA = 0;
    /**
     * @type {bool|number}
     * @public
     */
    status = true;
    /**
     * @type {bool|number}
     * @public
     */
    active = true;
    /**
     * @type {StudentModule}
     * @public
     */
    classeModuleStudent = new StudentModule();

    
}