import ObjectResponseList from "class/ObjectResponseList";
import Categoria from "class/omie/geral/Categoria";
import LeiComplementar116 from "class/omie/servico/LeiComplementar116";
import CategoriaService from "service/verbify/omie/geral/CategoriaService";
import LeiComplementar116Service from "service/verbify/omie/geral/servico/LeiComplementar116Service";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {LeiComplementar116} props.leiComplementar
 * @param {React.Dispatch<React.SetStateAction<LeiComplementar116>>} props.setLeiComplementar
 * @param {string|undefined|null} props.label
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<ObjectResponseList<LeiComplementar116>, any> | null} props.loadList
 * @param {boolean|null|undefined} props.required
 * @returns 
 */
export default function FormSearchLeiComplementar116({
    leiComplementar,
    setLeiComplementar,
    label,
    loadList,
    required
}) {
    return <FormSearch
        required={required}
        label={label ?? 'Código do Serviço LC 116'}
        item={leiComplementar}
        setItem={setLeiComplementar}
        loadList={loadList ? loadList : (searchPatch, search, searchTable, searchColumn) => {
            const leiComplementar116Service = new LeiComplementar116Service();
            return leiComplementar116Service.list();
        }}
        minimumLength={0}
        fullWidth={true}
        valueExtractor={
            /**
             * @param {LeiComplementar116} option 
             * @returns {string}
             */
            (option) => option?.cCodigo
        }
        textExtractor={
            /**
             * @param {LeiComplementar116} option 
             * @returns {string}
            */
            (option) => {
                return option?.cCodigo?.length > 0 ? `${option?.cCodigo ?? ''} - ${option?.cDescricao ?? ''}` : '';
            }
        }
    ></FormSearch>;
}