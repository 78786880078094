import DayOfWeek from "class/utils/DayOfWeek";

/**
 * @type {DayOfWeek[]}
 */
const DataDayOfWeek = [
    { label: "Domingo", id: 1, numberDay : 0 },
    { label: "Segunda-feira", id: 2, numberDay : 1 },
    { label: "Terça-feira", id: 3, numberDay : 2 },
    { label: "Quarta-feira", id: 4, numberDay : 3 },
    { label: "Quinta-feira", id: 5, numberDay : 4 },
    { label: "Sexta-feira", id: 6, numberDay : 5 },
    { label: "Sábado", id: 7, numberDay : 6 }
];
export default DataDayOfWeek;