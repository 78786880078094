import FormSearch from "ui-component/FormSeach"

export default function FormSearchProductType({
    item,
    setItem,
    loadList,
    required
}) {
    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/produto-tipo'}
            searchTable={'label'}
            searchColumn={'label'}
            label="Categoria de produto"
            item={item}
            setItem={setItem}
            loadList={loadList}
            minimumLength={0}
            valueExtractor={
                (option) => option?.id
            }
            textExtractor={
                (option) => option.id > 0 ? `${option.id} - ${option.label}` : ''
            }
        ></FormSearch>
    )
}