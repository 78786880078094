import Person from "../Person";
export default class Student {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
}