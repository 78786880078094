import Payment from "class/payment/Payment";
import BaseService from "service/BaseService";
import verbify from "../config";
import StudentModule from "class/person/student/StudentModule";
import ObjectResponseList from "class/ObjectResponseList";
import ObjectRequest from "class/ObjectRequest";
import TeacherModule from "class/person/teacher/TeacherModule";

/**
 * @extends {BaseService<Payment>}
 */
export default class PaymentService extends BaseService {
    static _baseUrl = 'payment';
    constructor () {
        super(PaymentService._baseUrl);
    }
    /**
     * @param {Object} param
     * @param {ObjectRequest} param.paga 
     * @returns 
     */
    list({
        page
    }) {
        return verbify.get(`/pagamento/listar?page=${page.page}&perPage=${page.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados para o estudante
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<StudentModule>>, any>>}
     */
    generateInstallmentsStudent(paramRequest) {
        return verbify.get(`/payment/generateInstallmentsStudent?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados para o estudante (AVA)
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<StudentModule>>, any>>}
     */
    generateInstallmentsStudentAVA(paramRequest) {
        return verbify.get(`/payment/generateInstallmentsStudentAVA?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados para o cliente
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<StudentModule>>, any>>}
     */
    generateInstallmentsStudentCustomer(paramRequest) {
        return verbify.get(`/payment/generateInstallmentsStudentCustomer?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados para o cliente (AVA)
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<StudentModule>>, any>>}
     */
    generateInstallmentsStudentCustomerAVA(paramRequest) {
        return verbify.get(`/payment/generateInstallmentsStudentCustomerAVA?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados dos professores
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<TeacherModule>>, any>>}
     */
    listPayRollNotGenereted(paramRequest) {
        return verbify.get(`/payment/listPayRollNotGenereted?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * Lista os titulos a serem gerados dos professores
     * @param {ObjectRequest} paramRequest 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Payment<TeacherModule>>, any>>}
     */
    listPayroll(paramRequest) {
        return verbify.get(`/pagamento/listPayroll?page=${paramRequest.page}&perPage=${paramRequest.perPage}`);
    }
    /**
     * @param {Object} props
     * @param {Payment<StudentModule>[]} props.list 
     */
    generateBatchPaymentStudentModule({list}) {
        return verbify.post(`/payment/generateBatchInstallmentsStudent`, list);
    }
    /**
     * @param {Object} props
     * @param {Payment<TeacherModule>[]} props.list 
     */
    generateBatchPaymentTeacherModule({list}) {
        return verbify.post(`/payment/generateBatchInstallmentsTeacher`, list);
    }
    getPaymentId({
        identificador
    }) {
        return verbify.get(`/payment/${identificador}`)
    }
}