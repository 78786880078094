// assets
import { IconBuildingSkyscraper, IconUser, IconWallet, IconChecklist, IconSchool, IconExplicit } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconBuildingSkyscraper,
    IconWallet,
    IconChecklist,
    IconExplicit
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const profiles = {
    id: 'account',
    title: 'Conta',
    type: 'group',
    children: [
        {
            id: 'userItem',
            title: 'Minha Conta',
            type: 'item',
            url: '/usuario',
            icon: icons.IconUser,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'schoolTask',
            title: 'Tarefas',
            type: 'item',
            url: '/tarefas',
            icon: icons.IconChecklist,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'schoolPersonLanguage',
            title: 'Experiencia com Idioma',
            type: 'item',
            url: '/usuario/experiencia-com-idioma',
            icon: icons.IconExplicit,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'accountsPayable',
            title: 'Faturas',
            type: 'item',
            url: '/usuario/contas-pagar',
            icon: icons.IconWallet,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'profileUserCustomer',
            title: 'Cliente',
            type: 'item',
            url: '/usuario/cliente',
            icon: icons.IconBuildingSkyscraper,
            target: false,
            breadcrumbs: false
        },
        {
            id: 'scheduleProfile',
            title: 'Agendas',
            type: 'collapse',
            icon: IconSchool,
            children: [
                {
                    id: 'schoolPresentation',
                    title: 'Professor',
                    type: 'item',
                    icon: IconSchool,
                    url: '/coordenacao/agenda',
                    target: false,
                    breadcrumbs: false
                },
                {
                    id: 'studentSchedule',
                    title: 'Aluno',
                    type: 'item',
                    icon: IconSchool,
                    url: '/aluno/agenda',
                    target: false,
                    breadcrumbs: false
                }
            ]

        },
    ]
};

export default profiles;
