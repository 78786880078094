import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import ObjectResponseList from "class/ObjectResponseList";
import Proposal from "class/proposal/Proposal";
import ClasseModule from "class/secretary/classe/ClasseModule";
import { Box, Stack, Typography } from "@mui/material";
import FormClasseModuleFull from "./FormClasseModuleFull";
import DialogBase from "ui-component/populated/base/DialogBase";
import FormClasseModuleStatus from "./FormClasseModuleStatus";
/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {number|null|undefined} param0.classe
 * @param {ClasseModule} param0.classeModuleData
 * @param {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]} param0.stateList 
 * @param {Proposal} param0.proposal 
 * @returns {React.JSX.Element}
 */
export default function ModalFormClasseModuleFull({
    stateList,
    label,
    proposal,
    stateOpen,
    classeId,
    classeModuleId,
    classeModuleData
}) {

    const openState = useState(false);
    const [open, setOpen] = stateOpen ?? openState;

    /**
     * @type {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]}
     */
    const list = useState(new ObjectResponseList());

    const [classeModules, setClasseModules] = stateList ?? list;

    function setItem(classe) {
        setClasseModules(prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == classe.id)) {
                const index = newListItens.findIndex(f => f.id == classe.id);

                newListItens[index] = classe;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        classe,
                        ...newListItens
                    ]
                }
            }
        });
    }
    
    return (
        <DialogBase
            stateOpen={[open, setOpen]}
            title={"Plano de Aulas"}
            headOptions={<FormClasseModuleStatus classeModuleData={classeModuleData} updateList={setItem}></FormClasseModuleStatus>}
            buttonOpenModal={label}
        >
            <Box>
                <FormClasseModuleFull classeId={classeId} classeModuleId={classeModuleId} classeModuleData={classeModuleData}></FormClasseModuleFull>
            </Box>
        </DialogBase>
    );
}
