export default class CustomForm {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = "";
    /**
     * @type {string}
     * @public
     */
    description = "";
    /**
     * @type {bool}
     * @public
     */
    open = true;
}
