import Item from "class/schedule/Item";
import Absence from "./Absence";
import Teacher from "class/person/teacher/Teacher";
import StudentModule from "class/person/student/StudentModule";
import TeacherModule from "class/person/teacher/TeacherModule";

export default class FrequencySheet {
    /** @type {number} */
    id = null;

    /** @type {stirng} */
    description = "";

    /** @type {Date} */
    date = new Date();

    /** @type {string} */
    start = '00:00';

    /** @type {string} */
    end = '00:00';

    /** @type {Item} */
    scheduleItem = new Item();

    /** @type {Teacher|null} */
    teacher = null;

    /** @type {number|null} */
    teacherPay = null;

    /** @type {boolean|null} */
    status = 1;

    /** @type {boolean|null} */
    learningConversation = 1;

    /** @type {boolean|null} */
    active = 1;

    /** @type {StudentModule[]} */
    studentsModule = [];

    /** @type {Absence[]} */
    studentsAbsence = [];

    /** @type {TeacherModule|null} */
    classeModuleTeacher = null;
}