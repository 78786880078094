import * as React from 'react';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputLabel,
  Grid,
  OutlinedInput,
  Button
} from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import verify from "service/verbify";
export default function FormDocument({ id, name, type, codigo, callback }) {
  const [document, setDocument] = React.useState({ id, name: name.value, type:type.value, codigo:codigo.value });
  async function handleSubmitDocument(e) {
    e.preventDefault();
    e.stopPropagation();
    if(id > 0 || document.id > 0){
      const result = await verify.put(`/documento/${id}`, document);
      console.log(result.data);
      return;
    }
    const result = await verify.post(`/documento`, document);
    callback(result.data.model);
  }
  return (
    <>
      <Box component="form" method="post" onSubmit={handleSubmitDocument} xl={12} sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xl={6} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="document-name">Titulo do Documento</InputLabel>
              <OutlinedInput
                type="text"
                label="Titulo do Endereço"
                value={document.name || null}
                disabled={name.disable}
                onChange={(e) => {
                  setDocument((prev) => {
                    return {
                      ...prev,
                      name: e.target.value
                    }
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={6} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Tipo de documento</InputLabel>
              <OutlinedInput
                type="text"
                label="Tipo de documento"
                value={document.type || null}
                disabled={type.disable}
                onChange={(e) => {
                  setDocument((prev) => {
                    return {
                      ...prev,
                      type: e.target.value
                    }
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item xl={6} md={12} padding={0.7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="document-postal-code">Codigo/Número do Documento</InputLabel>
              <OutlinedInput
                type="text"
                label='Codigo/Número do Documento'
                value={document.codigo || null}
                disabled={codigo.disable}
                onChange={(e) => {
                  setDocument((prev) => {
                    return {
                      ...prev,
                      codigo: e.target.value
                    }
                  });
                }} />
            </FormControl>
          </Grid>
          <Grid item padding={0.7} xl={12} md={12}>
            <AnimateButton>
              <Button size="large" type="submit" variant="outlined" color="primary">
                Salvar
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
