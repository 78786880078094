import { Box, Typography } from "@mui/material";
import ObjectResponseList from "class/ObjectResponseList";
import ClasseModule from "class/secretary/classe/ClasseModule";
import { useState } from "react";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import ModalFormFrequencySheet from "./ModalFormFrequencySheets";
import DialogFrequencySheet from "./DialogFrequencySheet";
/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} props 
 * @param {[ClasseModule, React.Dispatch<ClasseModule>]} props.stateClasseModule 
 * @param {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]} props.stateList  
 * @returns {React.JSX.Element}
 */
export default function DataGridListFrequencySheet({
    stateClasseModule,
    stateList,
    height
}) {
    const classeModuleService = new ClasseModuleService();
    const [ classeModule, setClasseModule ] = stateClasseModule;

    /**
     * @type {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]}
     */
    const [ frequencySheets, setFrequencySheets ] = useState(new ObjectResponseList());

    const stateModulesClasses = stateList ?? [ frequencySheets, setFrequencySheets ];

    return <DataGridBase
        stateItens={stateModulesClasses}
        height={height ?? undefined}
        columns={[
            { field : 'date', renderCell : (data) => <Box sx={{p: 1}}>{new Date(data.row.date+"T00:00:00").toLocaleDateString()}</Box>, headerName: 'Data', width : 120 },
            { field : 'teacher', renderCell : (data) => <Typography>{data.row.teacher.person.id} - {data.row.teacher.person.name}</Typography>, headerName: 'Professor', width : 220 },
            { field : 'start', valueGetter : (data) => data.row.start, headerName: 'Inicio', width : 120 },
            { field : 'end', valueGetter : (data) => data.row.end, headerName: 'Fim', width : 120 },
            { field : 'action', renderCell : (data) => <DialogFrequencySheet frequencySheet={data.row}></DialogFrequencySheet>, headerName: 'Ver', width : 120 },
        ]}
        functionListItens={() => classeModuleService.listByClasseModule(classeModule)}
    />
}