import Cidade from "class/omie/geral/Cidade";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class CidadeService extends BaseService {
    static _baseUrl = 'omie/geral/cidade';
    constructor () {
        super(CidadeService._baseUrl);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {{uf:string}} cidadeParam 
     * @returns {Promise<import("axios").AxiosResponse<Cidade[], any>>}
     */
    list ({ ...props }, { uf }) {
        return verbify.get(`${this.baseUrl}?uf=${uf}`);
    }
}