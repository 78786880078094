import React, { createContext, useState } from 'react';

export const ProfileContext = createContext({});

const ProfileContextComponent = (props) => {
  const [user, setUser] = useState({});
  const [person, setPerson] = useState({});
  const [employee, setEmployee] = useState({});
  const [teacher, setTeacher] = useState({});
  const [student, setStudent] = useState({});

  const context = {
    singleUser: { user, setUser },
    singlePerson: { person, setPerson },
    singleEmployee: { employee, setEmployee },
    singleTeacher: { teacher, setTeacher },
    singleStudent: { student, setStudent }
  };
  
  return (
    <ProfileContext.Provider value={context}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextComponent;
