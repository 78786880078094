import { useContext, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    Grid,
    OutlinedInput,
    Button,
    Divider,
    TextField
} from "@mui/material";
import { Box } from "@mui/system";
import 'dayjs/locale/pt-br';
import verbify from 'service/verbify';
import { ProfileContext } from '../Context/ProfileContext';

const FormStudent = () => {
    const { student, setStudent } = useContext(ProfileContext).singleStudent;
    const { setUser } = useContext(ProfileContext).singleUser;
    async function load() {
        const searchUser = await verbify.get('/usuario/dados/');
        const searchStudent = await verbify.get('/aluno/dados/');
        setUser(searchUser.data);
        setStudent(searchStudent.data)
        setStudent( (prev) => {
            return {
                ...prev,
                fkUserId: searchUser.data.id
            }
        } );
    }
    useEffect(() => {
        load();
    }, [])
    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log(student);
        if(student.id > 0){
            const result = await verbify.put(`/aluno/${student.id}`, student);
            setStudent(result.data.model)
            return
        }
        const result = await verbify.post(`/aluno`, student);
        setStudent(result.data.model)
        console.log(result);
    }
    return (
        <>
            <Box component="form" id='formPersonData' method="post" onSubmit={handleSubmit} xl={12} sx={{ flexGrow: 1 }}>
                <Grid item padding={0.7}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Título Academico</InputLabel>
                        <OutlinedInput
                            type="text"
                            label="Título Academico"
                            value={student.academicTitle || ''}
                            onChange={(e) => {
                                setStudent((prev) => {
                                    return {
                                        ...prev,
                                        academicTitle: e.target.value
                                    }
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Divider sx={{marginTop: 2, marginBottom: 2 }} />
                <Grid item padding={0.7}>
                    <FormControl sx={{ width: '100%' }}>
                        <TextField 
                            id="outlined-multiline-flexible"
                            label="Experiências no Idioma (outras escolas / intercâmbios etc)"
                            multiline
                            rows={4}
                            value={student.languageExperience || ''}
                            onChange={(e) => {
                                setStudent((prev) => {
                                    return {
                                        ...prev,
                                        languageExperience: e.target.value
                                    }
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item padding={0.7}>
                    <FormControl sx={{ width: '100%' }}>
                        <TextField 
                            id="outlined-multiline-flexible"
                            label="Temas de Interesse"
                            multiline
                            rows={4}
                            value={student.topicsOfInterest || ''}
                            onChange={(e) => {
                                setStudent((prev) => {
                                    return {
                                        ...prev,
                                        topicsOfInterest: e.target.value
                                    }
                                });
                            }}
                        />
                    </FormControl>
                </Grid>
                <Button sx={{ mt: 5 }} fullWidth variant="outlined" type="submit" size="medium">Salvar dados</Button>
            </Box>
        </>
    );
}
export default FormStudent;