import { Button, Grid, TextField } from "@mui/material";
import FormSearchStudent from "../FormSearchStudent";
import { useEffect, useState } from "react";
import StudentModule from "class/person/student/StudentModule";
import { LoadingButton } from "@mui/lab";
import StudentModuleService from "service/verbify/user/student/StudentModuleService";
import ClasseModule from "class/secretary/classe/ClasseModule";
import FormSeachCustomer from "ui-component/populated/customer/FormSearchCustomer";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
// import FormSearchCustomer from "views/customer/Forms/FormSeachCustomer";

/**
 * @param {Object} props 
 * @param {ClasseModule} props.classeModule
 * @param {(studentModuleUpdateList:StudentModule) => void} props.updateList
 * @param {[StudentModule, React.Dispatch<ClasseModule>]} props.stateStudentModule
 * @returns 
 */
export default function FormStudentModule({
    classeModule,
    updateList,
    stateStudentModule
}) {
    const studentModuleService = new StudentModuleService({ classeModule });
    /**
     * @type {[boolean, React.Dispatch<boolean>]} state
     */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * @type {[StudentModule, React.Dispatch<React.SetStateAction<StudentModule>>]}
     */
    const stateDataStudentModule = useState(new StudentModule())
    const [studentModule, setStudentModule] = stateStudentModule ?? stateDataStudentModule;

    useEffect(() => {
        if(classeModule?.classe?.customer) setStudentModule((prev) => {
            prev.customer = classeModule.classe.customer;
            // prev.startDate = new Date(prev.startDate);
            // prev.endDate = new Date(prev.endDate);
            return {
                ...prev
            };
        });
    }, []);

    function limpar() {
        setStudentModule(new StudentModule());
    }
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} ev
     * @returns {void}
     */
    function save(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        
        const isUpdate = isNumber(studentModule.id) && studentModule.id > 0;
        
        let newStudentModule = {
            ...studentModule,
            classeModule: classeModule,
            startDate : typeof studentModule.startDate == "string" ? studentModule.startDate : studentModule.startDate.toJSON().substring(0, 10),
            endDate : studentModule.endDate ? (typeof studentModule.endDate == "string" ? studentModule.endDate : studentModule.endDate == null ? null : studentModule.endDate.toJSON().substring(0, 10)) : null
        };
        
        setButtonLoading(true);
        (isUpdate ? studentModuleService.update(newStudentModule) : studentModuleService.insert(newStudentModule)).then(response => {
            newStudentModule = isUpdate ? newStudentModule : { ...newStudentModule, id : response.data.model.id };

            updateList(newStudentModule);
            limpar();
        }).catch(ex => {
            console.log(ex);
        }).finally(() => setButtonLoading(false))
    }
    return <form
        onSubmit={save.bind(this)}
        style={{
            marginTop: 8
        }}
    >
        <Grid
            container
            spacing={1}
            sx={{
                '& > * > * ': { width: '100%' },
                padding: 0
            }}
        >
            <Grid item xs={12} sm={2} md={2}>
                <TextField
                    label={"% do estudande"}
                    required
                    type="number"
                    inputProps={{
                        min: "0",
                        max: "100",
                        step: "5"
                    }}
                    onChange={(e) => {
                        setStudentModule(prev => {
                            if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0)
                                prev.percentageOfStudent = Number(e.target.value);
                            return {
                                ...prev
                            };
                        });
                    }}
                    value={studentModule?.percentageOfStudent || 0}
                ></TextField>
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
                <TextField
                    label={"% do estudande (AVA)"}
                    required
                    type="number"
                    inputProps={{
                        min: "0",
                        max: "100",
                        step: "5"
                    }}
                    onChange={(e) => {
                        setStudentModule(prev => {
                            if (Number(e.target.value) <= 100 && Number(e.target.value) >= 0)
                                prev.percentageOfStudentAVA = Number(e.target.value);
                            return {
                                ...prev
                            };
                        });
                    }}
                    value={studentModule?.percentageOfStudentAVA || 0}
                ></TextField>
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
                <DateField
                    label={"Data inicial"}
                    value={dayjs(studentModule?.startDate)}
                    onChange={(newValue) => setStudentModule(prev => {
                        return {
                            ...prev,
                            startDate : new Date(Date.parse(newValue.$d))
                        };
                    })}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
                <DateField
                    label={"Data final"}
                    value={studentModule?.endDate ? dayjs(studentModule?.endDate) : null}
                    onChange={(newValue) => 
                        setStudentModule(prev => {
                            return {
                                ...prev,
                                endDate : newValue ? new Date(Date.parse(newValue.$d)) : null
                            };
                        })
                    }
                />
            </Grid>

            {
                <Grid item xs={12} sm={4} md={4}>
                    <FormSearchStudent
                        required={true}
                        disabled={studentModule.id != null && studentModule.id > 0}
                        setStudent={(student) => {
                            setStudentModule(prev => {
                                prev.student = student;
                                return {
                                    ...prev
                                };
                            })
                        }}
                        student={studentModule.student}
                    ></FormSearchStudent>
                </Grid>
            }

            <Grid item xs={12} sm={4} md={3}>
                <FormSeachCustomer
                    required
                    customer={studentModule.customer}
                    setCustomer={(customer) => {
                        setStudentModule(prev => {
                            prev.customer = customer;
                            return prev;
                        });
                    }}
                    minimumLength={3}
                />
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Grid>

            {(isNumber(studentModule?.id) && studentModule?.id > 0) && <Grid item xs={12}>
                <Button
                    variant="outlined"
                    color='error'
                    onClick={(e) => limpar()}
                >Cancelar</Button>
            </Grid>}
        </Grid>
    </form >
}