// assets

import { LoyaltyOutlined } from "@mui/icons-material";


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const sales = {
    id: 'sales',
    title: 'Vendas',
    type: 'collapse',
    icon: LoyaltyOutlined,
    // icon: LoyaltyOutlinedIcon,
    children: [
        {
            id: 'schoolOpportunities',
            title: 'Oportunidades',
            type: 'item',
            url: '/escola/comercial/oportunidades',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'schoolProducts',
            title: 'Produtos',
            type: 'item',
            url: '/escola/comercial/produtos',
            target: false,
            breadcrumbs: false
        },
        // {
        //     id: 'schoolTask',
        //     title: 'Tarefas',
        //     type: 'item',
        //     url: '/escola/comercial/tarefas',
        //     target: false,
        //     breadcrumbs: false
        // }
    ]
};

export default sales;
