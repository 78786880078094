import Module from "class/secretary/Module";
import ModuleService from "service/verbify/secretary/ModuleService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} param0 
 * @param {Module} param0.module
 * @param {(module:Module) => void} param0.setModule
 * @param {string} param0.label
 * @param {boolean|undefined} param0.required
 * @returns {React.JSX.ReactElement}
 */
export default function FormSeachModule({
    module,
    setModule,
    label,
    required
}) {
    const modeleService = new ModuleService();
    return <FormSearch 
        required={required}
        loadList={(...props) => modeleService.searchFor(...props)}
        searchPatch={'/buscar/modulo'}
        searchTable={'module'}
        searchColumn={'title'}
        label={label ?? "Níveis"}
        minimumLength={2}
        item={module}
        setItem={setModule}
        valueExtractor={
            /**
             * @param {Module} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Module} option 
             * @returns 
            */
            (option) => option.id > 0 ? `${option?.language?.title} - ${option?.title}` : ''
        }
    />
}