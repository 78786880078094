import { lazy } from "react";

import Loadable from 'ui-component/Loadable';
// import ProfileStudent from "views/profiles/ProfileStudent";
import MainLayout from "layout/MainLayout";
import PaymentAdmin from "views/payment/paymentAdmin";
// import Employee from "views/employee";
const BillsToPayrol = Loadable(lazy(() => import('views/payment/BillsToPayroll')));
const BillsToReceiveTeacher = Loadable(lazy(() => import("views/payment/BillsToReceiveTeacher")));
const UserView = Loadable(lazy(() => import("views/school/user/UserView")));
const EmployeeView = Loadable(lazy(() => import("views/school/user/employee/EmployeeView")));
const OpportunityView = Loadable(lazy(() => import("views/school/opportunity/OpportunityView")));
const Employee = Loadable(lazy(() => import("views/old-employee")));
const PresentationView = Loadable(lazy(() => import("views/wordpad/WordpadView")));
const WordpadPageTamplateView = Loadable(lazy(() => import("views/wordpad/page/tamplate/WordpadPageTamplateView")));
const CustomFormView = Loadable(lazy(() => import("views/customForm/CustomFormView")));
const ProductView = Loadable(lazy(() => import("views/school/product/ProductView")));
const BillsToReceiveStudent = Loadable(lazy(() => import("views/payment/BillsToReceiveStudent")));
const CustomFormResponseView = Loadable(lazy(() => import("views/customForm/response/CustomFormResponseView")));
const User = Loadable(lazy(() => import('views/school/user/old')))
const DocumentView = Loadable(lazy(() => import('views/document/DocumentView')))
const CustomerView = Loadable(lazy(() => import('views/customer/CustomerView')))

const SchoolRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        {
            path: '/escola/cliente',
            element: <CustomerView />
        },
        {
            path: '/escola/comercial/oportunidades',
            element: <OpportunityView />
        },
        {
            path: '/escola/comercial/produtos',
            element: <ProductView />
        },
        {
            path: '/escola/documentos',
            element: <DocumentView />
        },
        {
            path: '/escola/comercial/apresentacao',
            element: <PresentationView />
        },
        {
            path: '/escola/formularios',
            element: <CustomFormView />
        },
        {
            path: '/escola/formularios/:customForm/resposta/',
            element: <CustomFormResponseView />
        },
        {
            path: '/escola/formularios/:customForm/resposta/:customFormResponse',
            element: <CustomFormResponseView />
        },
        {
            path: '/escola/comercial/apresentacao/pagina/tamplate',
            element: <WordpadPageTamplateView />
        },
        {
            path: '/escola/financeiro/gerar/mensalidade', 
            element: <BillsToReceiveStudent />
        },
        {
            path: '/escola/financeiro/gerar/folha-pagamento', 
            element: <BillsToReceiveTeacher />
        },
        {
            path: '/escola/financeiro/folha-pagamento', 
            element: <BillsToPayrol />
        },
        {
            path: '/escola/financeiro/pagamentos', 
            element: <PaymentAdmin />
        },
        {
            path: 'escola/usuario/v2', 
            element: <User />
        },
        {
            path: 'escola/usuario/', 
            element: <UserView/>
        },
        // {
        //     path: 'escola/usuario/novo', 
        //     element: <AddUser />
        // },
        {
            path: 'escola/funcionario', 
            element: <EmployeeView />
        },
        {
            path: 'escola/funcionario/v2', 
            element: <Employee />
        }
        // {
        //     path: '/onboarding/cliente', 
        //     element: <AddUser />
        // },
        // {
        //     path: '/onboarding/professor', 
        //     element: <ProfileTeacher />
        // },
        // {
        //     path: '/onboarding/aluno', 
        //     element: <ProfileStudent />
        // }
    ]
}

export default SchoolRoutes;