import Country from "class/Country"
import FormSearch from "ui-component/FormSeach"

/**
 * @param {{
 *     country:Country,
 *     setCountry:(country:Country) => void 
 * }} param0 
 * @returns 
 */
export default function FormSearchCountry({
    country,
    setCountry,
    loadList,
    required
}) {

    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/pais'}
            searchTable={'country'}
            searchColumn={'name'}
            label="Países"
            item={country}
            setItem={setCountry}
            loadList={loadList}
            valueExtractor={
                /**
                 * @param {Country} option 
                 * @returns 
                 */
                (option) => option?.code
            }
            textExtractor={
                /**
                 * @param {Country} option 
                 * @returns 
                */
                (option) => option.code == 0 ? '' : `${option.code} - ${option.name}`
            }
        ></FormSearch>
    )
}