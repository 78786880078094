import ClasseModule from "class/secretary/classe/ClasseModule";
import TeacherModule from "class/person/teacher/TeacherModule";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

/**
 * @extends {BaseService<TeacherModule>}
 */
export default class TeacherModuleService extends BaseService {
    /**
     * @type {string} baseUrl
     * @public
     */
    static baseUrl = 'turma-module/{classeModule}/professor';
    /**
     * @param {Object} props
     * @param {ClasseModule} props.classeModule 
     */
    constructor ({ classeModule }) {
        if(classeModule) super(TeacherModuleService.baseUrl.replace('{classeModule}', classeModule.id));
        else super(TeacherModuleService._baseUrl);
    }
     /**
     * @param {TeacherModule} data 
     * @returns {Promise<AxiosResponse<ObjectResponse<TeacherModule>, any>>}
     */
    insert(teacherModule) {
        return verbify.post(`${this.baseUrl}`, teacherModule);
    }

     /**
     * @param {TeacherModule} teacherModule 
     * @returns {Promise<AxiosResponse<ObjectResponse<TeacherModule>, any>>}
     */
    update(teacherModule) {
        return verbify.put(`${this.baseUrl.replace('{classeModule}', teacherModule.classeModule.id.toString())}/${teacherModule.teacher.person.id}`, teacherModule);
    }
    /**
     * 
     * @param {Object} param 
     * @param {Date|string} param.startDate
     * @returns {Promise<import("axios").AxiosResponse<TeacherModule[], any>>}
     */
    listAgenda({
        startDate
    }) {
        return verbify.get(`${TeacherModuleService.baseUrl.replace('{classeModule}/', '')}/agenda?startDate=${typeof startDate === 'string' ? startDate : startDate.toJSON()}`)
    }
}