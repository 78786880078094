export default class AbsenceType {
  /**
   * @type {number}
   * @public
   */
  id = null;
  /**
   * @type {string}
   * @public
   */
  title = null;
  /**
   * @type {bool}
   * @public
   */
  allowsReplacement = 0;
  /**
   * @type {bool}
   * @public
   */
  studentOrTeacher = 0
  /**
   * @type {bool}
   * @public
   */
  holiday = 0;
}
