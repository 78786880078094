import Proposal from "class/proposal/Proposal";
import Schedule from "class/schedule/Schedule";
import Classe from "class/secretary/Classe";
import Module from "class/secretary/Module";
import Duration from "class/utils/Duration";

export default class ClasseModule {
  /**
   * @param {ClasseModule} data
   */
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.status = data.isOperation;
      this.duration = new Duration(data.duration);
      this.minimumFrequency = data.minimumFrequency;
      this.classe = data.classe;
      this.module = data.module;
      this.schedule = data.schedule;
      this.proposal = data.proposal;
    }
  }
  /**
   * @type {number}
   * @public
   */
  id = null;
  /**
  * @type {number}
  * @public
  */
  status = 0;
  /**
   * @type {Duration}
  */
  duration = new Duration();
  /**
  * @type {number}
  * @public
  */
  minimumFrequency = 0.0;
  /**
  * @type {number}
  * @public
  */
  dueDay = 10;
  /**
  * @type {number}
  * @public
  */
  dayGenerateInstallment = 1;
  /**
  * @type {number}
  * @public
  */
  numberDaysAfterExpiration = 5;
  /**
  * @type {Classe}
  * @public
  */
  classe = new Classe();
  /**
 * @type {Module}
 * @public
 */
  module = new Module();
  /**
   * @type {Schedule}
   * @public
   */
  schedule = new Schedule();
  /**
   * @type {Proposal}
   * @public
   */
  proposal = new Proposal();
}