import ObjectResponseList from "class/ObjectResponseList";
import ClasseModule from "class/secretary/classe/ClasseModule";
import { useState } from "react";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import { IconCalendar, IconEdit, IconListDetails, IconMoneybag, IconSchool, IconUsers } from "@tabler/icons";
import { Box, Button, IconButton, Stack, useTheme } from "@mui/material";
import DialogFormItem from "ui-component/populated/schedule/item/DialogFormItem";
import DialogFormStudentModule from "ui-component/populated/user/student/module/DialogFormStudentModule";
import DialogFormTeacherModule from "ui-component/populated/user/teacher/module/DialogFormTeacherModule";
import ModalFormProposal from "ui-component/populated/proposal/ModalFormProposal";
import ModalFormFrequencySheet from "../frequency/ModalFormFrequencySheets";
import ModalFormClasseModuleFull from "../classe/module/ModalFormClasseModuleFull";
import FormClasseModuleStatus from "../classe/module/FormClasseModuleStatus";
/**
 * Retorna uma tabela populada com os idiomas cadastrados
 * @param {Object} props 
 * @param {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]} props.stateList  
 * @returns {React.JSX.Element}
 */
export default function DataGridClasseModule({
    stateList,
}) {
    const classeModuleService = new ClasseModuleService();

    /**
     * @type {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]}
     */
    const [classesModules, setClassesModules] = useState(stateList ? null : {
        rows: [],
        totalRows: 0
    });

    const stateModulesClasses = stateList ?? [classesModules, setClassesModules];

    function setItem(classeModule) {
        stateModulesClasses[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == classeModule.id)) {
                const index = newListItens.findIndex(f => f.id == classeModule.id);

                newListItens[index] = classeModule;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        classeModule,
                        ...newListItens
                    ]
                }
            }
        });
    }

    const theme = useTheme();

    return <DataGridBase
        stateItens={stateModulesClasses}
        columns={[
            {
                field: 'option',
                renderCell: (item) => <Stack flexWrap={'wrap'} display={'flex'} direction={'row'}>
                    <ModalFormClasseModuleFull classeId={item.row.classe.id} classeModuleId={item.row.id} label={<IconButton><IconEdit color={theme.palette.primary.main}></IconEdit></IconButton>}></ModalFormClasseModuleFull>
                </Stack>,
                headerName: 'Opções',
                width: 70
            },
            { field: 'id', headerName: 'Código', width: 70 },
            {
                field: 'status', renderCell: (data) => <Box>
                    <FormClasseModuleStatus updateList={setItem} classeModuleData={data.row}></FormClasseModuleStatus>
                </Box>, headerName: 'Status', width: 150
            },
            { field: 'classe', valueGetter: (data) => `${data.row.classe.id} - ${data.row.classe.title}`, headerName: 'Turma', width: 150 },
            { field: 'module', valueGetter: (data) => `${data.row.module.id} - ${data.row.module.title}`, headerName: 'Modulo', width: 150 },
            { field: 'language', valueGetter: (data) => `${data.row.language}`, headerName: 'Idioma', width: 100 },
            {
                field: 'students',
                renderCell: (data) => {
                    return <Button
                        disabled={data.row?.students?.length > 0 ? false : true}
                        variant="contained"
                        sx={{
                            wordWrap: "break-word"
                        }}
                    >
                        {
                            data.row?.students?.length > 0 ? "Ver alunos" : "Sem alunos"
                        }
                    </Button>;
                }, 
                headerName: 'Alunos', width: 150
            },
            {
                field: 'teachers',
                renderCell: (data) => {
                    return <Button
                        disabled={data.row?.teachers?.length > 0 ? false : true}
                        variant="contained"
                        sx={{
                            wordWrap: "break-word"
                        }}
                    >
                        {
                            data.row?.teachers?.length > 0 ? "Ver professores" : "Sem professores"
                        }
                    </Button>;
                }, 
                headerName: 'Professores', width: 150
            },
            {
                field: 'customers', valueGetter: (data) => {
                    return data.row.students?.map(item => {
                        return " " + item.c_name
                    })
                }, headerName: 'Empresas', width: 200
            },
            { field: 'duration', valueGetter: (data) => data.row.duration, headerName: 'Duração', width: 150 },
        ]}
        functionListItens={(page) => classeModuleService.list(page, new ClasseModule())}
    />
}