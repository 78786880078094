import ObjectRequest from 'class/ObjectRequest'
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
import { AxiosResponse } from 'axios';
import ObjectResponseList from 'class/ObjectResponseList';
import WordpadPage from 'class/wordpad/page/WordpadPage';
import CustomForm from 'class/customForm/CustomForm';
import CustomFormQuestion from 'class/customForm/question/CustomFormQuestion';
/**
 * @extends BaseService<CustomForm>
 */
export default class CustomFormQuestionService extends BaseService {
    static urlBase = `/formulario/{id}/questao`;
    constructor() {
        super(CustomFormQuestionService.urlBase);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {CustomForm} customForm 
     * @returns {Promise<AxiosResponse<ObjectResponseList<CustomForm>, any>>}
     */
    list (currentPage, customForm) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`/formulario/${customForm?.id}/questao?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {CustomFormQuestion} customFomQuestion 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert (customFomQuestion) {
        return verbify.post(`/formulario/${customFomQuestion.customForm.id}/questao`, customFomQuestion);
    }
    /**
     * @param {CustomFormQuestion} customFomQuestion 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update (customFomQuestion) {
        return verbify.put(`/formulario/${customFomQuestion.customForm.id}/questao/${customFomQuestion.id}`, customFomQuestion);
    }
}