import Recurrence from "class/utils/Recurrence";

/**
 * @type {Recurrence[]}
 */
const DataRecurrence = [
    //{ label: "Hora", id: 1 },
    { label: "Dia", id: 1 },
    { label: "Semana", id: 2 },
    { label: "Mês", id: 3 }
];
export default DataRecurrence;