import ObjectRequest from "class/ObjectRequest";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import Assessment from "class/secretary/frequency/assessment/Assessment";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class AssessmentService extends BaseService {
    static baseUrl = "folha-de-frequencia/{idFF}/avaliacao";
    constructor () {
        super(AssessmentService.baseUrl);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {FrequencySheet} frequencySheet 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    list (currentPage, frequencySheet) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`/avaliacao?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {Assessment} assessment 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert (assessment) {
        return verbify.post(`/folha-de-frequencia/${assessment.frequencySheet.id}/avaliacao`, assessment);
    }
    /**
     * @param {number} id 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    search (id) {
        return verbify.get(`/folha-de-frequencia/${id}/avaliacao/`);
    }
    /**
     * @param {Assessment} assessment 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update (assessment) {
        return verbify.put(`/folha-de-frequencia/${assessment.frequencySheet.id}/avaliacao/${assessment.id}`, assessment);
    }
}