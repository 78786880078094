
import * as React from 'react';
import ModalForm from 'ui-component/ModalForms';
import FormDocument from '../Form/FormDocument';
 
export default function ModalDocument(props) {
  return (
    <>
      <ModalForm
        name={"Adicionar "+props.name.value}
        description={"Informe o documento "+props.type.value+" abaixo"}
        form={<FormDocument id={props.id} name={props.name} type={props.type} codigo={props.codigo} callback={props.callback}></FormDocument>}
        buttonLabel={props.label}
        width={'md'}
      >
      </ModalForm>
    </>
  );
}

