import CustomForm from 'class/customForm/CustomForm';
import BaseService from 'service/BaseService';
/**
 * @extends {BaseService<CustomForm>}
 */
export default class CustomFormService extends BaseService {
    static urlBase = "formulario";
    constructor() {
        super(CustomFormService.urlBase);
    }
}