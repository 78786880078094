import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditorProvider,
  RichTextField,
} from "mui-tiptap";
import { useState } from "react";
export default function RichText({ stateContent, height }) {
  const stateText = useState("");
  const [richTextContent, setRichTextContent] = stateContent ?? stateText;

  const editor = useEditor({
    content: richTextContent,
    onUpdate({ editor }) {
      setRichTextContent(editor.getHTML());
    },
    extensions: [StarterKit],
  });

  return (
    <RichTextEditorProvider editor={editor}>
      <RichTextField
        controls={
          <MenuControlsContainer>
            <MenuSelectHeading />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            {/* Add more controls of your choosing here */}
          </MenuControlsContainer>
        }
      />
    </RichTextEditorProvider>
  );
}