import Country from "class/Country";
import User from "./user/User";
import Address from "class/address/Address";
import Document from "class/document/Document";

export default class Person {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {number}
     * @public
     */
    type = null;
    /**
     * @type {string}
     * @public
     */
    name = "";
    /**
     * @type {string}
     * @public
     */
    nickName = "";
    /**
     * @type {string}
     * @public
     */
    photo = "";
    /**
     * @type {Date}
     * @public
     */
    dateOfBirth = "";
    /**
     * @type {Country}
     * @public
     */
    countryOfOrigin = new Country();
    /**
     * @type {Document}
     * @public
     */
    federalDocument = new Document();
    /**
     * @type {Document}
     * @public
     */
    stateDocument = new Document();
    /**
     * @type {Address}
     * @public
     */
    address = new Address();
    /**
     * @type {User}
     * @public
     */
    user = new User();
}
