import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import { Avatar, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import Person from "class/person/Person";
import FormPerson from "./FormPerson";
import ObjectResponseList from "class/ObjectResponseList";

/**
 * 
 * @param {{label:string, person?:Person}} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormPerson({ stateList, label, person, user, stateOpen }) {
    const openState = useState(false);
    const [ open, setOpen ] = stateOpen ?? openState;

    const list = useState(new ObjectResponseList());

    const statePersons = stateList ?? list;

    function setItem(person) {
        statePersons[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == person.id)) {
                const index = newListItens.findIndex(f => f.id == person.id);

                newListItens[index] = person;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        ...newListItens,
                        person
                    ]
                }
            }
        });
    }
    const theme = useTheme()
    return (
        <ModalForm
            title={"Adicionar pessoa"}
            description={"Informe os dados da pessoa nos campos abaixo:"}
            form={
                <>
                    <Box padding={2} bgcolor={"primary.light"} borderRadius={theme.shape.borderRadius}>
                        <FormPerson personData={person} userData={user} setItem={setItem}></FormPerson>
                    </Box>

                </>

            }
            buttonLabel={label}
            width={'md'}
            open={open}
            setOpen={setOpen}
        >
        </ModalForm>
    );
}