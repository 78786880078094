import ObjectRequest from 'class/ObjectRequest'
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
import { AxiosResponse } from 'axios';
import ObjectResponseList from 'class/ObjectResponseList';
import WordpadPage from 'class/wordpad/page/WordpadPage';
import CustomForm from 'class/customForm/CustomForm';
import CustomFormResponseQuestion from 'class/customForm/question/response/CustomFormResponseQuestion';
import CustomFormResponse from 'class/customForm/response/CustomFormResponse';
/**
 * @extends BaseService<CustomFormResponseQuestion>
 */
export default class CustomFormResponseQuestionService extends BaseService {
    static urlBase = `/resposta/{id}/questao`;
    constructor() {
        super(CustomFormResponseQuestionService.urlBase);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {CustomFormResponseQuestion} customFormResponseQuestion 
     * @returns {Promise<AxiosResponse<ObjectResponseList<CustomFormResponseQuestion>, any>>}
     */
    search(customFormResponseQuestion) {
        return verbify.get(`formulario/${customFormResponseQuestion?.customFormResponse?.id}/resposta/${customFormResponseQuestion?.id}`);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {CustomFormResponse} customFormResponse 
     * @returns {Promise<AxiosResponse<ObjectResponseList<CustomFormResponse>, any>>}
     */
    list(currentPage, customFormResponse) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`/resposta/${customFormResponse?.id}/questao?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {CustomFormResponseQuestion} customFormResponseQuestion 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert(customFormResponseQuestion) {
        return verbify.post(`/resposta/${customFormResponseQuestion.customFormResponse.id}/questao`, customFormResponseQuestion);
    }
    /**
     * @param {CustomFormResponseQuestion} customFormResponseQuestion 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update(customFormResponseQuestion) {
        return verbify.put(`/resposta/${customFormResponseQuestion.customFormResponse.id}/questao/${customFormResponseQuestion.customFormQuestion.id}`, customFormResponseQuestion);
    }
}