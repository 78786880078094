import Person from "class/person/Person";
import { useEffect } from "react";
import PersonService from "service/verbify/user/person/PersonService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {Person} props.person
 * @param {(person:Person) => void} props.setPerson
 * @returns 
 */
export default function FormSearchMysPerson({
    label,
    person,
    setPerson,
    loadList,
    stateList,
    required
}) {
    const service = new PersonService();
    const search = () => service.mysPerson()
    return (
        <FormSearch
            stateList={stateList}
            required={required}
            searchPatch={'/lista-de-pessoas-relacionas'}
            searchTable={'person'}
            searchColumn={'name'}
            label="Selecionar perfil / empresa"
            item={person}
            setItem={setPerson}
            loadList={loadList ?? search}
            minimumLength={0}
            valueExtractor={
                
                (option) => {
                    return option.id
                }
            }
            textExtractor={
                (option) => {
                    // console.log(option, "omoianvioanvonavdo");
                    return option.id == 0 ? '' : `${option.id} - ${option.name}`
                }
            }
        ></FormSearch>
    )
}