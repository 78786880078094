import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import Person from "class/person/Person";
import User from "class/person/user/User";
import FormProduct from "./FormProduct";
import ObjectResponseList from "class/ObjectResponseList";

/**
 * 
 * @param {{label:string, person?:Person, user?:User}} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormProduct({ stateList, label, product, stateOpen }) {
    
    const openState = useState(false);
    const [ open, setOpen ] = stateOpen ?? openState;

    const [products, setProducts] = useState(new ObjectResponseList());

    const stateProduct = stateList ?? [products, setProducts];

    function setItem(data) {
        stateProduct[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == data.id)) {
                const index = newListItens.findIndex(f => f.id == data.id);

                newListItens[index] = data;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        ...newListItens,
                        data
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Adicionar produto"}
            description={"Informe os dados da produto nos campos abaixo:"}
            form={<FormProduct productData={product} setOpenModal={setOpen} setItem={setItem}></FormProduct>}
            buttonLabel={label}
            width={'md'}
            open={open}
            setOpen={setOpen}
        >
        </ModalForm>
    );
}