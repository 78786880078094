/**
 * @template T
 */
export default class BaseJsonService {
    /**
     * @param {Array<T>} data 
     */
    constructor (data) {
        this.data = data;
    }
    /**
     * @param {string} column 
     * @param {string | number} value 
     */
    search(column, value) {
        if(column && value) return this.data.find(object => object[column] == value);
        if(process.env.NODE_ENV === "development") console.warn("Informe uma coluna e um value para realizar a busca");
        return null
    }
    /**
     * @param {string} search // word or phrase
     * @param {string} searchColumn 
     * @returns {Promise<{ data: { rows:T[] } }>}
     */
    searchFor(searchColumn, search) {
        return Promise.resolve(this.list(searchColumn, search) );
    }
    /**
     * @param {string} column 
     * @param {string | number} value 
     */
    list({ column, value, callback}) {
        let result;
        if(!(column && value)) result = this.data;
        else result = callback ? callback(this.data) : this.data.filter((country) => country[column] == value)
        return  { data: { rows: result, } }
    }
}