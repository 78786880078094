import React, { useState } from 'react';
import DataGridBase from 'ui-component/populated/base/DataGridBase';
import ObjectResponseList from 'class/ObjectResponseList';
import AceService from 'service/verbify/ace/AceService';
import Ace from 'class/ace/Ace';
import { Link } from '@mui/material';

/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} param0 
 * @param {[ObjectResponseList<Ace>, React.Dispatch<ObjectResponseList<Ace>>]} param0.stateList 
 * @returns {React.JSX.Element}
 */    
export default function DataGridAceForStudent({
    classeModuleStudentData,
    stateList
}) {
    const aceService = new AceService();
    /** * @type {[ObjectResponseList<Ace>, React.Dispatch<ObjectResponseList<Ace>>]} */
    const [ aces, setAces ] = useState(stateList ? null : new ObjectResponseList());
    /** * @type {[ObjectResponseList<Ace>, React.Dispatch<ObjectResponseList<Ace>>]} */
    const stateAces = stateList ?? [ aces, setAces ];
    
    return (
        <DataGridBase 
            stateItens={stateAces}
            functionListItens={(page) => aceService.listByClasseModuleStudent(page, classeModuleStudentData)}
            columns={[
                // { field: 'actions', width : 200,  headerName : 'Ações', renderCell: (params) => {
                //     return <>
                //         <ModalFormCustomForm id={params.row.id} label={<IconPencil></IconPencil>}></ModalFormCustomForm>
                //         <DialogCustomFormQuestion 
                //             customForm={params.row}
                //             buttonOpenModal={<IconButton variant={'text'} autoFocus><IconQuestionCircle size={20}></IconQuestionCircle></IconButton>}
                //         >
                //         </DialogCustomFormQuestion>
                //     </>
                // }},
                { field: 'id', headerName: 'Código', width : 200 },
                { field: 'referenceDate', valueGetter: (params) => params.row.referenceDate ? new Date(params.row.referenceDate+" 00:00:00").toLocaleDateString() : "-", headerName: 'Data de referencia', width : 200 },
                { field: 'responseDate', valueGetter: (params) => params.row.responseDate ? new Date(params.row.responseDate+" 00:00:00").toLocaleDateString() : "-", headerName: 'Data de resposta', width : 200 },
                { field: 'customFormResponseId', valueGetter: (params) => params.row?.customFormResponse?.id, headerName: 'Resposta', width : 200 },
                { field: 'customFormId', valueGetter: (params) => params.row?.customForm?.title, headerName: 'Resposta', width : 200 },
                { field: 'link', renderCell: ({ row }) => <Link 
                    href={`${process.env.REACT_APP_URL_APP}ext/ace-resposta/${row?.id}`} 
                    target='_blank'
                >Link</Link>, headerName:"Link", width: 100}
            ]}
        />
    );
}