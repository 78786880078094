import { PixOutlined, PixRounded, PixSharp } from "@mui/icons-material";
import { Button } from "@mui/material";
import { IconEdit, IconPremiumRights } from "@tabler/icons";
import ObjectResponseList from "class/ObjectResponseList";
import Payment from "class/payment/Payment";
import { useState } from "react";
import { Link } from "react-router-dom";
import PaymentService from "service/verbify/payment/PaymentService";
import DataGridBase from "ui-component/populated/base/DataGridBase";

const colunas = [
    { 
        field: 'option', 
        renderCell : (params) => <>
            <Link
                to={`/pagamento/${params.row.id}`}  
            >
                <Button
                    variant={'text'} 
                    autoFocus 
                >
                    <IconPremiumRights size={32}></IconPremiumRights>
                </Button>
            </Link>
        </>, 
        headerName: 'Opções', 
        width : 80
    },
    { 
        field: 'parcelName', 
        renderCell : ({ row }) => `${row.numberInstallment}º`, 
        headerName: 'Parcela', 
        width : 100 
    },
    { field: 'dueDate', headerName: 'Vencimento', width : 100 },
    { field: 'valueBase', headerName: 'Valor (R$)', width : 120 },
    { field: 'beneficiaryName', valueGetter : ({ row }) => row.beneficiary.name, headerName : 'Beneficiario', width : 200 }
];

export default function DataGridBillsToPay() {
    const paymentService = new PaymentService();
    /**
     * @type {[ObjectResponseList<Payment>[], React.Dispatch<React.SetStateAction<ObjectResponseList<Payment>>>]}
     */
    const [ payments, setPayments ] = useState(new ObjectResponseList());

    return <DataGridBase
        columns={colunas}
        functionListItens={(page) => paymentService.list({ page })}
        mapRow={(row) => Object.assign(new Payment(), row)}
        stateItens={[ payments, setPayments ]}
        keyExtractor={(item) => item.id}
    ></DataGridBase>
}