import Item from "./Item";

export default class Schedule {
    /**
     * @type {null|number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {Item[]}
     */
    itens = [];
}