export default class Origin {
    /**
     * @type {number|null}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    name = "";
}

/**
 * @readonly
 * @enum {number}
 */
export const EnumOrigin = {
    MENSALIDADE : 1,
    FOLHADEPAGAMENTO : 2
}