import BaseService from "service/BaseService";
import viaCep from 'service/viaCep/request';

export default class AddressService extends BaseService {
    static baseUrl = "endereco";
    constructor () {
        super(AddressService.baseUrl);
    }
    /**
     * @param {string} cep 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    searchAddressByCep(cep) {
        return viaCep.get(`${cep}/json/`);
    }
}