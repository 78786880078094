import BaseService from "service/BaseService";
import verbify from "../config";
import PaymentFetlock from "class/payment/PaymentFetlock";

/**
 * @extends {BaseService<PaymentFetlock>}
 */
export default class PaymentFetlockService extends BaseService {
    static _baseUrl = 'pagamento/boleto';
    constructor () {
        super(PaymentFetlockService._baseUrl);
    }
    /**
     * @param {Object} param
     * @param {number} param.identificador Identificador do pagamento
     * @returns {Promise<import("axios").AxiosResponse<PaymentFetlock|null, any>>}
     */
    get({
        identificador
    }) {
        return verbify.get(`${PaymentFetlockService._baseUrl}?paymentId=${identificador}`);
    }
    /**
     * @param {Object} param
     * @param {number} param.paymentId Identificador do pagamento
     * @returns {Promise<import("axios").AxiosResponse<PaymentFetlock|null, any>>}
     */
    generate({
        paymentId
    }) {
        return verbify.post(`${PaymentFetlockService._baseUrl}/gerar`, { paymentId : paymentId });
    }
}