import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import ObjectResponseList from "class/ObjectResponseList";
import CustomForm from "class/customForm/CustomForm";
import FormAce from "./FormAce";
import { Box } from "@mui/material";
import DataGridAceForStudent from "./DataGridAceForStudent";

/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {number|null|undefined} param0.id
 * @param {[ObjectResponseList<CustomForm>, React.Dispatch<ObjectResponseList<CustomForm>>]} param0.stateList 
 * @returns {React.JSX.Element}
 */
export default function ModalAceForm({
    stateList, 
    label, 
    id,
    classeModuleStudentData
}) {
    const [ idState, setIdState ] = useState(id);
    /**
     * @type {[ObjectResponseList<CustomForm>, React.Dispatch<ObjectResponseList<CustomForm>>]}
     */
    const [ newListAces, setNewListAces ] = useState(new ObjectResponseList());
    const [ aces, setAces ] = stateList ?? [ newListAces, setNewListAces ];

    const currentItem = aces.rows.find(f => f.id == idState);

    function setItem(aces) {
        setAces(prev => {
            const newListItens = [ ...prev.rows ];

            if (newListItens.some(any => any.id == aces.id)) { 
                const index = newListItens.findIndex(f => f.id == aces.id);
                
                newListItens[index] = aces;

                return {
                    ...prev,
                    rows : [ ...newListItens ]
                }
            }
            else {
                setIdState(aces.id);
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        ...newListItens,
                        aces
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Gerenciar ACE"}
            description={"Informe os dados da ace nos campos abaixo:"}
            form={<Box>
                    <FormAce classeModuleStudentData={classeModuleStudentData} id={idState} item={currentItem} setItem={setItem}></FormAce>
                    <DataGridAceForStudent classeModuleStudentData={classeModuleStudentData} stateList={[aces, setAces]}></DataGridAceForStudent>
                </Box>
            }
            buttonLabel={label}
            width={'xl'}
        >
        </ModalForm>
    );
}