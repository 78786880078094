import Person from 'class/person/Person';
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
/**
 * @extends BaseService<Person>
 */
export default class PersonService extends BaseService {
    static baseUrl = "pessoa";
    constructor () {
        super(PersonService.baseUrl);
    }
    mysPerson () {
        return verbify.get(`lista-de-pessoas-relacionas`);
    }
    /**
     * @param {Person} person 
     * @returns 
     */
    syncOmie(person) {
        return verbify.post(`pessoa/sync-omie`, person); 
    }
}