import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import AssessmentStudentNoteService from "service/verbify/secretary/frequency/assessment/AssessmentStudentNoteService";
import AssessmentStudentNote from "class/secretary/frequency/assessment/AssessmentStudentNote";

/**
 * 
 * @param {object} props 
 * @param {AssessmentStudentNote} props.assessmentStudentNoteData
 * @returns 
 */
export default function FormAssessmentStudentNote({ assessmentStudentNoteData, setOpenModal, setItem }) {
    const [assessmentStudentNote, setAssessmentStudentNote] = useState(assessmentStudentNoteData ?? new AssessmentStudentNote());
    const assessmentStudentNoteService = new AssessmentStudentNoteService();

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        if(evento) {
            evento.preventDefault();
            evento.stopPropagation();
        }

        setButtonLoading(true);

        const isUpdate = assessmentStudentNote?.id != undefined && assessmentStudentNote?.id != null;

        (isUpdate ? assessmentStudentNoteService.update(assessmentStudentNote) : assessmentStudentNoteService.insert(assessmentStudentNote))
            .then((response) => {
                if (setOpenModal) setOpenModal(false);
                if (setItem) setItem(isUpdate ? assessmentStudentNote : { ...assessmentStudentNote, id: response?.data?.model?.id });
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(() => {
        if(assessmentStudentNote?.classeModuleStudent?.id > 0 && assessmentStudentNote?.frequencySheet?.id > 0) 
            assessmentStudentNoteService.search(assessmentStudentNote)
            .then(resp => {
                if(resp.data?.id > 0) setAssessmentStudentNote(resp.data)
            })
    }, [])

    useEffect(() => {
        console.log(assessmentStudentNote, "OANMIOVNAVOAMNVONAVOI");
    }, [assessmentStudentNote])

    return (
        <Box
            width={"100%"}
            component={"form"}
            onSubmit={save.bind(this)}
        >
            <Stack
                direction={"row"}
                spacing={1.5}
                p={1}
                alignItems={"center"}
            >
                {/* {
                    assessmentStudentNote.frequencySheet.id > 0 && <Box>
                        <Typography>Folha de Frequencia: {assessmentStudentNote?.frequencySheet?.id}</Typography>
                    </Box>
                }
                 */}
                <TextField
                    id="outlined-multiline-flexible"
                    label="Nota"
                    type="number"
                    value={assessmentStudentNote?.note}
                    onBlur={
                        e => {
                            save(e)
                        }
                    }
                    onChange={
                        e => {
                            setAssessmentStudentNote(prev => {
                                return {
                                    ...prev,
                                    note: e.target.value
                                }
                            })
                        }
                    }
                />
                <TextField
                    id="outlined-multiline-flexible"
                    label="Engajamento"
                    type="number"
                    value={assessmentStudentNote?.engagement}
                    onBlur={
                        e => {
                            save(e)
                        }
                    }
                    onChange={
                        e => {
                            setAssessmentStudentNote(prev => {
                                return {
                                    ...prev,
                                    engagement: e.target.value
                                }
                            })
                        }
                    }
                />
                <TextField
                    id="outlined-multiline-flexible"
                    label="Descrição"
                    multiline
                    rows={1}
                    value={assessmentStudentNote?.description}
                    onBlur={
                        e => {
                            save(e)
                        }
                    }
                    onChange={
                        e => {
                            setAssessmentStudentNote(prev => {
                                return {
                                    ...prev,
                                    description: e.target.value
                                }
                            })
                        }
                    }
                />
            </Stack>
        </Box>
    );
}