import Employee from "class/person/employee/Employee"
import FormSearch from "ui-component/FormSeach"

/**
 * @param {{
 *     employee:Employee,
 *     setEmployee:(employee:Employee) => void,
 *     loadList:(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<{rows: D[]}
 *     required?:boolean
 * }} param0 
 * @returns 
 */
export default function FormSeachEmployee({
    label,
    employee,
    setEmployee,
    loadList,
    required
}) {
    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/funcionario'}
            searchTable={'person'}
            searchColumn={'name'}
            label={"Funcionário / RH"/*label*/}
            item={employee}
            setItem={setEmployee}
            loadList={loadList}
            valueExtractor={
                /**
                 * @param {Employee} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {Employee} option 
                 * @returns 
                */
                (option) => ((option?.id ?? 0) <= 0) ? '' : `${option.id} - ${option.person?.name} (${option.person.user.email})`
            }
        ></FormSearch>
    )
}