import Customer from "class/customer/Customer"
import CustomerService from "service/verbify/user/customer/CustomerService"
import FormSearch from "ui-component/FormSeach"
import ModalFormCustomer from "./ModalFormCustomer";

/**
 * 
 * @param {{
 * customer:Customer,
 * setCustomer:(customer:Customer) => void,
 * label?:string|null,
 * disabled?:boolean,
 * minimumLength?:number|null
 * }} param0 
 * @returns 
 */
export default function FormSearchCustomer({
    label,
    customer,
    setCustomer,
    required,
    minimumLength = 3,
    disabled = false
}) {
    const customerService = new CustomerService();
    
    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/cliente'}
            searchTable={'person'}
            searchColumn={'name'}
            label={label ?? "Cliente"}
            item={customer}
            minimumLength={minimumLength ?? 3}
            setItem={setCustomer}
            loadList={( ...props ) => customerService.searchFor( ...props )}
            disabled={disabled ?? false}
            modal={<ModalFormCustomer />}
            valueExtractor={
                /**
                 * @param {Customer} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {Customer} option 
                 * @returns 
                */
                (option) => (option?.id ?? 0) > 0 ?  `${option?.id} - ${option?.person?.name} (${option?.person?.user?.email})` : ''
            }
        ></FormSearch>
    )
}