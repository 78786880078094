export default class Type {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    label = '';
    /**
     * @returns {string}
     */
    toString() {
        return this.id;
    }
    toJSON() {
        return this.id;
    }
}

let EnumType;
(function (EnumType) {
    EnumType[EnumType["Recorrente"] = 1] = "Recorrente";
    EnumType[EnumType["Evento"] = 2] = "Evento";
})(EnumType || (EnumType = {}));

export {
    EnumType
};