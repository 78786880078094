import React, { useState } from 'react';
import DataGridBase from 'ui-component/populated/base/DataGridBase';
import CustomerManagerService from 'service/verbify/user/customer/manager/CustomerManagerService';
import Customer from 'class/customer/Customer';
import ObjectResponseList from 'class/ObjectResponseList';
import { IconButton } from '@mui/material';
import { IconPencil } from '@tabler/icons';
import CustomerManager from 'class/customerManager/CustomerManager';


/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @returns {React.JSX.Element}
 */    
export default function DataGridCustomerManager( { stateList, stateCustomer, stateCustomerManager } ) {
    const customerManagerService = new CustomerManagerService();

    const state = useState(new Customer());
    const [customer] = stateCustomer ?? state

    const list = useState(new ObjectResponseList());
    const [customers, setCustomers] = stateList ?? list

    const manager = useState(new CustomerManager());
    const [ customerManager, setCustomerManager ] = stateCustomerManager ?? manager

    const columns = [
        { field: 'actions', headerName: 'Ações', width : 100,
            renderCell: ({row}) => <>
                <IconButton
                    onClick={(e) => {
                        setCustomerManager(row)
                    }}
                ><IconPencil></IconPencil></IconButton>
            </>
        },
        { field: 'id', headerName: 'Código', width : 100 },
        { field: 'type', headerName: 'Tipo', width : 100 },
        { field: 'customer', headerName: 'Cliente', valueGetter: ({row}) => row?.customer?.person?.name,  width : 250 },
        { field: 'manager', headerName: 'Gestor', valueGetter: ({row}) => row?.person?.name,  width : 250 },
    ];

    return (
        <DataGridBase 
            columns={columns}
            functionListItens={(page) => customerManagerService.list(page, customer)}
            stateItens={[customers, setCustomers]}
        />
    );
}