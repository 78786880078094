import ObjectResponseList from "class/ObjectResponseList";
import Customer from "class/customer/Customer";
import CustomerManager from "class/customerManager/CustomerManager";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class CustomerManagerService extends BaseService {
    static baseUrl = "cliente/{person}/gestor";
    constructor() {
        super(CustomerManagerService.baseUrl);
    }
        /**
     * @param {ObjectRequest} currentPage 
     * @param {Customer} customer 
     * @returns {Promise<AxiosResponse<ObjectResponseList<Customer>, any>>}
     */
        list (currentPage, customer) {
            const {
                column,
                order,
                page,
                perPage
            } = currentPage;
    
            return verbify.get(`cliente/${customer?.person?.id}/gestor?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
        }
        /**
         * @param {CustomerManager} customerManager 
         * @returns {Promise<AxiosResponse<void, any>>}
         */
        insert (customerManager) {
            return verbify.post(`cliente/${customerManager?.customer?.person?.id}/gestor`, customerManager);
        }
        /**
         * @param {CustomerManager} customerManager 
         * @returns {Promise<AxiosResponse<void, any>>}
         */
        update (customerManager) {
            return verbify.put(`cliente/${customerManager?.customer?.person?.id}/gestor/${customerManager.id}`, customerManager);
        }
}