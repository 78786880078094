import AbsenceType from "class/absenceType/AbsenceType";
import Task from "class/task/Task";
import AbsenceTypeService from "service/verbify/absenceType/AbsenceTypeService";
import TaskService from "service/verbify/task/TaskService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {AbsenceType} props.task
 * @param {(student:AbsenceType) => void} props.setAbsenceType
 * @returns 
 */
export default function FormSearchAbsenceType({
    absenceType,
    setAbsenceType,
    loadList,
    label,
    required
}) {
    const absenceTypeService = new AbsenceTypeService();
    return <FormSearch
        required={required}
        searchPatch={'/buscar/tipo-de-ausencia'}
        searchTable={'absenceType'}
        searchColumn={'title'}
        label="Buscar ausencia"
        item={absenceType}
        setItem={setAbsenceType}
        minimumLength={1}
        loadList={loadList ? (...props) => loadList(...props) : (...props) => absenceTypeService.searchFor(...props)}
        valueExtractor={
            /**
             * @param {AbsenceType} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {AbsenceType} option 
             * @returns 
            */
            (option) => !option || option.id <= 0 || option?.id == undefined ? '' : `${option?.id} - ${option?.title}(${option?.allowsReplacement ? "Permite reposição" : "Não reposição"})`
        }
    ></FormSearch>
}