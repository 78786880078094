import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import ObjectResponseList from "class/ObjectResponseList";
import FormPersonLanguage from "./FormPersonLanguage";
import PersonLanguage from "class/person/language/PersonLanguage";

/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {PersonLanguage} param0.data
 * @param {[ObjectResponseList<PersonLanguage>, React.Dispatch<ObjectResponseList<PersonLanguage>>]} param0.stateList 
 * @param {[PersonLanguage, React.Dispatch<PersonLanguage>]} param0.stateItem 
 * @returns {React.JSX.Element}
 */
export default function ModalFormPersonLanguage({ 
    data,
    callback,
    updateList,
    stateList,
    stateItem,
    label,
    variant
}) {

    const item = useState(data ?? new PersonLanguage());
    const [ personLanguage, setPersonLanguage ] = stateItem ?? item;

    const list = useState(new ObjectResponseList());

    const [ personLanguages, setPersonLanguages ] = stateList ?? list;

    function setItem (personLanguageData) {
        setPersonLanguages(prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == personLanguageData.id)) {
                const index = newListItens.findIndex(f => f.id == personLanguageData.id);

                newListItens[index] = personLanguageData;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        personLanguageData,
                        ...newListItens
                    ]
                }
            }
        });
    }


    return (
        <ModalForm
            title={"Gerenciar Tarefa"}
            variant={variant}
            description={"Informe os dados da tarefa nos campos abaixo:"}
            form={<FormPersonLanguage data={data} updateList={updateList ?? setItem} callback={callback}></FormPersonLanguage>}
            buttonLabel={label}
            width={'md'}
        >
        </ModalForm>
    );
}