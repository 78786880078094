import ObjectRequest from "class/ObjectRequest";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import Assessment from "class/secretary/frequency/assessment/Assessment";
import AssessmentStudentNote from "class/secretary/frequency/assessment/AssessmentStudentNote";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class AssessmentStudentNoteService extends BaseService {
    static baseUrl = "folha-de-frequencia/{idFF}/avaliacao";
    constructor() {
        super(AssessmentStudentNoteService.baseUrl);
    }
    /**
     * 
     * @param {ObjectRequest} param0
     * @param {FrequencySheet} frequencySheet
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    list({
        column,
        order,
        page,
        perPage
    }, frequencySheet) {
        return verbify.get(`folha-de-frequencia/${frequencySheet.id}/avaliacao?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {AssessmentStudentNote} assessmentStudentNote 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    search(assessmentStudentNote) {
        return verbify.get(`folha-de-frequencia/${assessmentStudentNote?.frequencySheet?.id}/avaliacao-aluno/${assessmentStudentNote?.classeModuleStudent?.id}`);
    }
    /**
     * @param {AssessmentStudentNote} assessmentStudentNote 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert(assessmentStudentNote) {
        return verbify.post(`/folha-de-frequencia/${assessmentStudentNote.frequencySheet.id}/avaliacao-aluno`, assessmentStudentNote);
    }
    /**
     * @param {AssessmentStudentNote} assessmentStudentNote 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update(assessmentStudentNote) {
        return verbify.put(`/folha-de-frequencia/${assessmentStudentNote.frequencySheet.id}/avaliacao-aluno/${assessmentStudentNote.classeModuleStudent.id}`, assessmentStudentNote);
    }
}