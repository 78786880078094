import ObjectResponseList from "class/ObjectResponseList";
import ClasseModule from "class/secretary/classe/ClasseModule";
import { useState } from "react";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import { IconCalendar, IconEdit, IconListDetails, IconMoneybag, IconSchool, IconUsers } from "@tabler/icons";
import { Box, Button, Stack } from "@mui/material";
import DialogFormItem from "ui-component/populated/schedule/item/DialogFormItem";
import DialogFormStudentModule from "ui-component/populated/user/student/module/DialogFormStudentModule";
import DialogFormTeacherModule from "ui-component/populated/user/teacher/module/DialogFormTeacherModule";
import ModalFormProposal from "ui-component/populated/proposal/ModalFormProposal";
import ModalFormFrequencySheet from "../../frequency/ModalFormFrequencySheets";
import ModalFormClasseModuleFull from "./ModalFormClasseModuleFull";
import FormClasseModuleInOperation from "./FormClasseModuleStatus";
/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} props 
 * @param {[ClasseModule, React.Dispatch<ClasseModule>]} props.stateClasseModule 
 * @param {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]} props.stateList  
 * @returns {React.JSX.Element}
 */
export default function DataGridModuleClasse({
    stateClasseModule,
    stateList,
}) {
    const classeModuleService = new ClasseModuleService();
    const [classeModule, setClasseModule] = stateClasseModule;

    /**
     * @type {[ObjectResponseList<ClasseModule>, React.Dispatch<ObjectResponseList<ClasseModule>>]}
     */
    const [classesModules, setClassesModules] = useState(stateList ? null : {
        rows: [],
        totalRows: 0
    });

    const stateModulesClasses = stateList ?? [classesModules, setClassesModules];

    function setItem(classeModule) {
        stateModulesClasses[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == classeModule.id)) {
                const index = newListItens.findIndex(f => f.id == classeModule.id);

                newListItens[index] = classeModule;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        classeModule,
                        ...newListItens
                    ]
                }
            }
        });
    }

    return <DataGridBase
        stateItens={stateModulesClasses}
        columns={[
            {
                field: 'option',
                renderCell: (item) => <Stack flexWrap={'wrap'} display={'flex'} direction={'row'}>
                    <ModalFormProposal label={<IconMoneybag></IconMoneybag>} proposalData={item.row.proposal}></ModalFormProposal>
                    <ModalFormClasseModuleFull classeModuleData={item.row} label={<IconMoneybag></IconMoneybag>}></ModalFormClasseModuleFull>
                    <DialogFormItem
                        schedule={item.row.schedule}
                        buttonOpenModal={<Button variant={'text'} autoFocus><IconCalendar size={20}></IconCalendar></Button>}
                        title={"Gerenciar agenda do módulo - " + item.row.schedule.title}
                    ></DialogFormItem>
                    <DialogFormStudentModule
                        classeModule={item.row}
                        buttonOpenModal={<Button variant={'text'} autoFocus><IconSchool size={20}></IconSchool></Button>}
                        title={"Gerenciar alunos do módulo - " + item.row.module.title}
                    ></DialogFormStudentModule>
                    <DialogFormTeacherModule
                        classeModule={item.row}
                        buttonOpenModal={<Button variant={'text'} autoFocus><IconUsers size={20}></IconUsers></Button>}
                        title={"Gerenciar professores do módulo - " + item.row.module.title}
                    />
                    <Button
                        variant={'text'}
                        autoFocus
                        onClick={(e) => {
                            console.log("Classe: ", item.row);
                            setClasseModule(item.row);
                        }}
                    >
                        <IconEdit size={20}></IconEdit>
                    </Button>
                    <ModalFormFrequencySheet
                        classeModuleData={item.row}
                        label={<IconListDetails></IconListDetails>}
                    ></ModalFormFrequencySheet>
                </Stack>,
                headerName: 'Opções',
                width: 250
            },
            { field: 'module', valueGetter: (data) => `${data.row.module?.language?.title} - ${data.row.module?.title}`, headerName: 'Nível', width: 250 },
            { field: 'duration', valueGetter: (data) => data.row.duration, headerName: 'Duração', width: 150 },
            {
                field: 'inOperation', renderCell: (data) => <Box>
                    <FormClasseModuleInOperation updateList={setItem} classeModuleData={data.row}></FormClasseModuleInOperation>
                </Box>, headerName: 'Modulo Atual', width: 150
            },
        ]}
        functionListItens={(page) => classeModuleService.list(page, classeModule)}
    />
}