import ObjectResponseList from 'class/ObjectResponseList';
import ObjectRequest from 'class/ObjectRequest';
import { AxiosResponse } from 'axios';
import verbify from './verbify/config'
import ObjectResponse from 'class/ObjectResponse';

/**
 * @template T
 * @class BaseService
 * @description Class generica para requisitar a api da verbify
 */
class BaseService {
    /**
     * @param {string} baseUrl 
     */
    constructor (baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * @param {number} id 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    search(id) {
        return verbify.get(`${this.baseUrl}/${id}`);
    }
    /**
     * @param {string} searchPatch 
     * @param {string} search // word or phrase
     * @param {string} searchTable 
     * @param {string} searchColumn 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    searchFor(searchPatch, search, searchTable, searchColumn) {
        return verbify.get(`${searchPatch}/${search}/${searchTable}/${searchColumn}`)
    }
    /**
     * 
     * @param {ObjectRequest} param0 
     * @param {[]} filters
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    list ({
        column,
        order,
        page,
        perPage,
        filters,
        quickFilter, 
        filtersColumns
    }) {
        console.log("SUPERFILTER", filters);
        let listFilters = [];
        if (filters?.length > 0) {
            listFilters = filters.map(item => {
                return {
                    column : item?.column?.column,
                    operator : item?.operator?.operator,
                    value : item?.value
                }
            });
            console.log("SUPERFILTER", listFilters);
            listFilters = JSON.stringify(listFilters);
            console.log("SUPERFILTER", listFilters);
            listFilters = encodeURIComponent(listFilters)
            console.log("SUPERFILTER", listFilters);
        }

        const quickFiltersColumns = filtersColumns ? encodeURIComponent(JSON.stringify(filtersColumns.filter((c) => c.field != "actions").map((c) => c.field))) : [];

        return verbify.get(`${this.baseUrl}?column=${column}&order=${order}&page=${page}&perPage=${perPage}&filters=${listFilters}&qf=${quickFilter}&qfColumns=${quickFiltersColumns}`);
    }
    /**
     * @param {T} data 
     * @returns {Promise<AxiosResponse<ObjectResponse<T>, any>>}
     */
    insert (data) {
        return verbify.post(this.baseUrl, data);
    }
    /**
     * @param {T} data 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update(data) {
        return verbify.put(`${this.baseUrl}/${data.id}`, data);
    }
    
    delete(id) {
        return verbify.delete(`${this.baseUrl}/${id}`);
    }
}

export default BaseService;