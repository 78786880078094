import React, { useState } from 'react';
import { IconEdit } from '@tabler/icons';
import DataGridBase from 'ui-component/populated/base/DataGridBase';
import ObjectResponseList from 'class/ObjectResponseList';
import ModalFormPersonLanguage from './ModalFormPersonLanguage';
import PersonLanguage from 'class/person/language/PersonLanguage';
import PersonLanguageService from 'service/verbify/user/person/language/PersonLanguageService';

/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} param0 
 * @param {[ObjectResponseList<PersonLanguage[]>, React.Dispatch<ObjectResponseList<PersonLanguage[]>>]} param0.stateList 
 * @param {PersonLanguage} param0.personLanguage 
 * @returns {React.JSX.Element}
 */    
export default function DataGridPersonLanguage({
    stateList,
    personLanguage
}) {
    const personLanguageService = new PersonLanguageService(personLanguage);
    
    const [ PersonLanguages, setPersonLanguages ] = useState(new ObjectResponseList());

    const stateListPersonLanguage = stateList ?? [ PersonLanguages, setPersonLanguages ];
        
    return (
        <DataGridBase
            columns={[
                { field: 'actions', headerName : 'Ações', renderCell: (params) => {
                    return <>
                        <ModalFormPersonLanguage taskData={params.row} stateList={stateListPersonLanguage} label={<IconEdit />}>
                        </ModalFormPersonLanguage>
                    </>
                }},
                { field: 'user', valueGetter : (data) => data.row.person.name,  headerName: 'Usuário', width : 150 },
                { field: 'language', valueGetter : (data) => data.row.language.title,  headerName: 'Usuário', width : 150 },
            ]}
            functionListItens={(page) => personLanguageService.list(page)}
            stateItens={stateListPersonLanguage}
        />
    );
}