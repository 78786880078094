import Person from "../Person";

export default class Teacher {
    /**
     * @public
     * @type {null|number}
     */
    id = null;
    /**
     * @public
     * @type {string}
     */
    academicTitle = '';
    /**
     * @public
     * @type {string}
     */
    academicDescription = '';
    /**
     * @public
     * @type {Person}
     */
    person = new Person();
}