import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ProfileRoutes from './ProfilesRoutes';
import OnboardingRoutes from './OnboardingRouter';
import CustomerRoutes from './SchoolRoutes';
import SecreatryRoutes from './SecretaryRoutes';
import OutherRoutes from './OutherRoutes';
import ConfigRoutes from './ConfigRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    OutherRoutes,
    ConfigRoutes,
    ProfileRoutes,
    CustomerRoutes,
    MainRoutes,
    AuthenticationRoutes,
    OnboardingRoutes,
    SecreatryRoutes
  ]);
}
