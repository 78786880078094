import Task from 'class/task/Task';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<Task>
 */
export default class TaskService extends BaseService {
    static urlBase = "tarefa";
    constructor() {
        super(TaskService.urlBase);
    }
}