import { useEffect, useState } from "react";
import ObjectResponseList from "class/ObjectResponseList";
import Proposal from "class/proposal/Proposal";
import ClasseModule from "class/secretary/classe/ClasseModule";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";
import { IconSelector } from "@tabler/icons";
import FormItem from "ui-component/populated/schedule/item/FormItem";
import FormStudentModule from "ui-component/populated/user/student/module/FormStudentModule";
import FormTeacherModule from "ui-component/populated/user/teacher/module/FormTeacherModule";
import FormClasseModule from "./FormClasseModule";
import DataGridItem from "ui-component/populated/schedule/item/DataGridItem";
import Item from "class/schedule/Item";
import DataGridStudentModule from "ui-component/populated/user/student/module/DataGridStudentModule";
import StudentModule from "class/person/student/StudentModule";
import TeacherModule from "class/person/teacher/TeacherModule";
import DataGridTeacherModule from "ui-component/populated/user/teacher/module/DataGridTeacherModule";
import DataGridReportFrequencySheet from "ui-component/populated/user/student/module/student/report/DataGridReportFrequencySheet";
import DataGridListClasseModuleStudentForClasse from "../report/DataGridListClasseModuleStudentForClasse";
import DataGridListFrequencySheet from "../../frequency/DataGridLIstFrequencySheet";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {ClasseModule} param0.classeModuleData
 * @param {Proposal} param0.proposalData
 * @param {(classeModule: ClasseModule) => ObjectResponseList<ClasseModule>} param0.updateList
 * @returns {React.JSX.Element}
 */
export default function FormClasseModuleFull({
    label,
    stateOpen,
    proposalData,
    classeModuleData,
    classeId,
    classeModuleId,
    updateList
}) {

    const openState = useState(false);
    const [open, setOpen] = stateOpen ?? openState;

    const [expanded, setExpanded] = useState("panel1");
    const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

    const styleAccordion = {
        border: "2px solid #2196f3",
        marginBottom: 1,
        borderRadius: "12px !important"
    };

    const [classeModule, setClasseModule] = useState(classeModuleData ?? new ClasseModule());

    const stateItem = useState(new Item());
    const stateItemList = useState(new ObjectResponseList());

    const stateStudentModule = useState(new StudentModule());
    const stateStudentModuleList = useState(new ObjectResponseList());

    const stateTeacherModule = useState(new TeacherModule());
    const stateTeacherModuleList = useState(new ObjectResponseList());

    const stateFrequencySheetModuleList = useState(new ObjectResponseList());

    const classeModuleService = new ClasseModuleService();

    const searchClasseModule = async (classeId, classeModuleId) => { 
        try {
            setClasseModule((await classeModuleService.search(classeId, classeModuleId)).data);

        } catch (error) {
            console.log(error);
        }
        
    };
    useEffect(() => {
        if (classeId > 0 && classeModuleId > 0) searchClasseModule(classeId, classeModuleId);
        else if (classeModule?.id > 0) setClasseModule(classeModuleData);
    }, [])

    useEffect(() => {
        if (classeModule?.id > 0) {
            console.log(classeModule, "OFOAHDOPEODKLAÇOCA");
            stateItem[1](prev => { return { ...prev, schedule: classeModule.schedule } })
            stateStudentModule[1](prev => { return { ...prev, classeModule: classeModule } })
            stateTeacherModule[1](prev => { return { ...prev, classeModule: classeModule } })
        }
    }, [classeModule?.id])

    return <Box p={2}>
        <Accordion
            expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
            defaultExpanded
            sx={styleAccordion}
        >
            <AccordionSummary
                expandIcon={<IconSelector color="#2196f3"></IconSelector>}
            >
                <Typography variant="h3">Modulo</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormClasseModule stateClasseModule={[classeModule, setClasseModule]} classeModuleData={classeModule}></FormClasseModule>
            </AccordionDetails>
        </Accordion>
        <Accordion
            expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
            sx={styleAccordion}
        >
            <AccordionSummary
                expandIcon={<IconSelector color="#2196f3"></IconSelector>}
            >
                <Typography variant="h3">Agenda - Planejamento de Aulas</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormItem
                    id={stateItem[0].id}
                    schedule={stateItem[0].schedule}
                    itemsList={stateItemList[0].rows}
                ></FormItem>
                <Divider sx={{ mt: 1 }} ></Divider>
                {
                    stateItem[0].schedule.id > 0 && <DataGridItem
                        stateItem={stateItem}
                        stateList={stateItemList}
                        height={400}
                    ></DataGridItem>
                }

            </AccordionDetails>
        </Accordion>
        <Accordion
            expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
            sx={styleAccordion}
        >
            <AccordionSummary
                expandIcon={<IconSelector color="#2196f3"></IconSelector>}
            >
                <Typography variant="h3">Alunos</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormStudentModule
                    stateStudentModule={stateStudentModule}
                    classeModule={classeModule}
                ></FormStudentModule>
                <Divider sx={{ mt: 1 }} ></Divider>
                {
                    (stateStudentModule[0].classeModule?.id > 0 && classeModule?.id > 0) && <DataGridStudentModule
                        classeModule={classeModule}
                        stateList={stateStudentModuleList}
                        stateStudentModule={stateStudentModule}
                        height={400}
                    ></DataGridStudentModule>
                }
            </AccordionDetails>
        </Accordion>
        <Accordion
            expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
            sx={styleAccordion}
        >
            <AccordionSummary
                expandIcon={<IconSelector color="#2196f3"></IconSelector>}
            >
                <Typography variant="h3">Professor</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormTeacherModule
                    stateTeacherModule={stateTeacherModule}
                    classeModule={classeModule}
                ></FormTeacherModule>
                <Divider sx={{ mt: 1 }} ></Divider>
                {
                    (stateStudentModule[0].classeModule?.id > 0 && classeModule?.id > 0) && <DataGridTeacherModule
                        height={400}
                        stateList={stateTeacherModuleList}
                        classeModule={classeModule}
                        stateTeacherModule={stateTeacherModule}
                    ></DataGridTeacherModule>
                }
            </AccordionDetails>
        </Accordion>
        <Accordion
            expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
            sx={styleAccordion}
        >
            <AccordionSummary
                expandIcon={<IconSelector color="#2196f3"></IconSelector>}
            >
                <Typography variant="h3">Relatorio de Frequencia</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="h4">Resultado do Aluno</Typography>
                <Typography variant="h4">Folhas de Frequencia</Typography>
                { 
                    classeModule?.id > 0 && <DataGridListFrequencySheet
                        height={400}
                        stateClasseModule={[classeModule, setClasseModule]}
                        stateList={stateFrequencySheetModuleList}
                    ></DataGridListFrequencySheet>
                }
            </AccordionDetails>
        </Accordion>
    </Box>
}
