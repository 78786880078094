import { Alert } from "@mui/material";
import axios from "axios";
import Cookies from 'js-cookie';
import { createRoot } from "react-dom/client";

axios.defaults.withCredentials = true;

const verbifyFile = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

verbifyFile.interceptors.request.use((request) => {
    request.maxBodyLength = Infinity;
    request.headers.setContentType('multipart/form-data');
    request.headers.setAuthorization('Bearer ' + Cookies.get('token'));
    return request;
})


verbifyFile.interceptors.response.use((response) => {
    const method = ['put', 'post', 'delete'];
    if (method.includes(response.config.method)) {
        
        const containerElement = document.getElementById('alerts');
        const notificationElement = document.createElement("div");
        containerElement.appendChild(notificationElement);

        const root = createRoot(notificationElement);
        root.render(
            <Alert  severity="success" sx={{ width: 250 }}>
                Sucesso
            </Alert>
        );

        setTimeout(() => {
            root.unmount();
            containerElement.removeChild(notificationElement);
        }, 5000);
    }
    return response;
});

export default verbifyFile;