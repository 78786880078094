import Type from "class/schedule/item/Type";
import TypeService from "service/verbify/schedule/item/TypeService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props 
 * @param {Type} props.type
 * @param {(type:Type) => void} props.setType
 * @param {string} props.label
 * @param {boolean|undefined} props.required
 * @returns {React.JSX.ReactElement}
 */
export default function FormSeachType({
    type,
    setType,
    label,
    required
}) {
    const typeService = new TypeService();
    return <FormSearch 
        required={required}
        loadList={(...props) => typeService.searchFor(props[3], props[1])}
        searchPatch={'/buscar/modulo'}
        searchTable={'module'}
        searchColumn={'label'}
        label={label ?? "Tipo"}
        item={type}
        minimumLength={0}
        setItem={setType}
        valueExtractor={
            /**
             * @param {Module} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Module} option 
             * @returns 
            */
            (option) => !option || option?.id == 0 ? '' : `${option.label}`
        }
    />
}