import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import FormClasse from "./FormClasse";
import TabsComponent from "ui-component/tabsComponent";
import ObjectResponseList from "class/ObjectResponseList";
import Classe from "class/secretary/Classe";
import FormClasseClasseModule from "./module/FormClasseModuleClasseList";
import Proposal from "class/proposal/Proposal";
import ReportClasse from "./report/ReportClasse";
/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {number|null|undefined} param0.id
 * @param {[ObjectResponseList<Classe>, React.Dispatch<ObjectResponseList<Classe>>]} param0.stateList 
 * @param {Proposal} param0.proposal 
 * @returns {React.JSX.Element}
 */
export default function ModalFormClasse({ 
    stateList, 
    label,
    proposal,
    stateOpen,
    id 
}) {
    
    const openState = useState(false);
    const [ open, setOpen ] = stateOpen ?? openState;

    const [ idState, setIdState ] = useState(id);
    /**
     * @type {[ObjectResponseList<Classe>, React.Dispatch<ObjectResponseList<Classe>>]}
     */
    const [ newListClasse, setNewClasses ] = useState(stateList ? null : {
        rows : [],
        totalRows : 0
    });

    const [ classes, setClasses ] = stateList ?? [ newListClasse, setNewClasses ];
    const currentItem = classes.rows.find(f => f.id == idState);

    function setItem(classe) {
        setClasses(prev => {
            const newListItens = [ ...prev.rows ];

            if (newListItens.some(any => any.id == classe.id)) { 
                const index = newListItens.findIndex(f => f.id == classe.id);
                
                newListItens[index] = classe;

                return {
                    ...prev,
                    rows : [ ...newListItens ]
                }
            }
            else {
                setIdState(classe.id);
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        classe,
                        ...newListItens
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            setOpen={setOpen}
            open={open}
            title={"Gerenciar turma"}
            description={"Informe os dados da turma nos campos abaixo:"}
            form={
                    <TabsComponent listTabs={[
                        {
                            label : 'Turma',
                            component : <FormClasse id={idState} item={currentItem} setItem={setItem} proposal={proposal}></FormClasse>
                        },
                        {
                            label : 'Níveis',
                            disabled : isNaN(currentItem?.id) || currentItem?.id <= 0,
                            component : isNaN(currentItem?.id) || currentItem?.id <= 0 ? <></> : <FormClasseClasseModule proposal={proposal} classe={currentItem} />
                        },
                        {
                            label: "Resumo",
                            disabled : isNaN(currentItem?.id) || currentItem?.id <= 0,
                            component : isNaN(currentItem?.id) || currentItem?.id <= 0 ? <></> : <ReportClasse classeData={currentItem} />
                        }
                    ]}></TabsComponent>
            }
            buttonLabel={label}
            width={'lg'}
        >
        </ModalForm>
    );
}