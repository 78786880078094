import Student from "class/person/student/Student";
import StudentService from "service/verbify/user/StudentService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {Student} props.student
 * @param {(student:Student) => void} props.setStudent
 * @returns 
 */
export default function FormSearchStudent({
    student,
    setStudent,
    required,
    disabled
}) {
    const studentService = new StudentService();
    return <FormSearch
        required={required}
        searchPatch={'/buscar/aluno'}
        searchTable={'person'}
        searchColumn={'name'}
        label="Estudante"
        item={student}
        disabled={disabled ?? false}
        setItem={setStudent}
        loadList={studentService.searchFor}
        valueExtractor={
            /**
             * @param {Student} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Student} option 
             * @returns 
            */
            (option) => !option || option.id <= 0 ? '' : `${option.id} - ${option.person.name}`
        }
    ></FormSearch>
}