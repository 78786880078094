import { useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import ObjectRequest from "class/ObjectRequest";
import React from 'react';
import { useEffect, useState, useRef } from "react";

/**
 * Retorana uma tabela populada com os dados listados por functionListItens
 * @template T
 * @param {Object} param0 
 * @param {(page:ObjectRequest) => Promise<AxiosResponse<ObjectResponseList<T>, any>>} param0.functionListItens 
 * @param {[ObjectResponseList<T>, React.Dispatch<ObjectResponseList<T>>]} param0.stateItens
 * @param {import('@mui/x-data-grid').GridColDef<T,any,any>[]} param0.columns
 * @param {(row: T) => import('@mui/x-data-grid').GridRowId|undefined} param0.keyExtractor
 * @param {boolean?} param0.checkboxSelection
 * @param {(itemsSelected:import('@mui/x-data-grid').GridRowId|undefined, listAll:T) => T[]} param0.onRowSelectionModelChange
 * @param {(row:T) => T|undefined} param0.mapRow
 * @param {boolean|undefined} param0.loadingProps
 * @param {[ObjectRequest, React.Dispatch<React.SetStateAction<ObjectRequest>>]} param0.pageState
 * @param {[]} param0.stateFilter
 * @returns {React.JSX.Element}
 */
export default function DataGridBase({
    functionListItens,
    stateItens,
    columns,
    keyExtractor,
    checkboxSelection,
    onRowSelectionModelChange,
    onChangeRow,
    getStatePage,
    mapRow,
    disableRowSelectionOnClick,
    onCellClick,
    loadingProps,
    pageState : statePageParam,
    filters,
    height,
    disableQuickFilter = false
}) {

    const [ itens, setItens ] = stateItens;
    const statePage = useState(new ObjectRequest());
    /**
     * @type {[ObjectRequest, React.Dispatch<ObjectRequest>]} state
     */
    const [ page, setPage ] = statePageParam ?? statePage;
    const pageRef = useRef([ page, setPage ]);

    const [ loading, setLoading ] = useState(false);

    const [ queryFilter, setQueryFilter ] = useState([])
    if (getStatePage)
        getStatePage(pageRef);

    const [quickFilter, setQuickFilter] = useState("")
    
    useEffect(() => {
        if (page && functionListItens) {
            setLoading(true);
            functionListItens({
                ...page,
                filters: filters,
                quickFilter: quickFilter,
                filtersColumns: columns
            })
                .then((response) => {
                    setItens(prev => {
                        let linhas = response?.data?.rows;
                        if(linhas === 0) return new ObjectRequest()
                        if (mapRow && linhas)
                            linhas = response?.data?.rows.map(m => mapRow(m));
                        return {
                            rows : linhas ?? [],
                            totalRows : response.data.totalRows
                        }; 
                    });
                }).catch(ex => {
                    //Manipular os erros
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [ page, filters, quickFilter ]);
    

    const onFilterChange = React.useCallback((filterModel) => {
        setQuickFilter(filterModel.quickFilterValues[0])
    }, [])
    
    //const mediaQuery = useMediaQuery('(max-width:900px)');
    const theme = useTheme();
    return (
        <div style={{
            height: height ?? 550,
            backgroundColor: theme.palette.grey[50]
        }}>
            <DataGrid
                //getEstimatedRowHeight={() => 120}
                //getRowHeight={!mediaQuery ? () => 'auto': null}
                onFilterModelChange={onFilterChange}
                slots={{ toolbar: disableQuickFilter ? null : GridToolbarQuickFilter }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: !disableQuickFilter,
                  },
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowId={keyExtractor}
                rows={itens?.rows ?? []}
                filterModel={{
                    items : []
                }}
                rowCount={itens?.totalRows ?? 0}
                paginationMode='server'
                filterMode='server'
                sortingMode='server'
                pageSizeOptions={[5,15,30,50,100]}
                columns={columns}
                paginationModel={{
                    page : (page?.page ?? 1)-1,
                    pageSize: page?.perPage ?? ObjectRequest.perPageDefault
                }}
                checkboxSelection={checkboxSelection ?? false}
                onRowSelectionModelChange={function (e) {
                    console.log(e);
                    const list = onRowSelectionModelChange ? (e) => onRowSelectionModelChange(e, itens?.rows) : (e) => e.map(m => itens?.rows?.find(f => keyExtractor(f) == m));
                    if (onChangeRow)
                        onChangeRow(list(e));
                }}
                sx={{
                    '& .MuiDataGrid-row' : {
                        minHeight : '45px !important',
                        maxHeight : 'none !important',
                        height : 'auto !important'
                    },
                    '& .MuiDataGrid-cell' : {
                        minHeight : '45px !important',
                        maxHeight : 'none !important',
                        height : 'auto !important'
                    }
                }}
                loading={loadingProps ?? loading}
                disableRowSelectionOnClick={disableRowSelectionOnClick ?? true}
                onCellClick={onCellClick ?? ((row, e) => e.stopPropagation())}
                disableColumnFilter={true}
                disableColumnMenu={true}
                onPaginationModelChange={(models, details) => {   
                    if (models.pageSize != page.perPage)
                    {
                        let newConfigPage = new ObjectRequest();
                        newConfigPage = { ...page };
                        newConfigPage.page = 1;
                        newConfigPage.perPage = models.pageSize;
                        setPage(newConfigPage);
                    }
                    else {
                        //Garante que a mesma pagina não será consultada 2 vezes
                        setPage(prev => {
                            return {
                                ...prev,
                                page : models.page + 1
                            }
                        });
                    }
                }}
            />
        </div>
    );
}