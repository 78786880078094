import { Box, Button, Card, CardContent, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import ObjectRequest from "class/ObjectRequest";
import CustomForm from "class/customForm/CustomForm";
import CustomFormQuestion from "class/customForm/question/CustomFormQuestion";
import CustomFormResponse from "class/customForm/response/CustomFormResponse";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CustomFormService from "service/verbify/customForm/CustomFormService";
import MainCard from "ui-component/cards/MainCard";
import CustomFormResponseService from "service/verbify/customForm/response/CustomFormResponseService";
import { useContext } from "react";
import { GlobalContext } from "GlobalContext";
import FormCustomFormResponse from "../../../ui-component/populated/customForm/response/FormCustomFormResponse";
import ListQuestions from "../../../ui-component/populated/customForm/response/ListQuetions";

/**
 * @param {Object} param0
 * @param {number} param0.idCustomForm
 * @param {number} param0.idCustomFormResponse
 * @param {(customFormResponse: CustomFormResponse) => void} param0.callbackLoadingFormResponse
 * @param {(customFormResponse: CustomFormResponse) => void} param0.callbackSaveFormResponse
 */

export default function CustomFormResponseView({ idCustomForm, idCustomFormResponse, callbackLoadingFormResponse, callbackSaveFormResponse}) {
    const page = new ObjectRequest()
    page.column = 'id'
    page.order = 'ASC'
    page.page = 1
    page.perPage = 10000

    const params = useParams();

    const customFormId = idCustomForm ?? params.customForm;
    const customFormResponseId = idCustomFormResponse ?? params.customFormResponse;

    const customFormService = new CustomFormService();
    const customFormResponseService = new CustomFormResponseService()

    const [customForm, setCustomForm] = useState(new CustomForm());
    const [customFormResponse, setCustomFormResponse] = useState(new CustomFormResponse());
    const { person, setPerson } = useContext(GlobalContext).person

    useEffect(() => {
        if (customFormId && customFormId > 0) customFormService.search(customFormId)
            .then(response => {
                if (response.data.id > 0) {
                    setCustomForm(response?.data)
                    setCustomFormResponse(prev => {
                        const data = {
                            ...prev,
                            id: customFormResponseId,
                            customForm: response?.data,
                            person: person
                        }
                        if(callbackLoadingFormResponse) callbackLoadingFormResponse(data)
                        return data
                    })
                }
            })
    }, [])

    return (
        <MainCard
            sx={{
                width: { xs: "100%", height: "100%" },
                margin: "0px auto"
            }}
        >
            <ListQuestions
                stateCustomFormResponse={[customFormResponse, setCustomFormResponse]}
            >
            </ListQuestions>
            <FormCustomFormResponse
                statePerson={[person, setPerson]}
                stateCustomForm={[customForm, setCustomForm]}
                stateCustomFormResponse={[customFormResponse, setCustomFormResponse]}
                callbackLoading={callbackLoadingFormResponse}
                callbackSave={callbackSaveFormResponse}
            >
            </FormCustomFormResponse>

        </MainCard>
    )
}
