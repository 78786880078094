// assets
import { IconPigMoney, IconFileDollar, IconFileInvoice, IconReceipt2, IconShoppingCart, IconPlayerPlay, IconWallet } from "@tabler/icons";

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const financial = {
    id: 'financial',
    title: 'Financeiro',
    // title: 'Financeiro - Em manutenção',
    type: 'collapse',
    icon: IconFileDollar,
    url: "/",
    children: [
        {
            id: 'payments',
            title: 'Nota Fiscal',
            type: 'item',
            icon: IconFileInvoice,
            url: '/escola/financeiro/pagamentos',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'billsToPay',
            title: 'Gerar mensalidades',
            type: 'item',
            icon: IconPigMoney,
            url: '/escola/financeiro/gerar/mensalidade',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'generatePayroll',
            title: 'Gerar folha pagamento',
            type: 'item',
            icon: IconReceipt2,
            url: '/escola/financeiro/gerar/folha-pagamento',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'payroll',
            title: 'Folha de pagamento',
            type: 'item',
            icon: IconWallet,
            url: '/escola/financeiro/folha-pagamento',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'schoolProducts',
            title: 'Produtos',
            type: 'item',
            url: '/escola/comercial/produtos',
            icon: IconShoppingCart,
            target: false,
            breadcrumbs: false
        },
    ]
};

export default financial;
