import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import FormUser from "./FormUser";
import { Avatar, Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import TabsComponent from "ui-component/tabsComponent";
import Person from "class/person/Person";
import User from "class/person/user/User";
import FormPerson from "./person/FormPerson";

/**
 * 
 * @param {{label:string, person?:Person, user?:User}} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormUser({ stateList, label, person, user, stateOpen }) {
    const openState = useState(false);
    const [open, setOpen] = stateOpen ?? openState;

    const [persons, setPersons] = useState(stateList ? null : {
        rows: [],
        totalRows: 0
    });

    const statePersons = stateList ?? [persons, setPersons];

    function setItem(person) {
        statePersons[1](prev => {
            const newListItens = [...prev.rows];
            if (newListItens.some(any => any.id == person.id)) {

                const index = newListItens.findIndex(f => f.id == person.id);

                newListItens[index] = person;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {

                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        ...newListItens,
                        person
                    ]
                }
            }
        });
    }
    const theme = useTheme()
    return (
        <ModalForm
            title={"Adicionar usuario"}
            description={"Informe os dados da usuario nos campos abaixo:"}
            form={
                <>
                    <FormUser userData={person?.user} setItem={setItem}></FormUser>
                    <Divider textAlign="left" sx={{ margin: "35px 0px 20px 0px" }}>
                    </Divider>
                    <Box padding={2} bgcolor={"primary.light"} borderRadius={theme.shape.borderRadius}>
                        <FormPerson personData={person}></FormPerson>
                    </Box>

                </>

            }
            buttonLabel={label}
            width={'md'}
            open={open}
            setOpen={setOpen}
        >
        </ModalForm>
    );
}