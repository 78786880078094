import MainCard from "ui-component/cards/MainCard";
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import React from "react";
import FormStudent from "./Form/FormStudent";
import ProfileContextComponent from "./Context/ProfileContext";


const ProfileStudent = () => {
    return (
        <ProfileContextComponent>
            <MainCard title="Dados do Aluno" sx={{ width: { xs: "100%", sm: "80%", md: "60%" }, margin: "0px auto" }} secondary={<SecondaryAction link="https://next.material-ui.com/system/typography/" />}>
                <FormStudent></FormStudent>
            </MainCard>
        </ProfileContextComponent>
    );
}
export default ProfileStudent;