import Proposal from "class/proposal/Proposal"
import FormSearch from "ui-component/FormSeach"

/**
 * 
 * @param {{
 *     proposal:Proposal,
 *     setProposal:(proposal:Proposal) => void 
 * }} param0 
 * @returns 
 */
export default function FormSearchProposal({
    proposal,
    setProposal,
    loadList,
    modal,
    required
}) {
    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/proposta'}
            searchTable={'proposal'}
            searchColumn={'description'}
            label="Proposta"
            modal={modal}
            item={proposal}
            setItem={setProposal}
            loadList={loadList}
            valueExtractor={
                /**
                 * @param {Proposal} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {Proposal} option 
                 * @returns 
                */
                (option) => {
                    return option?.id > 0 ?  `${option?.id} - ${option?.description} (${option?.product?.title})` : ''
                }
            }
        ></FormSearch>
    )
}