import { Box, Button, Divider, Link } from "@mui/material";
import { GlobalContext } from "GlobalContext";
import Person from "class/person/Person";
import { useContext, useState } from "react";
import UserService from "service/verbify/user/UserService";
import PersonService from "service/verbify/user/person/PersonService";
import MainCard from "ui-component/cards/MainCard";
import FormUser from "ui-component/populated/user/FormUser";
import FormPerson from "ui-component/populated/user/person/FormPerson";
import FormStudent from "ui-component/populated/user/student/FormStudent";
import TabsComponent from "ui-component/tabsComponent";
import FormAddress from "../../ui-component/populated/address/FormAddress";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import ObjectResponseList from "class/ObjectResponseList";
import ModalFormPerson from "ui-component/populated/user/person/ModalFormPerson";
import { IconTrash } from "@tabler/icons";
/**
 * @param {{personData?:Person, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function MyPersonView({ personData, setOpenModal, setItem }) {
    const userService = new UserService();
    const personService = new PersonService();

    const stateListPersons = useState(new ObjectResponseList());

    const { person, setPerson } = useContext(GlobalContext).person
    console.log("PEOSONCA", person);
    return (
        <MainCard
            title='Seu perfil'
            sx={{
                width: { xs: "100%" },
                height: "100%",
                margin: "0px auto"
            }}
        >
            {person?.user?.id > 0 && <FormUser userData={person?.user}></FormUser>}
            <Divider textAlign="left" sx={{ margin: "35px 0px 20px 0px" }}>
            </Divider>

            <TabsComponent
                listTabs={[
                    {
                        label: "Meus perfis",
                        component: <>
                            <ModalFormPerson  stateList={stateListPersons} label="Adicionar Pessoa / Vincular Empresa" user={person?.user}></ModalFormPerson>
                            <DataGridBase
                                columns={[
                                    { field: 'action', headerName: 'Ações', width: 250, renderCell : ({row}) => 
                                    <>
                                        <Box p={1.5}>
                                            <ModalFormPerson stateList={stateListPersons} person={row} label="Editar"></ModalFormPerson>
                                        </Box> 
                                        <Box p={1.5}>
                                            <Button
                                                    onClick={async () => {
                                                        if(confirm("Tem certeza que deseja excluir este elemento?")){
                                                            await personService.delete(row.id)
                                                            location.reload()
                                                        }
                                                    }}
                                                >
                                                    <IconTrash size={20} />
                                            </Button>
                                        </Box>
                                    </>},
                                    { field: 'id', headerName: 'Codigo', width: 250 },
                                    { field: 'name', headerName: 'Nome', width: 250 }
                                ]}
                                functionListItens={() => personService.mysPerson()}
                                stateItens={stateListPersons}
                            />
                        </>
                    },
                    {
                        label: "Perfil",
                        component: <FormPerson statePerson={[person, setPerson]} personData={person} setItem={setItem}></FormPerson>
                    },
                    {
                        label: "Aluno",
                        component: <FormStudent person={person}></FormStudent>
                    }
                ]}
            >

            </TabsComponent >
        </MainCard >
    );

}
