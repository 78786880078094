import { Stack, TextField, Typography } from "@mui/material";
import InputQuestion from "./InputQuestion";
import { useEffect } from "react";

export default function BoxInputQuestion({ stateCustomFormResponseQuestion, customFormQuestion, type, customFormResponseQuestionService }) {
    const [customFormResponseQuestion, setCustomFormResponseQuestion] = stateCustomFormResponseQuestion

    function save(customFormResponseQuestionData) {
        const data = customFormResponseQuestionData ?? customFormResponseQuestion;
        const isUpdate = data?.id != undefined && data?.id != null;

        (isUpdate ? customFormResponseQuestionService.update(data) : customFormResponseQuestionService.insert(data))
            .then((response) => {
                setCustomFormResponseQuestion(isUpdate ? data : { ...data, id: response.data.model.id } );
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {

            });
    }

    // useEffect(() => {
    //     if (
    //         customFormResponseQuestion && (
    //             customFormResponseQuestion?.value !== null &&
    //             customFormResponseQuestion?.value !== undefined &&
    //             customFormResponseQuestion?.value !== ''
    //         )
    //     ) save()
    // }, [customFormResponseQuestion?.value]);

    return <Stack gap={1} width={'100%'}>
        {
            customFormQuestion.id > 0 && <>
                <Typography variant="h3">{customFormQuestion.title}</Typography>
                <Typography variant="normal">{customFormQuestion.description}</Typography>
                <InputQuestion
                    value={customFormResponseQuestion?.value || ''}
                    width={'100%'}
                    callback={(data) => save(data)}
                    stateCustomFormResponseQuestion={stateCustomFormResponseQuestion}
                    type={type}
                >
                </InputQuestion>
            </>
        }
    </Stack>
}
