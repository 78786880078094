import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import IMask from 'imask';
import Duration from 'class/utils/Duration';

/**
 * 
 * @param {Object} props
 * @param {Duration} props.duration 
 * @param {(clearDurection:(value:string) => void) => void} props.setDurationInput
 * @returns 
 */
function DurationInput({duration, setDuration, label, required, placeholder, setDurationInput}) {
  const [ time, setTime ] = useState('');

  setDurationInput(setTime);

  const maskOptions = {
    mask: 'H:M',
    blocks: {
        H : {
            mask: IMask.MaskedRange,
            from: 0,
            to: 838
        },
        M : {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
        }
    },
    lazy: true
  };

  const durationMask = IMask.createMask(maskOptions);

  // useEffect(() => {
  //   if (duration.toString() === '00:00:00');
  //     setTime('');
  // }, [duration])

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      required={required}
      variant="outlined"
      value={time}
      onChange={(e) => {
        if (e.target.value && e.target.value.split(':').length == 2) 
          e.target.value = e.target.value.padStart(4, '0');
        durationMask.resolve(e.target.value);
        setTime(durationMask.value);
        setDuration(durationMask.value);
      }}
      onBlur={(e) => {
        if (time.length > 0) {
          const arrayTime = time.split(':');
          if (arrayTime.length == 1)
            arrayTime.push('00');
          arrayTime[0] = arrayTime[0].padStart(3, '0');
          arrayTime[1] = arrayTime[1].padStart(2, '0');
          setTime(`${arrayTime[0]}:${arrayTime[1]}`);
          setDuration(`${arrayTime[0]}:${arrayTime[1]}`);
        }
      }}
    />
  );
}

export default DurationInput;
