import { lazy } from "react";

import Loadable from 'ui-component/Loadable';
import MainLayout from "layout/MainLayout";

const MensageConfig = Loadable(lazy(() => import('views/mensage/MensageView')))
const AbsenceType = Loadable(lazy(() => import('views/absenceType/AbsenceTypeView')))

const ConfigRoutes = {
    path: '',
    element: <MainLayout />,
    children: [
        {
            path: '/mensage-config',
            element: <MensageConfig />
        },
        {
            path: '/ausencia-tipo',
            element: <AbsenceType />
        }
    ]
}

export default ConfigRoutes;