import { Box, Stack } from "@mui/material";
import Customer from "class/customer/Customer";
import { useEffect, useState } from "react";
import CustomerManagerService from "service/verbify/user/customer/manager/CustomerManagerService";
import FormSearchCustomer from "ui-component/populated/customer/FormSearchCustomer";
import FormSearchPerson from "../../person/FormSearchPerson";
import Person from "class/person/Person";
import PersonService from "service/verbify/user/person/PersonService";
import { LoadingButton } from "@mui/lab";

export default function FormCustomerManager({
    stateCustomerManager,
    stateCustomer
}) {
    const state = useState(new Customer());
    const [customer, setCustomer] = stateCustomer ?? state

    const stateManager = useState(new Customer());
    const [customerManager, setCustomerManager] = stateCustomerManager ?? stateManager

    function setPerson(person) {
        setCustomerManager(prev => {
            return {
                ...prev,
                person
            }
        })
    }
    const customerManagerService = new CustomerManagerService();
    const personService = new PersonService();
    console.log(customerManager);

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);

    function save(evento) {
        evento.preventDefault();

        setButtonLoading(true);

        const isUpdate = customerManager?.id != undefined && customerManager?.id != null;

        (isUpdate ? customerManagerService.update(customerManager) : customerManagerService.insert(customerManager))
            .then((response) => {
                if (setOpenModal) setOpenModal(false);
                setItem(isUpdate ? customerManager : { ...customerManager, id: response.data.model.id } );
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(()=>{
        setCustomerManager(prev => {
            return {
                ...prev,
                customer: customer
            }
        })
    }, [])
    return <Stack   
        component={'form'}
        direction={'column'}
        gap={2}
        onSubmit={save.bind(this)}
    >
        <FormSearchPerson
            person={customerManager?.person}
            loadList={(searchPatch, search, searchTable, searchColumn) => personService.searchFor(searchPatch, search, searchTable, searchColumn)}
            setPerson={setPerson}
        />
        <FormSearchCustomer
            customer={customer}
            setCustomer={setCustomer}
            loadList={(searchPatch, search, searchTable, searchColumn) => customerManagerService.searchFor(searchPatch, search, searchTable, searchColumn)}
            require={true}
            disabled={true}
        ></FormSearchCustomer>
        <LoadingButton
            type="submit"
            variant="outlined"
            loading={buttonLoading}
        >
            {"Salvar"}
        </LoadingButton>
    </Stack>
}