import Ace from 'class/ace/Ace';
import StudentModule from 'class/person/student/StudentModule';
import BaseService from 'service/BaseService';
import verbify from '../config';
/**
 * @extends BaseService<Ace>
 */
export default class AceService extends BaseService {
    static urlBase = "ace";
    constructor() {
        super(AceService.urlBase);
    }
    /**
     * 
     * @param {ObjectRequest} currentPage
     * @param {StudentModule} classeModuleStudent
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    listByClasseModuleStudent(currentPage, classeModuleStudent) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`turma-modulo-aluno/${classeModuleStudent?.id}/${this.baseUrl}?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
}