import axios from "axios";

const viacep = axios.create({
    baseURL: "https://viacep.com.br/ws",
    withCredentials: false,
    options: false,
});

viacep.interceptors.request.use((request) => {
    if (request.data) request.data = JSON.stringify(request.data);
    return request;
})

export default viacep;