import ObjectResponseList from "class/ObjectResponseList";
import ServicoMunicipal from "class/omie/servico/ServicoMunicipal";
import ServicoMunicipalService from "service/verbify/omie/geral/servico/ServicoMunicipalService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {ServicoMunicipal} props.servicoMunicipal
 * @param {React.Dispatch<React.SetStateAction<ServicoMunicipal>>} props.setServicoMunicipal
 * @param {string|undefined|null} props.label
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<ObjectResponseList<ServicoMunicipal>>, any> | null} props\.loadList
 * @param {boolean|null|undefined} props.required
 * @returns 
 */
export default function FormSearchServicoMunicipal({
    servicoMunicipal,
    setServicoMunicipal,
    label,
    loadList,
    required
}) {
    return <FormSearch
        required={required}
        label={label ?? 'Serviço município'}
        item={servicoMunicipal}
        setItem={setServicoMunicipal}
        loadList={loadList ? loadList : (searchPatch, search, searchTable, searchColumn) => {
            const servicoMunicipalService = new ServicoMunicipalService();
            return servicoMunicipalService.list();
        }}
        minimumLength={0}
        fullWidth={true}
        valueExtractor={
            /**
             * @param {servicoMunicipal} option 
             * @returns {string}
             */
            (option) => option?.cCodServMun
        }
        textExtractor={
            /**
             * @param {servicoMunicipal} option 
             * @returns {string}
            */
            (option) => (option?.cCodServMun?.length ?? 0) > 0 ? `LC116 ${option?.cCodServLC116 ?? ''} - ${(option?.cDescricao ?? '')}` : ''
        }
    ></FormSearch>;
}