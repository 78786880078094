import Language from "./Language";

export default class Module {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {string}
     * @public
     */
    description = '';
    /**
     * @type {Language}
     * @public
     */
    language = new Language();
}