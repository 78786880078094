import Recurrence from 'class/utils/Recurrence';
import ClasseModule from 'class/secretary/classe/ClasseModule';
import Teacher from './Teacher';

export default class TeacherModule {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {Date}
     * @public
     */
    startDate = new Date();
    /**
     * @type {Date}
     * @public
     */
    endDate = null;
    /**
     * @type {Date}
     * @public
     */
    price = 0;
     /**
     * @type {Recurrence}
     * @public
     */
    paymentPeriod = new Recurrence();
    /**
     * @type {Teacher}
     * @public
     */
    teacher = new Teacher();
    /**
     * @type {ClasseModule}
     * @public
     */
    classeModule = new ClasseModule();
}