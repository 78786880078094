import { IconSchool, IconLanguage, IconCategory, IconHexagons, IconMoneybag, IconPigMoney, IconFileDollar, IconCalendarDue, IconAt, IconBooksOff } from "@tabler/icons";
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';

const admin = {
    id: 'adminMenu',
    title: 'Configurações - Sistema',
    type: 'group',
    children: [
        {
            id: 'schoolFile',
            title: 'Arquivos',
            type: 'collapse',
            icon: IconSchool,
            children: [
                {
                    id: 'schoolPresentation',
                    title: 'Apresentações',
                    type: 'item',
                    icon: IconSchool,
                    url: '/escola/comercial/apresentacao',
                    target: false,
                    breadcrumbs: false
                },
                {
                    id: 'schoolTamplates',
                    title: 'Tamplates',
                    type: 'item',
                    icon: IconSchool,
                    url: '/escola/comercial/apresentacao/pagina/tamplate',
                    target: false,
                    breadcrumbs: false
                },
            ]

        },
        {
            id: 'email',
            title: 'E-mail',
            type: 'collapse',
            icon: IconAt,
            children: [
                {
                    id: 'mensage-config',
                    title: 'E-mails de Sistema',
                    type: 'item',
                    icon: IconSchool,
                    url: 'mensage-config',
                    target: false,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'absence',
            title: 'Ausencia',
            type: 'collapse',
            icon: IconBooksOff,
            children: [
                {
                    id: 'absence-type',
                    title: 'Tipos de ausencia',
                    type: 'item',
                    icon: IconBooksOff,
                    url: 'ausencia-tipo',
                    target: false,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'school',
            title: 'Cadastros',
            type: 'collapse',
            icon: IconSchool,
            children: [
                {
                    id: 'user',
                    title: 'Usuário',
                    type: 'item',
                    icon: IconSchool,
                    url: 'escola/usuario',
                    target: false,
                    breadcrumbs: false
                },
                {
                    id: 'employee',
                    title: 'Funcionarios',
                    type: 'item',
                    icon: IconSchool,
                    url: 'escola/funcionario',
                    target: false,
                    breadcrumbs: false
                },
                {
                    id: 'schoolCustomers',
                    title: 'Clientes',
                    type: 'collapse',
                    icon: IconSchool,
                    children: [
                        {
                            id: 'schoolCustomers',
                            title: 'Clientes',
                            type: 'item',
                            icon: IconSchool,
                            url: '/escola/cliente',
                            target: false,
                            breadcrumbs: false
                        },
                        {
                            id: 'schoolProducts',
                            title: 'Gestores',
                            type: 'item',
                            icon: IconSchool,
                            url: '/escola/cliente/gestor',
                            target: false,
                            breadcrumbs: false
                        }
                    ]
                },
                // {
                //     id: 'schoolCustomerManager',
                //     title: 'Gestor',
                //     type: 'item',
                //     icon: IconSchool,
                //     url: '/escola/cliente/gestor',
                //     target: false,
                //     breadcrumbs: false
                // },
                {
                    id: 'schoolDocument',
                    title: 'Documentos',
                    type: 'item',
                    icon: IconSchool,
                    url: '/escola/documentos',
                    target: false,
                    breadcrumbs: false
                },
                // {
                //     id: 'schoolClasse',
                //     title: 'Turmas',
                //     type: 'item',
                //     icon: IconSchool,
                //     url: '/escola/turma',
                //     target: false,
                //     breadcrumbs: false
                // },
                {
                    id: 'schoolCustomForm',
                    title: 'Formularios',
                    type: 'item',
                    icon: IconSchool,
                    url: '/escola/formularios',
                    target: false,
                    breadcrumbs: false
                },

                // {
                //     id: 'addUser',
                //     title: 'Adicionar Usuário',
                //     type: 'item',
                //     icon: IconSchool,
                //     url: 'escola/usuario/novo',
                //     target: false,
                //     breadcrumbs: false
                // },
            ]
        }

    ]
}
export default admin;