export default class Product {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {string}
     * @public
     */
    description = '';
    /**
     * @type {number|null}
     * @public
     */
    type = null;
    /**
     * @type {number|null}
     * @public
     */
    price = null;
    /**
     * @public
     * @type {string|null}
     */
    fkProductOmieTipoTributacaoId = null;
    /**
     * @type {string|null}
     * @public
     */
    fkProductOmieCategoriaId = '';
    /**
     * @type {string|null}
     * @public
     */
    fkProductOmieLeiComplementar116 = '';
    /**
     * @type {string|null}
     * @public
     */
    fkProductOmieServicoMunicipalId = '';
    /**
     * @type {string|null}
     * @public
     */
    uf = '';
    /**
     * @type {string|null}
     * @public
     */
    fkProductOmieCidadeId = '';
    /**
     * @type {number|null}
     * @public
     */
    alicotaPIS = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoPIS = false;
    /**
     * @type {number|null}
     * @public
     */
    alicotaCSLL = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoCSLL = false;
    /**
     * @type {number|null}
     * @public
     */
    alicotaISS = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoISS = false;
    /**
     * @type {number|null}
     * @public
     */
    alicotaCOFINS = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoCOFINS = false;
    /**
     * @type {number|null}
     * @public
     */
    alicotaIR = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoIR = false;
    /**
     * @type {number|null}
     * @public
     */
    alicotaINSS = null;
    /**
    * @type {bool|null}
    * @public
    */
    retidoINSS = false;
}