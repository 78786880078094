import Recurrence from "class/utils/Recurrence";
import RecurrenceService from "service/verbify/utils/RecurrenceService";
import FormSearch from "ui-component/FormSeach";
/**
 * @param {Object} props
 * @param {Recurrence} props.recurrence
 * @param {(recurrence:Recurrence) => void} props.setRecurrence
 * @param {string} props.label
 * @param {boolean} props.required
 * @returns 
 */
export default function FormSearchRecurrence({
    recurrence,
    setRecurrence,
    label,
    required
}) {
    const recurrenceService = new RecurrenceService();
    return <FormSearch
        required={required}
        loadList={(...props) => recurrenceService.searchFor(props[3], props[1])}
        searchPatch={'/buscar/recurrence'}
        searchTable={'recurrence'}
        searchColumn={'label'}
        label={label ?? "Recorrência"}
        item={recurrence}
        setItem={setRecurrence}
        minimumLength={0}
        valueExtractor={
            /**
             * @param {DayOfWeek} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {DayOfWeek} option 
             * @returns 
            */
            (option) => !option || option?.id == 0 ? '' : `${option.label}`
        }
    />
}