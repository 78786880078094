import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import Customer from "class/customer/Customer";
import Proposal from "class/proposal/Proposal";
import Classe from "class/secretary/Classe";
import { useEffect, useState } from "react";
import ClasseService from "service/verbify/secretary/ClasseService";
import FormSeachCustomer from "ui-component/populated/customer/FormSearchCustomer";
import FormSeachEmployee from "ui-component/populated/employee/FormSeachEmployee";

/**
 * @param {Object} props 
 * @param {number?} props.id
 * @param {(item: Classe) => void} props.setItem
 * @param {Proposal} props.proposal
 * @returns {React.JSX.Element}
 */
export default function FormClasse({ id, setItem, item, proposal }) {
    const classeService = new ClasseService();
    /**
     * @type {[Classe, (classe:Classe) => void]}
     */
    const [classe, setClasse] = useState(new Classe());
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();

        setButtonLoading(true);

        const isUpdate = id != undefined && id != null;

        (isUpdate ? classeService.update(classe) : classeService.insert(classe))
            .then((response) => {                    
                setItem(isUpdate ? classe : {
                    ...classe,
                    id : response.data.model.id
                });
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    useEffect(function () {
        const isUpdate = id != undefined && id != null;
        //Carraga os dados da languagem caso tenha o id
        if (isUpdate && isNumber(id))
            classeService
                .search(id)
                .then(response => setClasse(response.data))
                .catch(ex => { })
                .finally();
    }, []);

    useEffect(function () {
        console.log("DETALHESFINAIS", classe.customer)
    }, [classe.customer]);

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >
                <TextField
                    required
                    fullWidth
                    value={classe.title}
                    onChange={(e) => setClasse(prev => {
                        return {
                            ...prev,
                            title: e.target.value
                        }
                    })}
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    inputProps={{ maxLength: 45 }}
                ></TextField>

                <FormSeachEmployee
                    label={'Coordenador'}
                    employee={classe.employee}
                    loadList={(searchPatch, search, searchTable, searchColumn) => classeService.searchFor(searchPatch, search, searchTable, searchColumn)}
                    setEmployee={(employee) => {
                        setClasse(prev => {
                            return {
                                ...prev,
                                employee: employee
                            }
                        });
                    }}
                />

                <FormSeachCustomer
                    customer={classe.customer}
                    loadList={(searchPatch, search, searchTable, searchColumn) => classeService.searchFor(searchPatch, search, searchTable, searchColumn)}
                    setCustomer={(customer) => {
                        setClasse(prev => {
                            return {
                                ...prev,
                                customer: customer
                            }
                        });
                    }}
                />

                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Stack>
        </form>
    );
}