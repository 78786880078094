import { Box, Divider, Stack, Typography } from "@mui/material";
import { GlobalContext } from "GlobalContext";
import ObjectResponseList from "class/ObjectResponseList";
import Customer from "class/customer/Customer";
import CustomerManager from "class/customerManager/CustomerManager";
import Person from "class/person/Person";
import { useContext, useEffect, useState } from "react";
import CustomerService from "service/verbify/user/customer/CustomerService";
import MainCard from "ui-component/cards/MainCard";
import DataGridCustomerManager from "ui-component/populated/user/customer/manager/DataGridCustomerManager";
import FormCustomerManager from "ui-component/populated/user/customer/manager/FormCustomerManager";
/**
 * @param {{personData?:Person, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function MyCustomView() {
    const customerService = new CustomerService();
    const { person, setPerson } = useContext(GlobalContext).person
    const [customer, setCustomer] = useState(new Customer())
    const [customerManager, setCustomerManager] = useState(new CustomerManager())
    const [customers, setCustomers] = useState(new ObjectResponseList())

    useEffect(() => {
        customerService.searchByPerson(person)
            .then(resp => {
                setCustomer(resp.data)
            })
    }, [person?.id])

    return (

        <MainCard
            title={customer.id > 0 && 'Pagina do cliente'}
            sx={{
                width: { xs: "100%" },
                height: "100%",
                margin: "0px auto"
            }}
        >
            {
                customer.id > 0
                    ?
                    <Stack
                        gap={2}
                        direction={'column'}
                    >
                        <Typography variant="h3">Gestores</Typography>
                        <FormCustomerManager
                            stateCustomer={[customer, setCustomer]}
                            stateCustomerManager={[customerManager, setCustomerManager]}
                        >
                            <Divider sx={{
                                border: 'none',
                                p: 2
                            }}></Divider>
                        </FormCustomerManager>
                        {
                            customer?.id > 0 && <DataGridCustomerManager
                                stateCustomer={[customer, setCustomer]}
                                stateCustomerManager={[customerManager, setCustomerManager]}
                                stateList={[customers, setCustomers]}
                            />
                        }
                    </Stack>
                    :
                    <Box p={5} bgcolor={"primary.light"}>
                        <Typography textAlign={"center"} variant="h2">Cliente não encontrado</Typography>
                    </Box>
            }
        </MainCard>

    );

}
