import ModalForm from "ui-component/ModalForms";
import { useEffect, useState } from "react";
import FormDocument from "./FormDocument";
import Document from "class/document/Document";

/**
 * 
 * @param {{label:string, documents?:Document, document:Document }} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormDocument({ stateList, label, description, callback, stateDocument }) {
    const [open, setOpen] = useState(false);

    const [ documents, setDocuments ] = useState(stateList ? null : {
        rows: [],
        totalRows: 0
    });

    const stateDocuments = stateList ?? [documents, setDocuments ];

    function setItem(documents) {
        stateDocuments[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == documents.id)) {
                const index = newListItens.findIndex(f => f.id == documents.id);

                newListItens[index] = documents;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        ...newListItens,
                        documents
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Adicionar documento"}
            description={description ?? "Informe os dados da documento nos campos abaixo:"}
            buttonLabel={label}
            width={'sm'}
            open={open}
            setOpen={setOpen}
            form={<FormDocument stateDocument={stateDocument} setOpenModal={setOpen} callback={callback}></FormDocument>}
        >
        </ModalForm>
    );
}