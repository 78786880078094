import StudentModule from "class/person/student/StudentModule";
import FrequencySheet from "./FrequencySheet";
import AbsenceType from "class/absenceType/AbsenceType";
import Duration from "class/utils/Duration";
import Item from "class/schedule/Item";

export default class Absence {

    /** @type {number|null} */
    id = null;

    /** @type {string|null} */
    justificationForAbsenceFile = null;

    /** @type {string|null} */
    description = null;

    /** @type {string|null} */
    hoursWorked = "00:00";

    /** @type {AbsenceType} */
    absenceType = new AbsenceType();

    /** @type {Item} */
    scheduleItem = null;

    /** @type {FrequencySheet} */
    frequencySheet = new FrequencySheet();

    /** @type {FrequencySheet} */
    resetFrequencySheet = new FrequencySheet();

    /** @type {StudentModule} */
    classeModuleStudent = new StudentModule();

}