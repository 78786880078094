import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { IconForms } from "@tabler/icons";
import { useEffect } from "react";
import CustomFormResponseService from "service/verbify/customForm/response/CustomFormResponseService";

/**
 * @param {Object} param0
 * @param {(customFormResponse: CustomFormResponse) => void} param0.callbcakLoading
 * @param {(customFormResponse: CustomFormResponse) => void} param0.callbackSave
 */

export default function FormCustomFormResponse({ statePerson, stateCustomForm, stateCustomFormResponse, callbackSave, callbcakLoading }) {
    const [person, setPerson] = statePerson
    const [customForm, setCustomForm] = stateCustomForm
    const [customFormResponse, setCustomFormResponse] = stateCustomFormResponse

    const customFormResponseService = new CustomFormResponseService();

    useEffect(() => {
        if (person?.id > 0 && customForm?.id > 0) setCustomFormResponse(prev => {
            return {
                ...prev,
                customForm: customForm,
                person: person
            }
        })
    }, [person, customForm])

    function addCustomFormResponse() {
        customFormResponseService.insert(customFormResponse)
            .then(response => {
                response?.data?.model?.id > 0 && setCustomFormResponse(prev => {
                    const data = {
                        ...prev,
                        id: response.data.model.id
                    }
                    if(callbackSave) callbackSave(data)
                    return data
                })
            })
    }

    return (
        (customFormResponse.id === null || customFormResponse.id === undefined)
        &&
        <Box sx={{
            margin: 4,
            height: "100% !important",
            backgroundColor: 'primary.light',
            padding: [4, 10],
            borderRadius: 2
        }}>
            <Stack maxWidth={400} margin={"0px auto"} alignContent={'center'} gap={2}>
                <Typography textAlign={'center'} variant="h2">{customForm?.id} - {customForm?.title}</Typography>
                <Typography textAlign={'center'} variant="h3">{customForm?.description}</Typography>
                <Typography textAlign={'center'} variant="h4">{(new Date()).toLocaleDateString()} - {(new Date()).toLocaleTimeString()}</Typography>

                {
                    person?.id && <>
                        <Divider><Typography variant="h5" >Pessoa</Typography></Divider>
                        <Typography textAlign={'center'} variant="h2">{person.name}</Typography>
                    </>
                }

                <Divider>
                    <Typography variant="h5" >Responder formulario</Typography>
                </Divider>

                <Button variant="contained" onClick={addCustomFormResponse} startIcon={<IconForms></IconForms>}>Abrir formulario</Button>
            </Stack>
        </Box>

    )
}