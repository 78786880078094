// {
//     "numberInstallment": 1,
//     "valueBase": "53.425000",
//     "valuePredicted": "53.425000",
//     "valuePaid": "0.00",
//     "dueDate": "2023-11-15",
//     "countInstallment": 6,
//     "dueDateWhenExpired": "2023-10-18",
//     "paymentOrigin": {
//         "id": 1,
//         "name": "Mensalidade"
//     },
//     "paymentSituation": {
//         "id": 0,
//         "name": "Não gerada"
//     },
//     "debtor": {
//         "id": 74,
//         "name": "Gabriel Oliveira"
//     },
//     "beneficiary": {
//         "id": 74,
//         "name": "Gabriel Oliveira"
//     },
//     "reference": {
//         "id": 37,
//         "module": {
//             "id": 10,
//             "title": "Regional Data Agent",
//             "languange": {
//                 "id": 75,
//                 "title": "Inglês"
//             }
//         }
//     }
// }
import Person from "class/person/Person";
import Origin, { EnumOrigin } from "./Origin";
import Situation from "./Situation";
/**
 * @class
 * @template T
 */
export default class Payment {
    /**
     * @type {number|null}
     * @public
     */
    id = null;
    /**
     * @type {number}
     * @public
     */
    fkPaymentOmieId = 0;
    /**
     * @type {number}
     * @public
     */
    numberInstallment = 1;
    /**
     * @type {Date}
     * @public
     */
    dueDate = new Date();
    /**
     * @type {number}
     * @public
     */
    valueBase = 0.00;
    /**
     * @type {number}
     * @public
     */
    valuePredicted = 0.00;
    /**
     * @type {number}
     * @public
     */
    valuePaid = 0.00;
    /**
     * @type {Origin}
     * @public
     */
    paymentOrigin = new Origin();
    /**
     * @type {Situation}
     * @public
     */
    paymentSituation = new Situation();
    /**
     * @type {Person}
     * @public
     */
    beneficiary = new Person();
    /**
     * @type {Person}
     * @public
     */
    debtor = new Person();
    /**
     * @type {T}
     * @public
     */
    reference = null;
    /**
     * @type {string}
     * @public
     */
    description = '';
    renderTextReference() {
        if (EnumOrigin.MENSALIDADE == this.paymentOrigin.id)
            return `${this.reference?.classeModule?.module?.language?.title} - ${this.reference?.classeModule?.module?.title}`;
        else if (EnumOrigin.FOLHADEPAGAMENTO == this.paymentOrigin.id)
            return this.reference?.classeModule?.module?.title;
        return '';
    }
    constructor (data) {
        // const propriedadesThis = Object.getOwnPropertyNames(this);
        // const propriedadesData = Object.getOwnPropertyNames(data);
        
        // propriedadesThis.forEach(element => {
        //     if (propriedadesData.some(any => any == element))
        //         this[element] = data[element];
        // });
        // // console.log("this.dueDate: ", data);
        // // this = {
        // //     ...this,
        // //     ...data
        // // }
        // if (typeof this.dueDate === 'string')
        //     this.dueDate = new Date(this.dueDate + ' 00:00:00');
        // console.log(this.dueDate);
    }
}