import * as React from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { IconX } from '@tabler/icons';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

export const ModalFormContext = React.createContext({});

/**
 * 
 * @param {{
 *  open:boolean|null,
 *  setOpen:React.Dispatch<boolean>,
 *  startIcon?:React.ReactNode,
 *  buttonLabel?:React.ReactNode,
 *  title?:string
 *  description?:string,
 *  form:React.ReactNode,
 *  width?:false | import('@mui/system').Breakpoint
 * }} props 
 * @returns 
 */
export default function ModalForm(props) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(props?.open)
    }, [props?.open])

    const handleClickOpen = () => {
        setOpen(true);
        if (props.setOpen) props.setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        if (props.setOpen) props.setOpen(false);
    };
    const theme = useTheme();
    return (
        <ModalFormContext.Provider value={{ setOpen }}>
            <Button
                sx={{
                    borderRadius: theme.shape.borderRadius ?? 4,
                    margin: 0.5
                }}
                variant={props.variant ?? 'text'} color={props.color ?? 'primary'} onClick={handleClickOpen} startIcon={props.startIcon}>
                {props.buttonLabel === undefined ? props.children : props.buttonLabel}
            </Button>
            <Dialog open={props.open ?? open} onClose={handleClose} fullWidth={true} maxWidth={props.width}>
                <Stack direction={"row"} justifyContent={'space-between'}>
                    <DialogTitle variant='h2'>
                        {props.title}
                    </DialogTitle>
                    <IconButton sx={{ p: 2 }} onClick={handleClose} autoFocus>
                        <IconX />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Box>
                        {props.description && <Typography variant="h3">{props.description}</Typography>}
                        {
                            props.tabs ?
                                <DialogContentText>
                                    {props.tabs}
                                </DialogContentText>
                                : <></>
                        }
                        {props.form}
                        {props.panel}
                    </Box>
                </DialogContent>
            </Dialog>
        </ModalFormContext.Provider>
    );
}