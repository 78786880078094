import DialogBase from "ui-component/populated/base/DialogBase";
import FormTeacherModule from "./FormTeacherModule";
import ObjectResponseList from "class/ObjectResponseList";
import { useState } from "react";
import TeacherModule from "class/person/teacher/TeacherModule";
import DataGridTeacherModule from "./DataGridTeacherModule";

export default function DialogFormTeacherModule({
    title,
    buttonOpenModal,
    classeModule
}) {
    /**
     * @type {[ObjectResponseList<TeacherModule>, React.Dispatch<ObjectResponseList<TeacherModule>>]}
     */
    const [ teachersModules, setTeachersModules ] = useState(new ObjectResponseList());
     /**
     * @type {[ObjectResponseList<TeacherModule>, React.Dispatch<ObjectResponseList<TeacherModule>>]}
     */
    const [ teacherModule, setTeacherModule ] = useState(new TeacherModule());
    /**
     * @param {TeacherModule} studentClasseParam 
     */
    function updateList(teacherModuleParam) {
        const index = teachersModules.rows.findIndex(any => any.id == teacherModuleParam.id);
        if (index > -1)
            setTeachersModules(prev => {
                let newList = [ ...prev.rows ];
                newList[index] = teacherModuleParam;
                return {
                    ...prev,
                    rows : newList
                };
            });
        else
            setTeachersModules(prev => {
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        teacherModuleParam,
                        ...prev.rows
                    ]
                };
            });
    }
    return <DialogBase title={title} buttonOpenModal={buttonOpenModal}>
        <FormTeacherModule classeModule={classeModule} stateTeacherModule={[teacherModule, setTeacherModule]} updateList={updateList}></FormTeacherModule>
        <div style={{ margin : '10px 0px' }}>
            <DataGridTeacherModule classeModule={classeModule} stateTeacherModule={[teacherModule, setTeacherModule]} stateList={[ teachersModules, setTeachersModules ]}></DataGridTeacherModule>
        </div>
    </DialogBase>;
}