import { Box, Button, Stack, TextField } from "@mui/material";
import FormSeachModule from "ui-component/populated/secretery/module/FormSeachModule";
import { useEffect, useState } from "react";
import ClasseModule from "class/secretary/classe/ClasseModule";
import Classe from "class/secretary/Classe";
import DurationInput from "ui-component/extended/InputMask/DurationInput";
import { LoadingButton } from "@mui/lab";
import DataGridModuleClasse from "./DataGridModuleClasse";
import Duration from "class/utils/Duration";
import ScheduleService from "service/verbify/schedule/ScheduleService";
import ClasseModuleService from "service/verbify/secretary/classe/ClasseModuleService";
import FormSeachClasse from "ui-component/populated/secretery/classe/FormSeachClasse";
import ClasseService from "service/verbify/secretary/ClasseService";
import ObjectResponseList from "class/ObjectResponseList";
import Proposal from "class/proposal/Proposal";
import ProposalService from "service/verbify/proposal/ProposalService";
import InputDuration from "ui-component/populated/base/InputDuration";
import FormSearchProposal from "ui-component/populated/proposal/FormSearchProposal";

/**
 * @param {Object} props
 * @param {Classe} props.classe
 * @param {Proposal} props.proposal
 * @param {(classeModule:ClasseModule) => void|undefined} props.setItem
 * @returns 
 */
export default function FormClasseClasseModule({ classe, proposal, setItem }) {
  /**
   * @type {[boolean, React.Dispatch<boolean>]} state
  */
  const [buttonLoading, setButtonLoading] = useState(false);

  const scheduleService = new ScheduleService();
  const classeModuleService = new ClasseModuleService();
  const classeService = new ClasseService();
  const proposalService = new ProposalService();

  const classeModuleInstancia = new ClasseModule();
  if(classe) classeModuleInstancia.classe = classe;
  if(proposal) classeModuleInstancia.proposal = proposal;

  const [classeModule, setClasseModule] = useState(classeModuleInstancia);

  const [classesModules, setClassesModules] = useState(new ObjectResponseList());

  /**
   * @param {string} value 
   */
  let setDurationInput = (value) => { };

  function resertClasseModele() {
    setClasseModule(classeModuleInstancia);
    setDurationInput('');
  }

  async function save(evento) {
    evento.preventDefault();
    evento.stopPropagation();

    try {
      setButtonLoading(true);

      let newClasseModule = { ...classeModule };

      //Verifica se o calendario já está cadastrado, caso não esteja cadastra
      const isUpdateAgenda =
        isNumber(newClasseModule?.schedule?.id)
        && newClasseModule.schedule.id > 0;

      const responseAgenda =
        isUpdateAgenda
          ? await scheduleService.update(newClasseModule.schedule)
          : await scheduleService.insert(newClasseModule.schedule);

      if (!isUpdateAgenda)
        newClasseModule.schedule.id = responseAgenda.data.model.id;

      const isUpdate =
        isNumber(newClasseModule?.id)
        && newClasseModule.id > 0;

      const response =
        isUpdate
          ? await classeModuleService.update(newClasseModule)
          : await classeModuleService.insert(newClasseModule);

      if (isUpdate)
        setClassesModules(prev => {
          const newList = [...prev.rows];
          const index = newList.findIndex(f => f.id == newClasseModule.id);

          newList[index] = {
            ...newClasseModule,
            classe: newClasseModule.classe,
            schedule: newClasseModule.schedule
          };

          if (setItem)
            setItem(newList[index]);

          return {
            ...prev,
            rows: [...newList]
          };
        });
      else {
        newClasseModule = {
          ...newClasseModule,
          id: response.data.model.id
        };

        if (setItem)
          setItem(newClasseModule);

        setClassesModules(prev => {
          return {
            rows: [
              newClasseModule,
              ...prev.rows
            ],
            totalRows: prev.totalRows + 1
          };
        });
      }

      resertClasseModele();
    } catch (ex) {
      console.log(ex);
    } finally {
      setButtonLoading(false);
    }
  }

  return <form
    onSubmit={save.bind(this)}
  >
    <Stack
      spacing={2}
      marginTop={1}
    >
      {(classeModule.classe == undefined && <FormSeachClasse
        classe={classeModule.classe ?? classe}
        setClasse={(newClasse) => {
          setClasseModule(prev => {
            return {
              ...prev,
              classe: newClasse
            }
          })
        }}
        loadList={(...props) => classeService.searchFor(...props)}
        required
      ></FormSeachClasse>)}
      <TextField
        fullWidth
        value={classeModule.schedule.title}
        onChange={(e) => setClasseModule(prev => {
          return {
            ...prev,
            schedule: {
              ...prev.schedule,
              title: e.target.value
            }
          }
        })}
        label="Nome"
        placeholder="Nome"
        variant="outlined"
        inputProps={{ maxLength: 45 }}
      ></TextField>

      <FormSeachModule
        label="Nível"
        required={true}
        module={classeModule.module}
        setModule={(module) => {
          setClasseModule(prev => {
            return {
              ...prev,
              module: module
            }
          })
        }}
      ></FormSeachModule>

      {/* <InputDuration
        value={classeModule.duration}
        onChange={(duration) => setClasseModule(prev => {
          return {
            ...prev,
            duration: duration
          }
        })}
      ></InputDuration> */}
      <FormSearchProposal
        required={true}
        proposal={classeModule.proposal}
        loadList={(searchPatch, search, searchTable, searchColumn) => proposalService.searchFor(searchPatch, search, searchTable, searchColumn)}
        setProposal={proposal => {
          setClasseModule(prev => {
            return {
              ...prev,
              proposal: proposal
            }
          })
        }}
      ></FormSearchProposal>
      <DurationInput
        duration={classeModule.duration}
        setDuration={(duration) => {
          setClasseModule(prev => {
            return {
              ...prev,
              duration: new Duration(duration)
            }
          })
        }}
        setDurationInput={(action) => setDurationInput = action}
        label={'Duração'}
        placeholder={'Duração'}
        required={true}
      />
      <LoadingButton
        type="submit"
        variant="outlined"
        loading={buttonLoading}
      >{"Salvar"}</LoadingButton>
      {(isNumber(classeModule.id) && classeModule.id > 0) && <Button
        onClick={(e) => resertClasseModele()}
        variant="outlined"
        color='error'
      >Cancelar</Button>}
    </Stack>

    {(classe != undefined && <Box mt={2}>
      <DataGridModuleClasse
        stateList={[classesModules, setClassesModules]}
        stateClasseModule={
          [
            classeModule,
            (classeModule) => {
              const classeModuleUpdate = new ClasseModule(classeModule);
              setClasseModule(classeModuleUpdate);
              setDurationInput(classeModuleUpdate.duration.toString());
            }
          ]
        }
      />
    </Box>
    )}
  </form>
}