import Person from "class/person/Person";
import CustomForm from "../CustomForm";

export default class CustomFormResponse {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
    /**
     * @type {CustomForm}
     * @public
     */
    customForm = new CustomForm();
}
