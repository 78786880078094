import CustomForm from "class/customForm/CustomForm";
import Person from "class/person/Person";
import CustomFormService from "service/verbify/customForm/CustomFormService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {CustomForm} props.customForm
 * @param {(customForm:CustomForm) => void} props.setCustomForm
 * @returns 
 */
export default function FormSearchCustomForm({
    customForm,
    setCustomForm,
    loadList,
    required,
    disabled,
    modal
}) {
    const service = new CustomFormService();
    const func = (searchPatch, search, searchTable, searchColumn) => service.searchFor(searchPatch, search, searchTable, searchColumn);
    return (
        <FormSearch
            modal={modal}
            disabled={disabled}
            required={required}
            searchPatch={'/buscar/formulario'}
            searchTable={'customForm'}
            searchColumn={'title'}
            label={"Buscar Formulario"}
            item={customForm}
            setItem={setCustomForm}
            loadList={loadList ?? func}
            valueExtractor={
                /**
                 * @param {CustomForm} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {CustomForm} option 
                 * @returns 
                */
                (option) => !option || option.id == undefined ? '' : `${option?.id} - ${option?.title} (${option?.open > 0 ? "Aberto" : "Fechado"})`
            }
        ></FormSearch>
    )
}