import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import ObjectResponseList from "class/ObjectResponseList";
import CustomForm from "class/customForm/CustomForm";
import { Box } from "@mui/material";
import FormClasseModuleStudentPrice from "./FormClasseModuleStudentPrice";
import ClasseModuleStudentPrice from "class/secretary/classe/student/price/ClasseModuleStudentPrice";
import StudentModule from "class/person/student/StudentModule";

/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {number|null|undefined} param0.id
 * @param {[ObjectResponseList<CustomForm>, React.Dispatch<ObjectResponseList<CustomForm>>]} param0.stateList 
 * @param {StudentModule} param0.classeModuleStudentData 
 * @param {ClasseModuleStudentPrice} param0.classeModuleStudentPriceData 
 * @returns {React.JSX.Element}
 */
export default function ModalFormClasseModuleStudentPrice({
    stateList, 
    label, 
    id,
    classeModuleStudentData,
    classeModuleStudentPriceData,
    updateList
}) {
    const [ idState, setIdState ] = useState(id);
    /**
     * @type {[ObjectResponseList<ClasseModuleStudentPrice>, React.Dispatch<ObjectResponseList<ClasseModuleStudentPrice>>]}
     */
    const list = useState(new ObjectResponseList);
    const [ classeModuleStudentPriceList, setClasseModuleStudentPriceList ] = stateList ?? list;

    const currentItem = classeModuleStudentPriceList.rows.find(f => f.id == idState);

    function setItem(classeModuleStudentPriceList) {
        setClasseModuleStudentPriceList(prev => {
            const newListItens = [ ...prev.rows ];

            if (newListItens.some(any => any.id == classeModuleStudentPriceList.id)) { 
                const index = newListItens.findIndex(f => f.id == classeModuleStudentPriceList.id);
                
                newListItens[index] = classeModuleStudentPriceList;

                return {
                    ...prev,
                    rows : [ ...newListItens ]
                }
            }
            else {
                setIdState(classeModuleStudentPriceList.id);
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        ...newListItens,
                        classeModuleStudentPriceList
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Gerenciar ACE"}
            description={"Informe os dados da ace nos campos abaixo:"}
            form={<Box>
                    <FormClasseModuleStudentPrice 
                        classeModuleStudentPriceData={
                            classeModuleStudentPriceData ?? { 
                                    ...(new ClasseModuleStudentPrice()), 
                                    classeModuleStudent: {
                                        ...classeModuleStudentData
                                    } 
                            }
                        } 
                        id={idState} 
                        item={currentItem} 
                        setItem={updateList ?? setItem}
                    ></FormClasseModuleStudentPrice>
                </Box>
            }
            buttonLabel={label}
            width={'xl'}
        >
        </ModalForm>
    );
}