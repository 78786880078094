import { useEffect, useState } from "react";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import { Box, Button, Checkbox } from "@mui/material";
import Absence from "class/secretary/frequency/Absence";
import { IconGavel } from "@tabler/icons";
import ModalForm from "ui-component/ModalForms";
import FormAbsence from "./FormAbsence";
import DataGridBase from "ui-component/populated/base/DataGridBase";
import Assessment from "class/secretary/frequency/assessment/Assessment";
import AssessmentStudentNote from "class/secretary/frequency/assessment/AssessmentStudentNote";
import FormAssessmentStudentNote from "../assessments/studentNote/FormAssessmentStudentNote";
import AbsenceService from "service/verbify/secretary/frequency/AbsenceService";
import DurationInput from "ui-component/extended/InputMask/DurationInput";
import ListItemFormAbsence from "./ListItemFormAbsence";
import { TimePicker } from "@mui/x-date-pickers";
/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} props 
 * @param {[FrequencySheet, React.Dispatch<React.SetStateAction<FrequencySheet>>]|undefined} props.stateFrequencySheet
 * @param {[Assessment, React.Dispatch<React.SetStateAction<Assessment>>]|undefined} props.stateAssessment
 * @param {[boolean|undefined, React.Dispatch<React.SetStateAction<boolean|undefined>>]|undefined} props.loading  
 * @returns {React.JSX.Element}
 */
export default function DataGridAbsence({
    stateFrequencySheet,
    stateAssessment,
    loading
}) {
    /** @type {[FrequencySheet, React.Dispatch<React.SetStateAction<FrequencySheet>>]} */
    const stateFrequencySheetCurrent = useState(stateFrequencySheet ? null : new FrequencySheet());
    const stateAssessementCurrent = useState(stateAssessment ? null : new Assessment());
    
    const [ frequencySheet, setFrequencySheet ] = stateFrequencySheet ?? stateFrequencySheetCurrent;
    const [ assessment, setAssessment ] = stateAssessment ?? stateAssessementCurrent;
    
    const absenceService = new AbsenceService(frequencySheet);

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} ev 
     */
    function changeAbasence(ev, classeModuleStudent) { 
        //#region Atualização de state
        if (ev.target.checked) {
            const absence = frequencySheet.studentsAbsence.find(f => f.classeModuleStudent.id == classeModuleStudent.id);

            absenceService
                .delete(absence.id)
                .then((resp) => {
                    setFrequencySheet((prev) => {
                        prev.studentsAbsence.remove((absence) => absence.classeModuleStudent.id == classeModuleStudent.id);
                        return { ...prev };
                    });
                }).catch((ex) => {

                });
        } else {
            const newAbsence = new Absence();
            newAbsence.classeModuleStudent = classeModuleStudent;
            newAbsence.frequencySheet = frequencySheet;
            
            absenceService
                .insert(newAbsence)
                .then((resp) => {
                    if (resp.data.success) {
                        newAbsence.id = resp.data.model.id;
                        setFrequencySheet((prev) => {
                            return {
                                ...prev,
                                studentsAbsence : [
                                    ...(prev.studentsAbsence ?? []),
                                    newAbsence
                                ]
                            };
                        });
                    }
                }).catch((ex) => {
                   
                });
        }
        //#endregion
    }

    return <Box
        sx={{
            '& > * > * ': { width: '100%' },
            padding : 0
        }}
    >
        <DataGridBase
            loadingProps={loading}
            stateItens={[
                {
                    rows : frequencySheet?.studentsModule,
                    totalRows : frequencySheet?.studentsModule?.length
                }
            ]}
            columns={[
                { 
                    field : 'present', 
                    renderCell : ({ row }) => {
                        console.log(frequencySheet.studentsAbsence);
                        const absence = !frequencySheet.studentsAbsence?.some(any => any.classeModuleStudent.id == row.id);

                        return <Box sx={{ display : 'flex', justifyContent: 'center', alignItems : 'center' }}>
                            {/* <Checkbox 
                                checked={(!(isNumber(frequencySheet.id) && frequencySheet.id > 0)) ? true : absence}
                                disabled={!(isNumber(frequencySheet.id) && frequencySheet.id > 0)}
                                onChange={(ev) => changeAbasence(ev, row)}
                            /> */}

                            <ListItemFormAbsence 
                                frequencySheetId={frequencySheet.id}
                                stateAbsences={[
                                    frequencySheet.studentsAbsence, 
                                    (values) => setFrequencySheet((prev) => {
                                        prev.studentsAbsence = values;
                                        return {
                                            ...prev
                                        };
                                    })
                                ]}
                                stateStudentModule={[row]}
                            />

                            {!absence && (isNumber(frequencySheet.id) && frequencySheet.id > 0) && <Button>
                                <ModalForm
                                    title={"Justificar ausência"}
                                    description={`Informe os dados para justificar a ausência do aluno(a) ${row.student.person.name}:`}
                                    startIcon={<IconGavel style={{ marginRight : 0 }}></IconGavel>}
                                    form={<FormAbsence 
                                        classeModuleStudent={row}
                                        frequencySheet={frequencySheet}
                                    />}
                                ></ModalForm>
                            </Button>}
                        </Box>
                    }, 
                    headerName: 'Presença', 
                    width : 300
                },
                { field : 'student', valueGetter : (data) => data.row.student.person.name, headerName: 'Aluno', width : 250 },
                { 
                    field : 'note', 
                    renderCell : (data) => {
                        let studentNote = new AssessmentStudentNote()
                        studentNote.classeModuleStudent = data.row
                        studentNote.frequencySheet = assessment.frequencySheet
                        console.log("oOOFNOAOPDADOADPA", assessment);
                        return <>
                            {
                                assessment.id > 0 ? 
                                    <FormAssessmentStudentNote assessmentStudentNoteData={studentNote}></FormAssessmentStudentNote> 
                                :
                                    <>Sem avaliação</>
                            }
                        </>
                    }, 
                    headerName: 'Avaliação', 
                    width: 600
                },
            ]}
            disableRowSelectionOnClick
            onCellClick={(row, e) => e.stopPropagation()}
        ></DataGridBase>
    </Box> 
}