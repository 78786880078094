import AccountService from "service/verbify/payment/bank/AccountService";
import FormSearch from "ui-component/FormSeach";

export default function FormSearchAccount({
    account,
    setAccount,
    label,
    loadList,
    required
}) {
    const accountService = new AccountService();

    return <FormSearch
        required={required}
        searchColumn={'title'}
        label={label ?? "Conta"}
        minimumLength={0}
        item={account}
        setItem={setAccount}
        loadList={loadList ? loadList : (...param) => accountService.listOmie()}
        valueExtractor={
            /**
             * @param {any} option 
             * @returns {string}
             */
            (option) => option?.nCodCC
        }
        textExtractor={
            /**
             * @param {any} option 
             * @returns {string}
            */
            (option) => option?.nCodCC > 0 ? `Banco ${option?.codigo_banco ?? ''} - Conta ${option?.nCodCC ?? '-'}` : ''
        }
    ></FormSearch>
}