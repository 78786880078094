import StudentModule from "class/person/student/StudentModule";
import ClasseModule from "class/secretary/classe/ClasseModule";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

/**
 * @extends {BaseService<StudentModule>}
 */
export default class StudentModuleService extends BaseService {
    /**
     * @type {string} baseUrl
     * @private
     */
    static _baseUrl = 'turma-module/{classeModule}/aluno';
    /**
     * @param {Object} props
     * @param {ClasseModule} props.classeModule 
     */
    constructor({ classeModule }) {
        if(classeModule?.id > 0) super(StudentModuleService._baseUrl.replace('{classeModule}', classeModule.id));
        else super(StudentModuleService._baseUrl);
    }
    /**
     * @param {string} searchPatch 
     * @param {string} search // word or phrase
     * @param {string} searchTable 
     * @param {string} searchColumn 
     * @param {[]} outheParams 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    searchFor(searchPatch, search, searchTable, searchColumn, outheParams) {
        return verbify.get(`${searchPatch}?word=${search}&table=${searchTable}&column=${searchColumn}&classModule=${outheParams[0] ?? outheParams[0]?.id}&recursive=${outheParams[1]}`)
    }
    /**
     * 
     * @param {StudentModule} studentModule 
     * @returns {Promise<import("axios").AxiosResponse<void, any>>}
     */
    update(studentModule) {
        return verbify.put(`${StudentModuleService._baseUrl.replace('{classeModule}', studentModule.classeModule.id.toString())}/${studentModule.student.person.id.toString()}`, studentModule);
    }
    /**
     * 
     * @param {Object} param 
     * @param {Date|string} param.startDate
     * @returns {Promise<import("axios").AxiosResponse<StudentModule[], any>>}
     */
    listAgenda({
        startDate
    }) {
        return verbify.get(`${StudentModuleService._baseUrl.replace('{classeModule}/', '')}/agenda?startDate=${typeof startDate === 'string' ? startDate : startDate.toJSON()}`)
    }
    /**
     * 
     * @param {Object} param 
     * @param {StudentModule} param.classeModuleStudent
     * @param {ClasseModule} param.classeModule
     * @returns {Promise<import("axios").AxiosResponse<StudentModule[], any>>}
     */
    vReportFrequencySheetConsolidada(classeModuleStudent) {
        return verbify.get(`vReportFrequencySheetConsolidada?classeModuleStudent=${classeModuleStudent?.id ?? null}&classeModuleStudentPerson=${classeModuleStudent?.student?.person?.id ?? null}&classeModule=${classeModuleStudent?.classeModule?.id ?? null}`)
    }
}