import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import 'assets/css/scrollBar.css';
import 'assets/css/reset.css';

import './utils/global';
import config from './config';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RichText from 'ui-component/richText/RichText';
import GlobalContextComponent from 'GlobalContext';
// import FormSearch from 'ui-component/FormSeach';
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  // <RichText></RichText>
  <GlobalContextComponent>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <BrowserRouter basename={config.basename}>
          <App />
        </BrowserRouter>
      </ LocalizationProvider>
    </Provider>
  </GlobalContextComponent>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
