import User from "class/person/user/User";
import dayjs from "dayjs";

export default class Task {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {string}
     * @public
     */
    description = '';
    /**
     * @type {number}
     * @publica
     */
    type = 0;
    /**
     * @type {number}
     * @publica
     */
    situation = 0;
    /**
     * @type {string}
     * @public
     */
    startDateTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    /**
     * @type {Date}
     * @public
     */
    endDateTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    /**
     * @type {User}
     * @public
     */
    user = new User();
}