import ObjectResponseList from "class/ObjectResponseList";
import TipoTributacao from "class/omie/servico/TipoTributacao";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class TipoTributacaoService extends BaseService {
    /**
     * @private
     */
    static _baseUrl = 'omie/geral/tipo-tributacao';
    constructor () {
        super(TipoTributacaoService._baseUrl);
    }
    /**
     * @returns {Promise<import("axios").AxiosResponse<ObjectResponseList<TipoTributacao>, any>>}
     */
    list () {
        return verbify.get(`${this.baseUrl}`);
    }
}