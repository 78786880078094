import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import { Box } from '@mui/material';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <Box sx={{ height: "90vh"}}>
    <Outlet />
    <Customization />
  </Box>
);

export default MinimalLayout;
