/**
 * @template T
 */
export default class ObjectResponseList {
    /**
     * @type {number} 
     * @public
     */
    totalRows = 0;
    /**
     * @type {T[]} 
     * @public
     */
    rows = [];
}