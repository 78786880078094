import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import FormSearchPerson from "../user/person/FormSearchPerson";
import Customer from "class/customer/Customer";
import CustomerService from "service/verbify/user/customer/CustomerService";
import Person from "class/person/Person";
import PersonService from "service/verbify/user/person/PersonService";
import { Typography } from "@mui/material";
import FormPerson from "../user/person/FormPerson";
import ModalFormUser from "../user/ModalFormUser";
import { IconPlus } from "@tabler/icons";

/**
 * @param {{customerData?:Customer, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormCustomer({ customerData, setOpenModal, setList }) {

    const [customer, setCustomer] = useState(new Customer(customerData));

    const customerService = new CustomerService();
    const personService = new PersonService();

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */

    function save(evento) {
        evento.preventDefault();
        evento.stopPropagation();

        setButtonLoading(true);

        const isUpdate = customer?.id != undefined && customer?.id != null;

        (isUpdate ? customerService.update(customer) : customerService.insert(customer))
            .then((response) => {
                if (setOpenModal) setOpenModal(false);
                setList(isUpdate ? customer : prev => { 
                    return {
                        ...prev,
                        id: response.data.model.id 
                    } 
                });
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    return (

        <Stack
            spacing={2}
            mt={2}
            mb={2}
            direction={'column'}
            component={'form'}
            onSubmit={save.bind(this)}
        >
            <FormSearchPerson
                person={customer?.person}
                setPerson={person => {
                    setCustomer(prev => {
                        return {
                            ...prev,
                            person: { ...person }
                        }
                    })
                }}
                loadList={(searchPatch, search, searchTable, searchColumn) => personService.searchFor(searchPatch, search, searchTable, searchColumn)}
                modal={<ModalFormUser label={<IconPlus></IconPlus>}></ModalFormUser>}
            >
            </FormSearchPerson>
            <TextField
                value={customer?.segment}
                onChange={e => setCustomer(prev => {
                    return {
                        ...prev,
                        segment: e.target.value
                    }
                })}
                sx={{ width: "100%" }}
                fullWidth
                label="Segmento"
                placeholder="Segmento"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 45 }}
            >
            </TextField>
            <LoadingButton
                type="submit"
                variant="outlined"
                loading={buttonLoading}
                fullWidth
            >{"Salvar"}</LoadingButton>
        </Stack >

    );
}