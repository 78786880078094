import { Grid } from "@mui/material";
import React from "react";
import DialogBase from "ui-component/populated/base/DialogBase";
import CustomFormResponseView from "views/customForm/response/CustomFormResponseView";

/**
 * @param {Object} props
 * @param {number} props.idCustomForm
 * @param {number} props.idCustomFormResponse
 * @param {React.ReactNode} props.buttonOpenModal
 */
export default function DialogCustomFormResponse({
    idCustomForm,
    idCustomFormResponse,
    callbackSave,
    callbackLoading,
    buttonOpenModal,
    title
}) {
    

    return <DialogBase title={title} buttonOpenModal={buttonOpenModal}>
        <Grid
            container 
            sx={{
                '& > * > * ': { width: '100%' },
                marginTop : '8px'
            }}
        >
            <Grid item xs={12} p={5}>
                <CustomFormResponseView idCustomForm={idCustomForm} idCustomFormResponse={idCustomFormResponse} callbackSaveFormResponse={callbackSave} callbackLoadingFormResponse={callbackLoading}></CustomFormResponseView>
            </Grid>
        </Grid>
    </DialogBase>;
}