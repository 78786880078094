import Teacher from "class/person/teacher/Teacher";
import TeacherService from "service/verbify/employee/teacher/TeacherService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {Teacher} props.teacher
 * @param {(student:Teacher) => void} props.setTeacher
 * @returns 
 */
export default function FormSearchTeacher({
    teacher,
    setTeacher,
    required
}) {
    const teacherService = new TeacherService();
    return <FormSearch
        required={required}
        searchPatch={'/buscar/professor'}
        searchTable={'teacher'}
        searchColumn={'academicTitle'}
        label="Professor"
        item={teacher}
        setItem={setTeacher}
        loadList={teacherService.searchFor}
        valueExtractor={
            /**
             * @param {Teacher} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Teacher} option 
             * @returns 
            */
            (option) => !option || option.id <= 0 ? '' : `${option.id} - ${option.academicTitle}`
        }
    ></FormSearch>
}