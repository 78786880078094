import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import ClasseModule from "class/secretary/classe/ClasseModule";
import DataGridListFrequencySheet from "./DataGridLIstFrequencySheet";
/**
 * @param {Object} param0
 * @param {string|React.ReactNode} param0.label
 * @param {ClasseModule} param0.classeModuleData 
 * @returns {React.JSX.Element}
 */
export default function ModalFormFrequencySheet({ 
    label,
    stateOpen,
    classeModuleData
}) {
    const openState = useState(false);
    const [ open, setOpen ] = stateOpen ?? openState;
    const stateClasseModule = useState(new ClasseModule(classeModuleData))
    return (
        <ModalForm
            setOpen={setOpen}
            open={open}
            title={"Folhas de Frequencia"}
            form={<>
                <DataGridListFrequencySheet
                    stateClasseModule={stateClasseModule}
                ></DataGridListFrequencySheet>
            </>}
            buttonLabel={label}
            width={'md'}
        >
        </ModalForm>
    );
}