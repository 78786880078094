import Person from "class/person/Person";
import BaseService from "service/BaseService";
import verbify from "../../config";

export default class CustomerService extends BaseService {
    static baseUrl = "cliente";
    constructor() {
        super(CustomerService.baseUrl);
    }
    /**
     * @param {string} searchPatch 
     * @param {string} search // word or phrase
     * @param {string} searchTable 
     * @param {string} searchColumn 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    searchFor(searchPatch, search, searchTable, searchColumn) {
        return verbify.get(`${searchPatch ?? "/buscar/cliente"}/${search}/${searchTable ?? "person"}/${searchColumn ?? "name"}`)
    }
    /**
     * @param {Person} person 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    searchByPerson(person) {
        return verbify.get(`${this.baseUrl}-pessoa?person=${person?.id}`);
    }
}