export const DataCustomFormType = [
    { label: "text", id: 1 },
    { label: "multline", id: 12, multiline: true },
    { label: "checkbox", id: 2, select: true},
    { label: "color", id: 3 },
    { label: "date", id: 4 },
    { label: "datetime-local", id: 5 },
    { label: "email", id: 6 },
    { label: "number", id: 7, numeric: true },
    { label: "password", id: 8 },
    { label: "tel", id: 9 },
    { label: "time", id: 10 },
    { label: "url", id: 11 },
]