import ObjectResponseList from "class/ObjectResponseList";
import Categoria from "class/omie/geral/Categoria";
import LeiComplementar116 from "class/omie/servico/LeiComplementar116";
import TipoTributacao from "class/omie/servico/TipoTributacao";
import CategoriaService from "service/verbify/omie/geral/CategoriaService";
import TipoTributacaoService from "service/verbify/omie/geral/servico/TipoTributacaoService ";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {TipoTributacao} props.tipoTributacao
 * @param {React.Dispatch<React.SetStateAction<TipoTributacao>>} props.setTipoTributacao
 * @param {string|undefined|null} props.label
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<TipoTributacao[], any> | null} props.loadList
 * @param {boolean|null|undefined} props.required
 * @returns 
 */
export default function FormSearchTipoTributacao({
    tipoTributacao,
    setTipoTributacao,
    label,
    loadList,
    required
}) {
    return <FormSearch
        required={required}
        label={label ?? 'Tributação dos Serviços'}
        item={tipoTributacao}
        setItem={setTipoTributacao}
        loadList={loadList ? loadList : (searchPatch, search, searchTable, searchColumn) => {
            const tipoTributacaoService = new TipoTributacaoService();
            return tipoTributacaoService.list();
        }}
        minimumLength={0}
        fullWidth={true}
        valueExtractor={
            /**
             * @param {TipoTributacao} option 
             * @returns {string}
             */
            (option) => option?.cIdTrib
        }
        textExtractor={
            /**
             * @param {TipoTributacao} option 
             * @returns {string}
            */
            (option) => option?.cIdTrib?.length > 0 ? (option?.cDescricao ?? '') : ''
        }
    ></FormSearch>;
}