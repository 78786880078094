import Person from "class/person/Person";
import Customer from "../customer/Customer";


export default class CustomerManager {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {int}
     * @public
     */
    type = 0;
    /**
     * @type {Customer}
     * @public
     */
    customer = new Customer();
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
}