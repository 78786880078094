import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ProductService from "service/verbify/product/ProductService";

import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ProposalService from "service/verbify/proposal/ProposalService";
import Proposal from "class/proposal/Proposal";
import InputSearchPaymentPeriod from "./InputSearch/InputSearchPaymentPeriod";
import ProposalPaymentPeriodService from "service/verbify/proposal/data/ProposalPaymentPeriodService";
import { IconPlus } from "@tabler/icons";
import FormSearchProduct from "../opportunity/product/FormSearchProduct";
import FormSearchAccount from "../payment/bank/account/FormSearchAccount";
import ModalFormOpportunityProposalClasseModule from "../opportunity/proposal/classe/module/ModalFormOpportunityProposalClasseModule";
import ModalFormProduct from "../product/ModalFormProduct";
import FormSeachClasse from "../secretery/classe/FormSeachClasse";

/**
 * @param {{id?:number, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormProposal({ id, stateProposal, setItem, callback, setOpenModal }) {
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */

    const state = useState(new Proposal());
    const [proposal, setProposal] = stateProposal ?? state;

    const proposalService = new ProposalService();
    const productService = new ProductService();

    const [paymentPeriod, setPaymentPeriod] = useState({});
    const proposalPaymentPeriodService = new ProposalPaymentPeriodService();


    function save(evento) {
       evento.preventDefault();
         setButtonLoading(true);

        const isUpdate = proposal?.id > 0;

        (isUpdate ? proposalService.update(proposal) : proposalService.insert(proposal))
            .then((response) => {
                const data = {
                    ...proposal,
                    id: response?.data?.model?.id
                }
                if (callback) callback(isUpdate ? JSON.parse(JSON.stringify(proposal)) : data)
                if (setItem) setItem(isUpdate ? JSON.parse(JSON.stringify(proposal)) : data)
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                if (setOpenModal) setOpenModal(false);
                setButtonLoading(false);
            });
    }

    useEffect(function () {
        //Carraga os dados da languagem caso tenha o id
        if (proposal?.id != undefined && proposal?.id != null && isNumber(proposal?.id)){
            setProposal(proposal)
            setPaymentPeriod(proposalPaymentPeriodService.search("id", proposal?.paymentPeriod))
        }

        if ((id ?? 0) > 0) {
            proposalService
                .search(id)
                .then(response => {
                    if (response.data.paymentPeriod) setPaymentPeriod(proposalPaymentPeriodService.search("id", response.data.paymentPeriod));
                    setProposal(response.data);
                })
                .catch(ex => { })
                .finally();
        }
    }, []);

    return (
        <Grid
            component={'form'}
            onSubmit={save.bind(this)}
            mb={3}
            mt={3}
            item
        >
            <Stack
                gap={1.5}
                mb={2}
                direction={'column'}
                justifyContent={'space-between'}
            >
                <Box width={"100%"}>
                    <FormSearchProduct
                        product={proposal?.product}
                        setProduct={product => setProposal(prev => {
                            return{
                                ...prev,
                                product: product
                            }
                        })}
                        loadList={(searchPatch, search, searchTable, searchColumn) => productService.searchFor(searchPatch, search, searchTable, searchColumn)}
                        modal={<ModalFormProduct label={<IconPlus></IconPlus>}></ModalFormProduct>}
                    >
                    </FormSearchProduct>
                </Box>
                <Box width={"100%"}>
                    <TextField
                        sx={{ width: "100%" }}
                        multiline
                        rows={4}
                        fullWidth
                        value={proposal?.description || proposal?.product?.description || ''}
                        onChange={(e) =>
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    description: e.target.value
                                }
                            })
                        }
                        label="Descrição"
                        placeholder="Descrição"
                        variant="outlined"
                        type="text"
                        inputProps={{ maxLength: 1000 }}
                    >
                    </TextField>
                </Box>
                <Box width={"100%"}>
                    <InputSearchPaymentPeriod
                        item={paymentPeriod?.id > 0 ? paymentPeriod : null}
                        setItem={
                            (paymentPeriod) => {
                                setPaymentPeriod(paymentPeriod)
                                setProposal(prev => {
                                    return {
                                        ...prev,
                                        paymentPeriod: paymentPeriod?.id
                                    }
                                });
                            }
                        }
                        loadList={(searchPatch, search, searchTable, searchColumn) => proposalPaymentPeriodService.searchFor(searchPatch, search, searchTable, searchColumn)}
                    ></InputSearchPaymentPeriod>
                </Box>
                <Box width={"100%"}>
                    <TextField
                        sx={{ width: "100%" }}
                        fullWidth
                        value={proposal?.paymentPeriodInterval || proposal?.product?.paymentPeriodInterval || 0}
                        onChange={(e) =>
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    paymentPeriodInterval: e.target.value
                                }
                            })
                        }
                        label="Intervalo entre os pagamentos"
                        placeholder="Intervalo entre os pagamentos"
                        variant="outlined"
                        type="number"
                        inputProps={{ maxLength: 45 }}
                    >
                    </TextField>
                </Box>
                <Box width={"100%"}>
                    <TextField
                        sx={{ width: "100%" }}
                        fullWidth
                        value={proposal?.qdtInstallment || proposal?.product?.qdtInstallment || 0}
                        onChange={(e) =>
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    qdtInstallment: e.target.value
                                }
                            })
                        }
                        label="Quantidade de parcelas"
                        placeholder="Quantidade de parcelas"
                        variant="outlined"
                        type="number"
                        inputProps={{ maxLength: 45 }}
                    >
                    </TextField>
                </Box>
                <Box width={"100%"}>
                    <TextField
                        sx={{ width: "100%" }}
                        fullWidth
                        value={proposal?.totalValue}
                        onChange={(e) =>
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    totalValue: e.target.value
                                }
                            })
                        }
                        label="Valor Total por Estudante"
                        placeholder="Valor Total por Estudante"
                        variant="outlined"
                        type="number"
                        inputProps={{ maxLength: 45 }}
                    >
                    </TextField>
                </Box>
                <Box width={"100%"}>
                    <DateField
                        sx={{ width: "100%" }}
                        label={"Validade da proposta"}
                        value={dayjs(proposal.proposalValidity)}
                        onChange={(value) =>
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    proposalValidity: `${value.$y}-${value.$M + 1}-${value.$D}`
                                }
                            })
                        }
                    >
                    </DateField>
                </Box>
                <Box width={"100%"}>
                    <FormSearchAccount
                        account={{
                            nCodCC: proposal.fkContaOmieId
                        }}
                        setAccount={(conta) => {
                            setProposal((prev) => {
                                return {
                                    ...prev,
                                    fkContaOmieId: conta.nCodCC
                                };
                            });
                        }}
                    ></FormSearchAccount>
                </Box>
                <Box width={"100%"}>
                    <FormSeachClasse
                        label="Classe"
                        classe={proposal.classe}
                        setClasse={(classe) => {
                            setProposal(prev => {
                                return {
                                    ...prev,
                                    classe: classe
                                };
                            });
                        }}
                    ></FormSeachClasse>
                </Box>
            </Stack>
            <LoadingButton
                type="submit"
                variant="contained"
                loading={buttonLoading}
                fullWidth
            >{"Adicionar"}</LoadingButton>
        </Grid>
    );
}