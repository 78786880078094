import { Box, Divider, IconButton, Typography } from "@mui/material";
import FormFrequencySheet from "./FormFrequencySheet";
import DialogBase from "ui-component/populated/base/DialogBase";
import { useEffect, useState } from "react";
import { IconEye, IconViewfinder } from "@tabler/icons";
import FrequencySheet from "class/secretary/frequency/FrequencySheet";
import Item from "class/schedule/Item";
import DataGridFrequencySheet from "./DataGridFrequencySheet";
import FrequencySheetService from "service/verbify/secretary/frequency/FrequencySheetService";
import ObjectResponseList from "class/ObjectResponseList";
import ClasseModule from "class/secretary/classe/ClasseModule";

/**
 * 
 * @param {Object} param0
 * @param {FrequencySheet} param0.frequencySheet
 * @param {Item} param0.item
 * @param {number} param0.id
 * @returns 
 */
export default function DialogFrequencySheet({
    dialogStateParam,
    frequencySheet,
    item,
    id
}) {
    const dialogState = useState(false);
    const [dialogAgenda, setDialogAgenda] = dialogStateParam ?? dialogState;

    const stateFrequencySheet = useState(frequencySheet ?? new FrequencySheet());

    const frequencySheetService = new FrequencySheetService();
    const stateListFrequencySheetPendent = useState(new ObjectResponseList());

    const [classeModule, setClasseModule] = useState(new ClasseModule());

    const title = stateFrequencySheet[0] ? `${stateFrequencySheet[0].scheduleItem.schedule.title} - ${(new Date(stateFrequencySheet[0].date).toLocaleDateString())} - (${stateFrequencySheet[0].start} até ${stateFrequencySheet[0].end})` : `${item?.title || item?.schedule?.title} - ${new Date(item?.startDate).toLocaleDateString()} (${item?.startTime} até ${item?.endTime})`;
    const data = stateFrequencySheet[0] ? { ...stateFrequencySheet[0].scheduleItem, startDate: new Date(stateFrequencySheet[0].date) } : item
    
    useEffect(() => {
        if (id > 0) {
            frequencySheetService.search(id)
                .then(data => {
                    if (data?.data?.id > 0) {
                        stateFrequencySheet[1](prev => {
                            return {
                                ...prev,
                                ...data?.data
                            }
                        })
                        setClasseModule(stateFrequencySheet?.classeModuleTeacher?.classeModule)
                    }
                })
        }
        else {
            stateFrequencySheet[1](frequencySheet)
        }        
    }, [id])



    return <DialogBase
        stateOpen={[dialogAgenda, setDialogAgenda]}
        buttonOpenModal={<IconButton>
            <IconEye></IconEye>
        </IconButton>}
        title={title}
    >
        <Box
            sx={{
                width: "95%",
                margin: "20px auto"
            }}
        >
            <FormFrequencySheet
                itemData={data}
                climbDiceClasseModule={(classeModuleData) => {
                    setClasseModule(prev => {
                        return {
                            ...prev,
                            ...classeModuleData
                        }
                    })
                }}
            />
            <Divider sx={{ mt: 2, borderColor: "white" }}></Divider>
            <Typography variant="h3">Folhas de Frequencia para reposição</Typography>
            <Divider sx={{ mt: 1, borderColor: "white" }}></Divider>
            {
                classeModule?.id > 0 && <DataGridFrequencySheet
                    height={400}
                    stateList={stateListFrequencySheetPendent}
                    loadList={(page) => frequencySheetService.vListFrequencySheetByClasse(page, classeModule?.id, 0)}
                ></DataGridFrequencySheet>
            }

        </Box>
    </DialogBase>
}
