import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import Student from "class/person/student/Student";
import { useEffect, useState } from "react";
import StudentService from "service/verbify/user/student/StudentService";
import FormSearchPerson from "../person/FormSearchPerson";

/**
 * @param {{id?:number, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormStudent({ id, person, setOpenModal, setItem }) {
    const studentService = new StudentService();
    /**
     * @type {[Student, (student:Student) => void]}
     */
    const [student, setStudent] = useState(new Student());
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento) {
        evento.preventDefault();

        setButtonLoading(true);

        const isUpdate = id != undefined && id != null;
        const newStudent = {
            ...student,
            person : person
        };

        (isUpdate ? studentService.update(newStudent) : studentService.insert(newStudent))
            .then((response) => {
                setOpenModal(false);
                const newStudentUpdate = isUpdate ? newStudent : {
                    ...newStudent,
                    id : response.data.model.id
                };

                setStudent(newStudentUpdate);
                setItem(newStudentUpdate);
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }
    
    useEffect(()=>{
        console.log("Estudante",student);
    }, [student]);

    useEffect(function () {
        //Carraga os dados da languagem caso tenha o id
        if (id != undefined && id != null && isNumber(id))
            studentService
                .search(id)
                .then(response => {
                    console.log(response.data);
                    setStudent(response.data)
                })
                .catch(ex => { })
                .finally();
    }, []);
    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >

                {/* <FormSearchPerson></FormSearchPerson> */}
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Stack>
        </form>
    );

}
