import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, TextareaAutosize, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import Absence from 'class/secretary/frequency/Absence';
import AbsenceService from 'service/verbify/secretary/frequency/AbsenceService';
import FormSearchJustificationType from './justificationType/FormSearchJustificationType';
import InputFileBase from 'ui-component/populated/base/InputFileBase';
import JustificationType from 'class/secretary/frequency/absence/JustificationType';
import DataJustificationType from 'data/secretary/frequency/absence/DataJustificationType';
import StudentModule from 'class/person/student/StudentModule';
import FormSearchAbsenceType from 'ui-component/populated/absenceType/FormSearchAbsenceType';
import FormMakeupClass from 'ui-component/populated/makeupClass/FormMakeupClass';
import MakeupClass from 'class/makeupClass/MakeupClass';
import FrequencySheet from 'class/secretary/frequency/FrequencySheet';

/**
 * @param {Object} props
 * @param {[Absence, React.Dispatch<React.SetStateAction<Absence>>]} props.stateAbsence
 * @param {StudentModule} props.classeModuleStudent
 * @param {FrequencySheet} props.frequencySheet
 * @param {(Absence) => void} props.callback
 */
export default function FormAbsence({
    stateAbsence,
    classeModuleStudent,
    frequencySheet,
    callback,
    sx
}) {
    const absenceService = new AbsenceService({ frequencySheet });

    /** @type {[boolean, React.Dispatch<boolean>]} */
    const [buttonLoading, setButtonLoading] = useState(false);
    /** @type {[Absence, React.Dispatch<Absence>]} */
    const state = useState(new Absence())
    const [absence, setAbsence] = stateAbsence ?? state;

    // const [makeupClass, setMakeupClass] = useState(new MakeupClass());

    function save(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        // const newItem = { ...absence };
        // newItem.justificationForAbsence = Object.assign(new JustificationType(), newItem.justificationForAbsence);

        const isUpdate = isNumber(absence.id) && absence.id > 0;

        setButtonLoading(true);

        (isUpdate ? absenceService.update(absence) : absenceService.insert(absence))
            .then(response => {

                let data = absence;

                if (!isUpdate) data = {
                    ...absence,
                    id: response.data?.model?.id
                };
                setAbsence(data)
                if (callback) callback(data)
            }).catch(ex => console.log(ex))
            .finally(() => {
                setButtonLoading(false);
            });
    }

    // useEffect(() => {
    //     setMakeupClass(prev => {
    //         return {
    //             ...prev,
    //             classeModuleStudent: absence?.classeModuleStudent
    //         }
    //     })
    // }, [absence.classeModuleStudent])

    // useEffect(() => {
    //     setMakeupClass(prev => {
    //         return {
    //             ...prev,
    //             missedClassFF: absence?.frequencySheet
    //         }
    //     })
    // }, [absence.frequencySheet])

    useEffect(() => {
        if(classeModuleStudent?.id > 0) absenceService.search(classeModuleStudent.id).then(response => {
            if (response.data.id != null) {
                const newAbsence = Object.assign(new Absence(), response.data);
                setAbsence(newAbsence);
            } else {
                const newAbsence = new Absence();
                newAbsence.frequencySheet = frequencySheet;
                newAbsence.classeModuleStudent = classeModuleStudent;
                setAbsence(newAbsence);
            }
        }).catch((ex) => console.log(ex));
        else if(frequencySheet?.id > 0 && frequencySheet.status == 0 && classeModuleStudent?.id == null) {
            console.log(frequencySheet, "FFSHETT");
            absenceService.showByFrequencySheet(frequencySheet.id).then(resp => {
                if(resp?.data?.id > 0) setAbsence(prev => {
                    return {
                        ...prev,
                        ...resp.data,
                        frequencySheet
                    }
                })
                else {
                    setAbsence(prev => {
                        const data = new Absence()
                        data.frequencySheet = frequencySheet
                        return {
                            ...prev,
                            ...resp.data,
                            frequencySheet
                        }
                    })
                }
            })
            
        }
    }, []);

    return <Box
        sx={sx}
        component={"form"}
        onSubmit={save.bind(this)}
    >
        <Grid container spacing={1} sx={{
            '& > * > * ': { width: '100%' },
            padding: 1
        }}>
            <Grid item xs={12}>
                <FormSearchAbsenceType
                    label={"Buscar Motivo"}
                    absenceType={absence.absenceType}
                    setAbsenceType={(absenceType) => {
                        setAbsence((prev) => {
                            prev.absenceType = absenceType;
                            console.log("ABSENCE", prev);
                            return {
                                ...prev
                            }
                        });
                    }}
                    required
                />
            </Grid>
            {
                // Boolean(absence?.absenceType?.allowsReplacement) === true ? <FormMakeupClass stateMakeupClass={[makeupClass, setMakeupClass]}></FormMakeupClass> : <></>
            }
            {/* <Grid item xs={12}>
                <FormSearchJustificationType
                    justificationType={absence.justificationForAbsence}
                    setJustificationType={(justificationType) => {
                        setAbsence((prev) => {
                            prev.justificationForAbsence = justificationType;
                            return {
                                ...prev
                            }
                        });
                    }}
                    required
                />
            </Grid> */}

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Motivo"
                    placeholder="Motivo"
                    variant="outlined"
                    value={absence?.description ?? ''}
                    onChange={(e) => setAbsence(prev => {
                        return {
                            ...prev,
                            description: e.target.value
                        };
                    })}
                    inputProps={{ maxLength: 500 }}
                ></TextField>
            </Grid>

            <Grid item xs={12}>
                <InputFileBase file={absence.justificationForAbsenceFile} setFile={(file) => {
                    setAbsence((prev) => {
                        return {
                            ...prev,
                            justificationForAbsenceFile: file.file
                        }
                    });
                }}></InputFileBase>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h2'>Folha de Frequencia de Reposição</Typography>
                <TextField
                    fullWidth
                    label="Reposição"
                    placeholder="Reposição"
                    variant="outlined"
                    type='number'
                    value={absence?.description ?? ''}
                    onChange={(e) => setAbsence(prev => {
                        return {
                            ...prev,
                            resetFrequencySheet: {
                                ...prev.resetFrequencySheet,
                                id: e.target.value
                            }
                        };
                    })}
                    inputProps={{ maxLength: 500 }}
                ></TextField>
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Grid>
        </Grid>
    </Box>;
}