import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Switch, Typography, useTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import { Box, Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Country from "class/Country";
import Person from "class/person/Person";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CountryService from "service/verbify/country/CountryService";
import PersonService from "service/verbify/user/person/PersonService";
import FormSearchCountry from "ui-component/FormSearchCountry";
import CardDocument from "ui-component/populated/document/CardDocument";
import ModalFormDocument from "ui-component/populated/document/ModalFormDocument";
import ModalDocument from "views/profiles/Modals/ModalDocument";
import FormSearchUser from "../FormSearchUser";
import User from "class/person/user/User";
import UserService from "service/verbify/user/UserService";
import CardAddress from "ui-component/populated/address/CardAddress";
import ModalFormAddress from "ui-component/populated/address/ModalFormAddress";
import { Link } from "react-router-dom";

/**
 * @param {{personData?:Person, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormPerson({ statePerson, personData, userData, setOpenModal, setItem }) {
    const personService = new PersonService();
    const countryService = new CountryService();
    const userService = new UserService();
    /**
     * @type {[Person, (person:Person) => void]}
     */
    const state = useState(userData ? { ...personData, user: userData } : personData ?? new Person());
    const [person, setPerson] = statePerson ?? state

    /**
     * @type {[Country, (country:Country) => void]}
     */
    const [country, setCountry] = useState(new Country);

    /**
     * @type {[User, (user:User) => void]}
     */
    const [user, setUser] = useState(new User());

    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evento
     * @returns {void}
     */
    function save(evento, personData) {
        if (evento) {
            evento.preventDefault();
            evento.stopPropagation();
        }

        const data = personData ?? person;

        setButtonLoading(true);

        const isUpdate = data?.id != undefined && data?.id != null;

        (isUpdate ? personService.update(data) : personService.insert(data))
            .then((response) => {
                if (setOpenModal) setOpenModal(false)
                if (setItem) setItem(isUpdate ? data : { ...data, id: response.data.model.id } );
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                setButtonLoading(false);
            });
    }

    const setFederalDocument = (document) => {
        setPerson(prev => {
            return {
                ...prev,
                federalDocument: { ...prev.federalDocument, ...document() }
            }
        })
    }
    const saveFederalDocument = (id) => {
        const personData = { ...person };
        personData.federalDocument.id = id
        save(false, personData);
    }
    const setStateDocument = (document) => {
        setPerson(prev => {
            return {
                ...prev,
                stateDocument: { ...prev.stateDocument, ...document() }
            }
        })
    }

    const saveAddress = (id) => {
        const personData = { ...person };
        personData.address.id = id
        save(false, personData);
    }

    const saveStateDocument = (id) => {
        const personData = { ...person };
        personData.stateDocument.id = id
        save(false, personData);
    }

    useEffect(() => {
        if (person?.user?.id !== user?.id) setUser(person?.user)
    }, [person?.user])

    useEffect(() => {
        console.log(country);
        if (person?.countryOfOrigin !== country?.code) {
            const value = countryService.search('code', person?.countryOfOrigin)
            setCountry(value)
        }
    }, [person?.countryOfOrigin])

    return (<>
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                marginTop={1}
            >
                <Grid container xl={12} spacing={1.5}>
                    <Grid item md={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '100%',
                            height: '100%'
                        }}>
                            {
                                (person?.user?.id === null || person?.user?.id === undefined) && <FormSearchUser
                                    user={person?.user}
                                    setUser={user => {
                                        setPerson((prev) => {
                                            return {
                                                ...prev,
                                                user: { ...prev?.user, ...user }
                                            }
                                        })
                                        setUser(user)
                                    }}
                                    loadList={((searchPatch, search, searchTable, searchColumn) => userService.searchFor(searchPatch, search, searchTable, searchColumn))}
                                    required={true}
                                >
                                </FormSearchUser>
                            }
                            {
                                (user?.id > 0) && <Typography variant="h4">{person?.user?.id} - {person?.user?.email}</Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xl={6}>
                        <FormControl>
                            <FormLabel>Tipo</FormLabel>
                            <RadioGroup
                                value={person?.type}
                                sx={{
                                    flexDirection: 'row !important'
                                }}
                                onChange={(e) => setPerson(prev => {
                                    return {
                                        ...prev,
                                        type: e.target.value
                                    }
                                })}
                            >
                                <FormControlLabel value={0} control={<Radio />} label={"Pessoa Fisica"} />
                                <FormControlLabel value={1} control={<Radio />} label={"Pessoa Juridica"} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            fullWidth
                            value={person?.name ?? ''}
                            onChange={(e) => setPerson(prev => {
                                return {
                                    ...prev,
                                    name: e.target.value
                                }
                            })}
                            label={person?.type == 0 ? 'Nome' : 'Razão Social'}
                            placeholder={person?.type == 0 ? 'Nome' : 'Razão Social'}
                            variant="outlined"
                            type="text"
                            inputProps={{ maxLength: 45 }}
                        ></TextField>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            fullWidth
                            value={person?.nickName ?? ''}
                            onChange={(e) => setPerson(prev => {
                                return {
                                    ...prev,
                                    nickName: e.target.value
                                }
                            })}
                            label={person?.type == 0 ? 'Sobrenome' : 'Nome Fantasia'}
                            placeholder={person?.type == 0 ? 'Sobrenome' : 'Nome Fantasia'}
                            variant="outlined"
                            type="text"
                            inputProps={{ maxLength: 45 }}
                        ></TextField>
                    </Grid>
                    <Grid item md={6}>
                        <FormSearchCountry
                            country={country}
                            loadList={(searchPatch, search, searchTable, searchColumn) => countryService.searchFor(searchPatch, search, searchTable, searchColumn)}
                            setCountry={country => {
                                setCountry(country)
                                setPerson((prev) => {
                                    return {
                                        ...prev,
                                        countryOfOrigin: country?.code
                                    }
                                })
                            }}
                            required
                        >
                        </FormSearchCountry>
                    </Grid>
                    <Grid item md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DatePicker
                                sx={{ width: "100%" }}
                                label={person?.type == 0 ? 'Data de nascimento' : 'Data de abertura'}
                                placeholder={person?.type == 0 ? 'Data de nascimento' : 'Data de abertura'}
                                value={dayjs(person?.dateOfBirth) || new Date()}
                                onChange={(e) => setPerson(prev => {
                                    const date = `${e.$y}/${e.$M + 1}/${e.$D}`;
                                    return {
                                        ...prev,
                                        dateOfBirth: date
                                    }
                                })}
                            >
                            </DatePicker>
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid container spacing={2} xl={12}>
                    <Grid item md={12}>
                        <Divider textAlign="left">Documentos</Divider>
                    </Grid>
                    <Grid item md={6} alignItems={'center'} justifyContent={'center'} >
                        {
                            person?.id > 0 && person?.federalDocument?.id > 0 ?
                                <CardDocument
                                    stateDocument={[person?.federalDocument, setFederalDocument]}
                                    callback={saveFederalDocument}
                                    description={<>
                                        <Typography variant="h3">
                                            Baixe seu {person?.type == 0 ? 'CPF' : 'CNPJ'} clicando 
                                            {
                                                person?.type == 0
                                                    ?
                                                    <a href={'https://servicos.receita.fazenda.gov.br/Servicos/CPF/ImpressaoComprovante/ConsultaImpressao.asp'} target="_blank" rel="noreferrer"> aqui </a>
                                                    :
                                                    <a href={'https://solucoes.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp'} target="_blank" rel="noreferrer"> aqui </a>

                                            }
                                        </Typography>
                                    </>}
                                >
                                </CardDocument>
                                :
                                <ModalFormDocument
                                    label={person?.type == 0 ? 'Adicinar CPF' : 'Adicinar CNPJ'}
                                    description={<>
                                        <Typography variant="h3">
                                            Baixe seu {person?.type == 0 ? 'CPF' : 'CNPJ'} em 
                                            {
                                                person?.type == 0
                                                    ?
                                                    <a href={'https://servicos.receita.fazenda.gov.br/Servicos/CPF/ImpressaoComprovante/ConsultaImpressao.asp'} target="_blank" rel="noreferrer"> aqui </a>
                                                    :
                                                    <a href={'https://solucoes.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp'} target="_blank" rel="noreferrer"> aqui </a>

                                            }
                                        </Typography>
                                    </>}
                                    stateDocument={[person?.federalDocument, setFederalDocument]}
                                    callback={saveFederalDocument}
                                ></ModalFormDocument>
                        }
                    </Grid>
                    <Grid item md={6} alignItems={'center'} justifyContent={'center'} >
                        {
                            person?.id > 0 && person?.stateDocument?.id > 0 ?
                                <CardDocument
                                    stateDocument={[person?.stateDocument, setStateDocument]}
                                    callback={saveStateDocument}
                                >
                                </CardDocument>
                                :
                                <ModalFormDocument
                                    label={person?.type == 0 ? 'Adicinar RG' : 'Adicinar Inscrição Estadual'}
                                    stateDocument={[person?.stateDocument, setStateDocument]}
                                    callback={saveStateDocument}
                                ></ModalFormDocument>
                        }
                    </Grid>
                    <Grid item md={12}>
                        <Divider textAlign="left">Endereço</Divider>
                    </Grid>
                    <Grid item md={6} alignItems={'center'} justifyContent={'center'} >
                        {
                            person?.id > 0 && person?.address?.id > 0 ?
                                <CardAddress
                                    stateAddress={[person?.address, (address) => {
                                        setPerson(prev => {
                                            return {
                                                ...prev,
                                                address: { ...prev.address, ...address }
                                            }
                                        })
                                    }]}
                                    callback={saveAddress}
                                // callback={{func: saveDocument, key: "stateDocument"}}
                                >
                                </CardAddress>
                                :
                                <ModalFormAddress
                                    label={`Adicionar Endereço`}
                                    stateAddress={[person?.address, (address) => {
                                        setPerson(prev => {
                                            return {
                                                ...prev,
                                                address: { ...prev.address, ...address }
                                            }
                                        })
                                    }]}
                                    callback={saveAddress}
                                ></ModalFormAddress>
                        }
                    </Grid>
                </Grid>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
                {/* <FormAddress /> */}
            </Stack>
        </form>
    </>

    );

}
