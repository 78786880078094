import Person from "class/person/Person";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {Person} props.person
 * @param {(person:Person) => void} props.setPerson
 * @returns 
 */
export default function FormSearchPerson({
    person,
    setPerson,
    loadList,
    required,
    disabled,
    modal
}) {
    return (
        <FormSearch
            modal={modal}
            disabled={disabled}
            required={required}
            searchPatch={'/buscar/pessoa'}
            searchTable={'person'}
            searchColumn={'name'}
            label={"Buscar pessoa"}
            item={person}
            setItem={setPerson}
            loadList={loadList}
            valueExtractor={
                /**
                 * @param {Pessoa} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {Pessoa} option 
                 * @returns 
                */
                (option) => !option || option.id == undefined ? '' : `${option?.name} (${option?.user?.email})`
            }
        ></FormSearch>
    )
}