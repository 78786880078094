import CustomForm from "../CustomForm";

export default class CustomFormQuestion {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = "";
    /**
     * @type {string}
     * @public
     */
    description = "";
    /**
     * @type {number}
     * @public
     */
    type = 0;
    /**
     * @type {string}
     * @public
     */
    options = "";
    /**
     * @type {string}
     * @public
     */
    minValue = null;
    /**
     * @type {string}
     * @public
     */
    maxValue = null;
    /**
     * @type {CustomForm}
     * @public
     */
    customForm = new CustomForm();
}