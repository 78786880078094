import FrequencySheet from "../FrequencySheet";

export default class Assessment {
    /** @type {number} */
    id = null;

    /** @type {string} */
    title = '';

    /** @type {string} */
    description = '';

    /** @type {FrequencySheet} */
    frequencySheet = new FrequencySheet();
}