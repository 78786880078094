import { Box, MenuItem, TextField } from "@mui/material";
import CustomFormResponseQuestion from "class/customForm/question/response/CustomFormResponseQuestion";
import { useEffect, useState } from "react";

/**
 * 
 * @param {Object} param0 
 * @param {[CustomFormResponseQuestion, () => CustomFormResponseQuestion]} param0.stateCustomFormResponseQuestion
 * @returns 
 */
export default function InputQuestion({ stateCustomFormResponseQuestion, customFormResponseQuestionService, type, callback }) {
    const [customFormResponseQuestion, setCustomFormResponseQuestion] = stateCustomFormResponseQuestion;
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(customFormResponseQuestion?.value)
    }, [])

    const handleChange = (e) => {
        let valor = type.numeric ? parseInt(e.target.value) : e.target.value
        if (customFormResponseQuestion?.customFormQuestion?.maxValue && valor > customFormResponseQuestion?.customFormQuestion?.maxValue) valor = customFormResponseQuestion?.customFormQuestion?.maxValue;
        if (customFormResponseQuestion?.customFormQuestion?.minValue && valor < customFormResponseQuestion?.customFormQuestion?.minValue) valor = customFormResponseQuestion?.customFormQuestion?.minValue;
        setValue(valor);
    }
    
    const list = customFormResponseQuestion?.customFormQuestion?.options ? customFormResponseQuestion?.customFormQuestion?.options.split(";") : []

    return <Box width={'100%'}>
        <TextField sx={{ width: '100%' }}
            value={value}
            onChange={handleChange}
            onBlur={(e) => {
                setCustomFormResponseQuestion(prev => {
                    const data = {
                        ...prev,
                        value: value
                    };
                    if (callback) callback(data)
                    return data
                })
            }}
            placeholder={customFormResponseQuestion?.customFormQuestion?.title}
            // inputProps={{
            //     min: customFormResponseQuestion?.customFormQuestion?.minValue,
            //     max: customFormResponseQuestion?.customFormQuestion?.maxValue
            // }}
            type={type.multiline ? "text" : type?.label}
            multiline={type.multiline ? true : false}
            rows={4}
            select={type.select ? true : false}
        >
            {
                list.length > 0 && list.map((item, i) => {
                    return <MenuItem key={i} value={item} >
                        {item}
                    </MenuItem>
                })
            }
        </TextField>
    </Box>
}