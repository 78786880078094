export default class Country {
    /**
     * @type {number}
     * @public
     */
    ordem = '';
    /**
     * @type {string}
     * @public
     */
    code = '';
    /**
     * @type {number}
     * @public
     */
    name = '';
    /**
     * @type {string}
     * @public
     */
    acronym2 = '';

    /**
     * @type {string}
     * @public
     */
    acronym3 = '';
}