import { Box, Divider } from "@mui/material";
import CustomFormResponseQuestion from "class/customForm/question/response/CustomFormResponseQuestion";
import { useEffect } from "react";
import { useState } from "react";
import CustomFormTypeService from "service/verbify/customForm/question/data/CustomFormTypeService";
import BoxInputQuestion from "./BoxInputQuestion";
import CustomFormResponseQuestionService from "service/verbify/customForm/question/response/CustomFormResponseQuestionService";
import CustomFormQuestionService from "service/verbify/customForm/question/CustomFormQuestionService";
import ObjectRequest from "class/ObjectRequest";

export function Question({ customFormQuestion, responseQuestion, type, customFormResponseQuestionService }) {
    const [customFormResponseQuestion, setCustomFormResponseQuestion] = useState()
    const [question, setQuestion] = useState()

    useEffect(() => {
        setQuestion(customFormQuestion)
        setCustomFormResponseQuestion(responseQuestion)
    }, []);

    
    useEffect(() => {
        if (customFormResponseQuestion?.id === null || customFormResponseQuestion?.id === undefined) {
            setCustomFormResponseQuestion(prev => {
                return {
                    ...prev,
                    customFormQuestion: {
                        ...prev?.customFormQuestion,
                        ...question
                    }
                }
            })
        }
    }, [question]);

    return (
        <Box>
            <Divider textAlign="left" sx={{ mt: 3, mb: 0, border: 'none' }}>
            </Divider>
            {
                question?.id > 0
                &&
                <BoxInputQuestion
                    question={question}
                    customFormResponseQuestionService={customFormResponseQuestionService}
                    customFormQuestion={question}
                    stateCustomFormResponseQuestion={[customFormResponseQuestion, setCustomFormResponseQuestion]}
                    type={type}
                />
            }
        </Box>
    )
}

export default function ListQuestions({ stateCustomFormResponse }) {

    const page = new ObjectRequest()
    page.column = 'id'
    page.order = 'ASC'
    page.page = 1
    page.perPage = 10000

    const [customFormResponse] = stateCustomFormResponse
    const [customFormQuestions, setCustomFormQuestions] = useState([]);
    const [customFormResponseQuestions, setCustomFormResponseQuestions] = useState([]);

    const customFormTypeService = new CustomFormTypeService();
    const customFormResponseQuestionService = new CustomFormResponseQuestionService();
    const customFormQuestionService = new CustomFormQuestionService();

    useEffect(() => {
        if (customFormResponse?.id > 0) customFormResponseQuestionService.list(page, customFormResponse)
            .then(response => {
                setCustomFormResponseQuestions(response.data.rows)
            })

        if (customFormResponse?.customForm?.id > 0 && customFormResponse?.id > 0) {
            customFormQuestionService.list(page, customFormResponse.customForm)
                .then(response => {
                    setCustomFormQuestions(response.data.rows)
                })
        }
    }, [customFormResponse.id])

    return (
        <>
            {
                (customFormResponse?.id > 0 && customFormQuestions?.length > 0)
                &&
                customFormQuestions?.map((customFormQuestion, key) => {
                    const type = customFormTypeService.search('id', customFormQuestion.type)

                    const responseQuestion = 
                        customFormResponseQuestions?.length > 0 ? 
                            customFormResponseQuestions.find(response => customFormQuestion.id === response.customFormQuestion.id) 
                            ?? 
                            new CustomFormResponseQuestion() 
                        : new CustomFormResponseQuestion()

                        if(
                            (responseQuestion && responseQuestion?.customFormQuestion && responseQuestion?.customFormQuestion?.id === null) 
                            ||
                            (responseQuestion && responseQuestion?.customFormResponse && responseQuestion?.customFormResponse?.id === null)
                        ){
                            responseQuestion.customFormQuestion = customFormQuestion;
                            responseQuestion.customFormResponse = customFormResponse
                            console.log("IOWJNAÇAÇZALF",responseQuestion);
                        }

                        // if(responseQuestion?.customFormQuestion?.id === null){
                        //     responseQuestion?.customFormQuestion = 
                        // }
                        // if(responseQuestion?.customFormQuestion?.id === null) responseQuestion?.customFormQuestion = customFormQuestion
                        // if(responseQuestion?.customFormResponse?.id === null) responseQuestion?.customFormResponse = customFormResponse
                    
                    return <Question
                        customFormQuestion={customFormQuestion}
                        responseQuestion={responseQuestion}
                        customFormResponseQuestionService={customFormResponseQuestionService}
                        type={type}
                        key={key}
                    >
                    </Question>
                })
            }

        </>
    )
}