import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import { useNavigate, useRoutes } from 'react-router';
import { Box } from '@mui/material';
import { IconLogout } from '@tabler/icons';

export default function FormLogout() {
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        Cookies.remove("token");
        navigate("/login")
        setOpen(false);
    };

    return (
        <Box>
            <Button variant="outlined" onClick={handleClickOpen}>
                <IconLogout></IconLogout>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}

            >
                <DialogTitle variant='h2'>
                    {"Deseja realmente sair?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ao sair você sera redirecionado ao nosso portal de login, por onde devera acessar novamente com suas credenciais
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} autoFocus>Não</Button>
                    <Button onClick={handleClose}>Sim</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}