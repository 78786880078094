import { Button, Chip, Typography } from "@mui/material";
import { IconUpload } from "@tabler/icons";
import axios, { Axios } from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import UploadService from "service/verbify/upload/UploadService";

/**
 * @param {Object} props
 * @param {string} props.label
 * @param {(file:File) => void} props.setFile
 * @returns 
 */
export default function InputFileBase({
    label,
    setFile,
    file,
    labelFile
}) {
    /** @type {[ File|null, React.Dispatch<File|null>]} */
    const [stateFile, setStateFile] = useState(null);
    const uploadService = new UploadService()
    return <>
        <Button component="label" variant="contained" startIcon={<IconUpload />}>
            {label || 'Carregar arquivo'}
            <input
                type="file"
                hidden
                onChange={(ev) => {
                    if (ev.target.files) {
                        const [file] = ev.target.files;
                        const data = new FormData()
                        data.append('file', file, file.name);

                        setStateFile(file);

                        uploadService.insert(data)
                            .then(resp => {
                                const data = resp?.data
                                if (setFile) setFile(data);
                            })
                            .catch(erro => {
                                console.error(erro);
                            })
                            .finally(() => {
                                
                            })
                    }
                }}
            ></input>
        </Button>
        {(stateFile || file) && <Chip size={'small'} style={{ marginTop: 5, padding: 5 }} label={stateFile?.name ?? <Typography
                        variant="body2"
                        color="primary"
                        component={Button}
                        fullWidth
                        href={`https://api.companhiadeidiomas.com.br/api/upload?file=${encodeURIComponent(file)}`}
                        // href={`https://api.companhiadeidiomas.com.br/api/upload?file=${encodeURIComponent(file)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {labelFile ?? 'Ver arquivo'}
                    </Typography>} color="primary" variant="outlined"  onDelete={(ev) => {
            setStateFile(null);
            if (setFile) setFile(null);
        }} />}
    </>;
}