import PersonLanguage from 'class/person/language/PersonLanguage';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<PersonLanguage>
 */
export default class PersonLanguageService extends BaseService {
    static baseUrl = "pessoa/{person}/idioma-experiencia/{personLanguage}";
    /**
     * 
     * @param {PersonLanguage} personLanguage 
     */
    static hydrateURL(personLanguage) {
        let url = PersonLanguageService.baseUrl;
        if(personLanguage?.person?.id > 0) url = url.replace('{person}', (personLanguage?.person?.id ?? 0)?.toString())
        else return url

        if(personLanguage?.id > 0) url = url.replace('{personLanguage}', (personLanguage?.id ?? 0)?.toString())
        else url = url.replace('/{personLanguage}', "")

        return url
    }
    constructor(personLanguage) {
        super(PersonLanguageService.hydrateURL(personLanguage));
    }
}