import { number, string } from "prop-types";
import verbify from "./config";

export function createRequest() {
    return {
        buildUrl(baseUrl = [{ urlSegment: string, id: number }]) {
            let path = ''
            baseUrl.forEach(chunk => {
                path += `${chunk.urlSegment}/${chunk.id ?? ''}`
            });
            return{
                search() {
                    return verbify.get(path);
                },
                list({ column, order, page, perPage }) {
                    return verbify.get(path`?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
                },
                insert(model) {
                    return verbify.post(path, model);
                },
                update(model) {
                    return verbify.put(path, model);
                }
            }
        },
    }
}

export default verbify;
