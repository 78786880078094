import Classe from 'class/secretary/Classe';
import BaseService from 'service/BaseService';
import verbify from '../config';
/**
 * @extends {BaseService<Classe>}
 */
export default class ClasseService extends BaseService {
    static urlBase = "turma";
    constructor() {
        super(ClasseService.urlBase);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {Classe} classe 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    listReportClasseModuleStudent(currentPage, classe) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage
        return verbify.get(`turma-modulo-alunos-report/${classe?.id}/?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {Classe} classe 
     * @returns {Promise<AxiosResponse<ObjectResponseList<T>, any>>}
     */
    listReportClasseModuleTeacher(currentPage, classe) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage
        return verbify.get(`turma-modulo-professores-report/${classe?.id}/?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
}