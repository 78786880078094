import MinimalLayout from "layout/MinimalLayout";
import { lazy } from "react";

import Loadable from 'ui-component/Loadable';

const WordpadPageEditorView = Loadable(lazy(() => import("views/wordpad/WordpadPageEditorView")));
const AceResponseView = Loadable(lazy(() => import("views/ace/respose/AceResposeView")));

const OutherRoutes = {
    path: 'ext',
    element: <MinimalLayout />,
    children: [
        {
            path: 'wordpad-editor/:wordpad', 
            element: <WordpadPageEditorView />
        },
        {
            path: 'ace-resposta/:idAce', 
            element: <AceResponseView />
        }

    ]
}

export default OutherRoutes;