import React, { useState } from 'react';
import { IconEdit } from '@tabler/icons';
import ClasseService from 'service/verbify/secretary/ClasseService';
import DataGridBase from 'ui-component/populated/base/DataGridBase';
import ObjectResponseList from 'class/ObjectResponseList';
import Classe from 'class/secretary/Classe';

/**
 * Retorana uma tabela populada com os idiomas cadastrados
 * @param {Object} param0 
 * @param {[ObjectResponseList<any>, React.Dispatch<ObjectResponseList<any>>]} param0.stateList 
 * @param {Classe} param0.classe 
 * @returns {React.JSX.Element}
 */    
export default function DataGridListClasseModuleTeacherForClasse({
    stateList,
    classe
}) {
    const classeService = new ClasseService();

    /** * @type {[ObjectResponseList<Classe>, React.Dispatch<ObjectResponseList<Classe>>]} */
    const [ classes, setClasses ] = useState(stateList ? null : new ObjectResponseList());

    /** * @type {[ObjectResponseList<Classe>, React.Dispatch<ObjectResponseList<Classe>>]} */
    const stateClasses = stateList ?? [ classes, setClasses ];

    return (
        <DataGridBase
            height={300}
            stateItens={stateClasses}
            functionListItens={(page) => classeService.listReportClasseModuleTeacher(page, classe)}
            columns={[
                { field: 'actions', headerName : 'Ações', renderCell: (params) => {
                    return <>
                    </>
                }},
                { field: 'id', headerName: 'Código', width : 50 },
                { field: 'name', headerName: 'Nome', width : 150 },
                { field: 'paymentAVG', valueGetter: ({row}) => new Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL"}).format(row.paymentAVG), headerName: 'Méd. R$/Aula', width : 100 },
                { field: 'startDate', valueGetter: ({row}) => new Date(row.startDate+" 00:00:00").toLocaleDateString() ,headerName: 'Ingresso', width : 100 },
                { field: 'firstClasseModule', headerName: 'Primeiro Modulo', width : 185 },
                { field: 'listClasseModuleTeacherLabel',  headerName: 'Lecionando em', width : 200 }
            ]}
        />
    );
}