import { Button } from "@mui/material";
import { IconEdit } from "@tabler/icons";
import ObjectResponseList from "class/ObjectResponseList";
import TeacherModule from "class/person/teacher/TeacherModule";
import DataRecurrence from "data/utils/DataRecurrence";
import { useState } from "react";
import TeacherModuleService from "service/verbify/user/teacher/TeacherModuleService";
import DataGridBase from "ui-component/populated/base/DataGridBase";

/**
 * @param {Object} props
 * @param {[ObjectResponseList<TeacherModule>, React.Dispatch<ObjectResponseList<TeacherModule>>]} props.stateList
 * @param {ClasseModule} props.classeModule
 * @param {[TeacherModule, React.Dispatch<TeacherModule>]} props.stateTeacherModule
 * @returns 
 */
export default function DataGridTeacherModule({
    stateList,
    classeModule,
    stateTeacherModule,
    height
}) {
    const teacherModuleService = new TeacherModuleService({classeModule});
    /**
     * @type {[ObjectResponseList<TeacherModule>, React.Dispatch<ObjectResponseList<TeacherModule>>]}
     */
    const [ items, setItems ] = useState(stateList ? null : new ObjectResponseList());

    const stateTeacherModules = stateList ?? [ items, setItems ];

    return <DataGridBase
        height={height ?? undefined}
        columns={[
            { 
                field: 'option', 
                renderCell : (params) => <Button 
                    variant={'text'} autoFocus 
                    onClick={(e) => stateTeacherModule[1](prev => {
                        return {
                            ...params.row
                        }
                    })}
                >
                    <IconEdit size={20}></IconEdit>
                </Button>, 
                headerName: 'Opções', 
                width : 100 
            },
            { field: 'nomeProfessor', valueGetter : (params) => `${params.row.teacher.person.name} (${params.row.teacher.academicTitle})`, headerName: 'Professor(a)', width : 250},
            { field: 'price', valueGetter : (params) => `${params.row.price} (${params.row.paymentPeriod.label})`, headerName : 'Valor hora', width : 150 },
            { field: 'starDate', valueGetter : (params) => params.row.startDate ? (typeof params.row.startDate == 'string' ? new Date(params.row.startDate + ' 00:00:00').toLocaleDateString() : params.row.startDate) : '-', headerName: 'início', width: 120 },
            { field: 'endDate', valueGetter : (params) => params.row.endDate ? (typeof params.row.endDate == 'string' ? new Date(params.row.endDate + ' 00:00:00').toLocaleDateString() : params.row.endDate) : '-', headerName: 'Fim', width: 120 }
        ]}
        functionListItens={(page) => teacherModuleService.list(page)}
        stateItens={stateTeacherModules}
        mapRow={(row) => {
            if (isNumber(row.paymentPeriod))
                row.paymentPeriod = DataRecurrence.find(f => f.id == row.paymentPeriod);
            return row;
        }}
    />
}
