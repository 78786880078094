import Student from 'class/person/student/Student';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<Person>
 */
export default class StudentService extends BaseService {
    static baseUrl = "aluno";
    constructor () {
        super(StudentService.baseUrl);
    }
}