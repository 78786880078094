import Product from "class/opportunity/product/Product";
import Classe from "class/secretary/Classe";

export default class Proposal {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    description = null;
    /**
     * @type {int}
     * @public
     */
    paymentPeriod = null;
    /**
     * @type {int}
     * @public
     */
    formOfPayment = null;
    /**
     * @type {number}
     * @public
     */
    totalValue = 0;
    /**
     * @type {Date|null}
     * @public
     */
    proposalValidity = null;
    /**
    * @type {Product|null}
    * @public
    */
    product = new Product();
    /**
     * @type {number}
     */
    fkContaOmieId = null;
    /**
    * @type {Classe|null}
    * @public
    */
    classe = new Classe();
}