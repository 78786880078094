import User from "class/person/user/User";
import FormSearch from "ui-component/FormSeach";
import ModalFormUser from "./ModalFormUser";

/**
 * @param {Object} props
 * @param {User} props.user
 * @param {(student:User) => void} props.setUser
 * @returns 
 */
export default function FormSearchUser({
    label,
    user,
    setUser,
    loadList,
    required
}) {
    return (
        <FormSearch
            fullWidth={true}
            required={required}
            searchPatch={'/buscar/usuario'}
            searchTable={'user'}
            searchColumn={'email'}
            label={label || "Buscar usuario"}
            item={user}
            setItem={setUser}
            loadList={loadList}
            // modal={<ModalFormUser label="Adicionar"></ModalFormUser>}
            valueExtractor={
                /**
                 * @param {User} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {User} option 
                 * @returns 
                */
                (option) => !option || option?.id == 0 || option?.id == undefined ? '' : `${option.email} (${option.whatsapp})`
            }
        ></FormSearch>
    )
}