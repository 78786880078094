export default class Situation {
    /**
     * @type {number|null}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    name = "";
}