import ObjectResponseList from "class/ObjectResponseList";
import React, { useContext, useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import ModalFormPersonLanguage from "ui-component/populated/user/person/language/ModalFormPersonLanguage";
import DataGridPersonLanguage from "ui-component/populated/user/person/language/DataGridPersonLanguage";
import PersonLanguage from "class/person/language/PersonLanguage";
import { GlobalContext } from "GlobalContext";

export default function PersonlanguageView () {
    /**
     * @type {[ObjectResponseList<PersonLanguage[]>, React.Dispatch<ObjectResponseList<PersonLanguage[]>>]} stateClasse
     */
    const stateList = useState(new ObjectResponseList());
    const [ personLanguage, setPersonLanguage ] = useState(new PersonLanguage());
    const { person } = useContext(GlobalContext).person
    useEffect(() => {
        setPersonLanguage(prev => {
            return {
                ...prev,
                person: person
            }
        })
    }, [person])

    return (
        <MainCard
            title='Experiencia com idioma'
            sx={{ 
                width: { xs: "100%"},
                height: "100%",
                margin: "0px auto"
            }}
            secondary={<ModalFormPersonLanguage stateList={stateList} label="Adicionar"></ModalFormPersonLanguage>}
        >
            {
                personLanguage?.id > 0 || personLanguage?.person?.id > 0 
                && 
                <DataGridPersonLanguage stateList={stateList} personLanguage={{...personLanguage}} />
            }
        </MainCard>
    );
}