import CustomForm from "class/customForm/CustomForm";
import CustomFormResponse from "class/customForm/response/CustomFormResponse";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class CustomFormResponseService extends BaseService {
    static _baseUrl = 'formulario/{id}/resposta';
    constructor() {
        super(CustomFormResponseService._baseUrl);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {CustomFormResponse} customFormResponse 
     * @returns {Promise<AxiosResponse<ObjectResponseList<CustomFormResponse>, any>>}
     */
    search(customFormResponse) {
        return verbify.get(`formulario/${customFormResponse?.customForm?.id}/resposta/${customFormResponse?.id}`);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {CustomForm} customForm 
     * @returns {Promise<AxiosResponse<ObjectResponseList<CustomForm>, any>>}
     */
    list(currentPage, customForm) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`formulario/${customForm.id}/resposta?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {CustomFormResponse} customFormResponse 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert(customFormResponse) {
        return verbify.post(`formulario/${customFormResponse.customForm.id}/resposta`, customFormResponse);
    }
    /**
     * @param {CustomFormResponse} customFormResponse 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update(customFormResponse) {
        return verbify.put(`formulario/${customFormResponse.customForm.id}/resposta/${customFormResponse.id}`, customFormResponse);
    }
}
