import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';

import { useEffect, useState } from "react";

/**
 * @typedef {{label:string, index:number, component:React.ReactElement, disabled?:boolean }} TabComponentProps
 */

/**
 * @param {Object} param0
 * @param {TabComponentProps[]} param0.listTabs
 * @returns
 */
export default function TabsComponent ({
    listTabs
}) {
    /**
     * @type {[number, (newTab?:number) => void]}
     */
    const [ currentTab, setCurrentTab ] = useState(undefined);

    /**
     * @param {React.SyntheticEvent<Element, Event>} evento
     * @param {any} value
     */
    function onChangeTab (evento, value) {
        setCurrentTab(value);
    }

    return (
        <TabContext value={(currentTab ?? 0).toString()}>
            <TabList onChange={onChangeTab}>
                {listTabs.map((m, i) => <Tab key={i.toString()} disabled={m.disabled} label={m.label} value={i.toString()} />)}
            </TabList>
            {listTabs.map((m, i) => <TabPanel sx={{ p: 0, pt: 1 }} key={i.toString()} value={(currentTab ?? 0).toString()} style={{ display : (currentTab ?? 0).toString() == i.toString() ? 'block' : 'none'}}>{m.component}</TabPanel>)}
        </TabContext>
    );
}