import { lazy } from "react";
import Loadable from 'ui-component/Loadable';
import MainLayout from "layout/MainLayout";
import ClasseModuleView from "views/secretary/ClasseModuleView";
const LanguageView = Loadable(lazy(() => import('views/secretary/LanguageView')));
const ClasseView = Loadable(lazy(() => import('views/secretary/ClasseView')));
const AssessmentView = Loadable(lazy(() => import('views/secretary/AssessmentView')));
const ModuloView = Loadable(lazy(() => import('views/secretary/ModuleView')));
const ScheduleView = Loadable(lazy(() => import('views/schedule/ScheduleView')));
const ScheduleViewStudent = Loadable(lazy(() => import('views/schedule/ScheduleViewStudent')));

const SecreatryRoutes = {
    path: '',
    element: <MainLayout />,
    children : [
        {
            path: '/coordenacao/idiomas',
            element: <LanguageView />
        },
        {
            path: '/coordenacao/turmas',
            element: <ClasseView />
        },
        {
            path: '/coordenacao/modulos-turma',
            element: <ClasseModuleView />
        },
        {
            path: '/coordenacao/avaliacoes',
            element: <AssessmentView />
        },
        {
            path : '/coordenacao/niveis',
            element : <ModuloView />
        },
        {
            path : '/coordenacao/agenda',
            element : <ScheduleView />
        },
        {
            path : '/aluno/agenda',
            element : <ScheduleViewStudent />
        }
    ]
};

export default SecreatryRoutes;