import ObjectRequest from 'class/ObjectRequest'
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
import { AxiosResponse } from 'axios';
import ObjectResponseList from 'class/ObjectResponseList';
import OpportunityProposal from 'class/opportunity/proposal/OpportunityProposal';
/**
 * @extends BaseService<OpportunityProposal>
 */
export default class OpportunityProposalService extends BaseService {
    static urlBase = "/oportunidade/proposal";
    constructor() {
        super(OpportunityProposalService.urlBase);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {OpportunityProposal} opportunityProposal 
     * @returns {Promise<AxiosResponse<ObjectResponseList<OpportunityProposal>, any>>}
     */
    list (currentPage, opportunity) {
        console.log("OPORTUNITY", opportunity);
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;
        return verbify.get(`/oportunidade/${opportunity?.id}/proposta?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {OpportunityProposal} opportunityProposal 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert (opportunity) {
        return verbify.post(`/oportunidade/${opportunity?.id}/proposta`, opportunity);
    }
    /**
     * @param {OpportunityProposal} opportunityProposal 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update (opportunityProposal) {
        return verbify.put(`/oportunidade/${opportunityProposal?.opportunity?.id}/proposta/${opportunityProposal?.proposal?.id}`, opportunityProposal);
    }
}