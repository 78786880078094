export default class DayOfWeek {
    /**
     * @type {number|null}
     * @public
     */
    id = null;
    /**
     * @type {number|null}
     * @public
     */
    numberDay = null;
    /**
     * @type {string}
     * @public
     */
    label = '';
    toString() {
        return this.label;
    }
    toJSON(d) {
        return this.numberDay;
    }
}