import ModalForm from "ui-component/ModalForms";
import { useState } from "react";
import FormProposal from "ui-component/populated/proposal/FormProposal";
import Proposal from "class/proposal/Proposal";
import ObjectResponseList from "class/ObjectResponseList";
import { Grid } from "@mui/material";
import FormClasseModuleFull from "../secretery/classe/module/FormClasseModuleFull";

/**
 * 
 * @param {{label:string}} props 
 * @returns {React.JSX.Element}
 */

export default function ModalFormProposal({ id, stateList, label, proposalData, variant, callback, updateList }) {

    const stateProposal = useState(proposalData ?? new Proposal());

    const [open, setOpen] = useState(false);

    const list = useState(new ObjectResponseList());

    const stateProposals = stateList ?? list;

    function setItem(opportunity) {
        stateProposals[1](prev => {
            const newListItens = [...prev.rows];

            if (newListItens.some(any => any.id == opportunity.id)) {
                const index = newListItens.findIndex(f => f.id == opportunity.id);

                newListItens[index] = opportunity;

                return {
                    ...prev,
                    rows: [...newListItens]
                }
            }
            else {
                return {
                    ...prev,
                    totalRows: prev.totalRows + 1,
                    rows: [
                        opportunity,
                        ...newListItens
                    ]
                }
            }
        });
    }

    return (
        <ModalForm
            title={"Gerenciar proposta"}
            description={"Informe os dados da proposta nos campos abaixo:"}
            form={
                <Grid container xl={12} spacing={2}>
                    <Grid item xl={(stateProposal[0]?.classe?.id ?? 0) > 0 ? 4 : 12}>
                        <FormProposal id={id} stateProposal={stateProposal} callback={callback} setItem={updateList ? setItem : undefined}></FormProposal>
                    </Grid>
                    {
                        (stateProposal[0]?.classe?.id ?? 0) > 0 && <Grid item xl={8} spacing={2} gap={2} sx={{
                            height: "100%",
                            overflow: "scroll"
                        }}>
                            <FormClasseModuleFull></FormClasseModuleFull>
                        </Grid>
                    }
                </Grid>

            }
            buttonLabel={label}
            width={stateProposal[0]?.classe?.id > 0 ? 'xl' : 'sm'}
            open={open}
            setOpen={setOpen}
            variant={variant}
        >
        </ModalForm>
    );
}