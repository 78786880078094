
import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Address from 'class/address/Address';
import ModalFormAddress from './ModalFormAddress';

/**
 * 
 * @param {[ Address, React.Dispatch<Address> ]} stateAddress 
 * @template T
 * @param {{ (id: string|number)=>void }} callback 
 * @returns 
 */
export default function CardAddress({ stateAddress, callback }) {
    const state = useState(new Address());
    const [address, setAddress] = stateAddress ?? state;
    return (
        <Box minWidth={320} bgcolor={'primary.light'} p={2} borderRadius={2} boxShadow={3}>
            <Typography>Título: {address.name}</Typography>
            <Typography>Rua: {address.type}</Typography>
            <Typography>Id: {address.id}</Typography>
            <Typography>Número: {address.postalCode}</Typography>
            <Divider sx={{ mt: 1, mb: 1, border: 'none' }} ></Divider>
            <ModalFormAddress
                label={`Editar endereço`}
                stateAddress={[address, setAddress]}
                callback={callback}
            >
            </ModalFormAddress>
        </Box>
    );
}