import ObjectRequest from 'class/ObjectRequest';
import ClasseModule from 'class/secretary/classe/ClasseModule';
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
import { AxiosResponse } from 'axios';
import ObjectResponseList from 'class/ObjectResponseList';
/**
 * @extends {BaseService<ClasseModule>}
 * @class ClasseModuleService
 */
export default class ClasseModuleService extends BaseService {
    static urlBase = "/turma/modulo";
    constructor() {
        super(ClasseModuleService.urlBase);
    }
    /**
     * @param {ClasseModule} classeModule 
     * @param {string} start 
     * @param {string} end 
     * @returns {Promise<AxiosResponse<ObjectResponseList<ClasseModule>, any>>}
     */
    listByClasseModule(
        classeModule,
        start = null,
        end = null
    ) {
        const queryStart = start ? `start=${start}` : "";
        const queryEnd = end ? `end=${end}` : "";
        return verbify.get(`/turma-modulo/${classeModule?.id}/folha-de-frequencia?${queryStart}&${queryEnd}`);
    }
    list (currentPage, classeModule) {
        const {
            column,
            order,
            page,
            perPage
        } = currentPage;

        return verbify.get(`/modulos-turma?column=${column}&order=${order}&page=${page}&perPage=${perPage}`);
    }
    /**
     * @param {number} classeId
     * @param {number} classeModuleId 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    search(classeId, classeModuleId) {
        return verbify.get(`turma/${classeId}/modulo/${classeModuleId}`);
    }
    /**
     * @param {ClasseModule} classeModule 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    insert (classeModule) {
        return verbify.post(`/turma/${classeModule.classe.id}/modulo`, classeModule);
    }
    /**
     * @param {ClasseModule} classeModule 
     * @returns {Promise<AxiosResponse<void, any>>}
     */
    update (classeModule) {
        return verbify.put(`/turma/${classeModule.classe.id}/modulo/${classeModule.module.id}`, classeModule);
    }
}