import Language from 'class/secretary/Language';
import BaseService from 'service/BaseService';

/**
 * @extends BaseService<Language>
 */
export default class LanguageService extends BaseService {
    static urlBase = "idioma";
    constructor() {
        super(LanguageService.urlBase);
    }
}