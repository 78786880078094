import { LoadingButton } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import Document from "class/document/Document";
import DocumentService from "service/verbify/document/DocumentService";
import InputFileBase from "../base/InputFileBase";
import { Button, Link, Typography } from "@mui/material";

/**
 * @param {{customerData?:Document, setOpenModal:React.Dispatch<boolean>}} props 
 * @returns {React.JSX.Element}
 */
export default function FormDocument({ stateDocument, setOpenModal, callback }) {
    const documentService = new DocumentService();
    
    const doc = useState(new Document());

    const [document, setDocument] = stateDocument ?? doc;

    function handleFile({ file }) {
        setDocument(prev => {
            return {
                ...prev,
                urlFile: file
            }
        })
    }
    /**
    * @type {[boolean, React.Dispatch<boolean>]} state
    */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
     * @returns {void}
     */
    function save(e) {
        e.preventDefault();
        e.stopPropagation();
        setButtonLoading(true);

        const isUpdate = document?.id != undefined && document?.id != null;
        (isUpdate ? documentService.update(document) : documentService.insert(document))
            .then((response) => {
                if (callback) callback(response?.data?.model?.id)
                if(setItem) setItem(isUpdate ? document : response.data.model);
                setDocument(prev => {
                    return {
                        ...prev,
                        id: response.data.model.id
                    }
                })
            }).catch((ex) => {
                console.log(ex);
            }).finally(() => {
                if (setOpenModal) setOpenModal(false);
                setButtonLoading(false);
            });
    }

    return (
        <form
            onSubmit={save.bind(this)}
        >
            <Stack
                spacing={2}
                mt={2}
                mb={2}
                direction={'column'}
            >
                <TextField
                    required
                    value={document?.name}
                    fullWidth
                    onChange={(e) => setDocument(prev => {
                        return {
                            ...prev,
                            name: e.target.value
                        }
                    })}
                    label="Titulo"
                    placeholder="Titulo"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 45 }}
                ></TextField>
                <TextField
                    value={document?.codigo}
                    fullWidth
                    onChange={(e) => setDocument(prev => {
                        return {
                            ...prev,
                            codigo: e.target.value
                        }
                    })}
                    label="Codigo"
                    placeholder="Codigo"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 45 }}
                ></TextField>
                <TextField
                    value={document?.type}
                    fullWidth
                    onChange={(e) => setDocument(prev => {
                        return {
                            ...prev,
                            type: e.target.value
                        }
                    })}
                    label="Tipo"
                    placeholder="Tipo"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 45 }}
                ></TextField>
                <TextField
                    value={document?.subType}
                    fullWidth
                    onChange={(e) => setDocument(prev => {
                        return {
                            ...prev,
                            subType: e.target.value
                        }
                    })}
                    label="Sub-tipo"
                    placeholder="Sub-tipo"
                    variant="outlined"
                    type="text"
                    inputProps={{ maxLength: 45 }}
                ></TextField>
                {
                    document?.urlFile !== ''
                    &&
                    <Typography
                        variant="body2"
                        color="primary"
                        component={Button}
                        fullWidth
                        href={`https://api.companhiadeidiomas.com.br/api/upload?file=${encodeURIComponent(document?.urlFile)}`}
                        // href={`https://api.companhiadeidiomas.com.br/api/upload?file=${encodeURIComponent(document?.urlFile)}`}
                        target="_blank"
                        display="block"
                        underline="hover"
                        gutterBottom
                    >
                        Ver documento
                    </Typography>
                }

                <InputFileBase label="Carregar documento" setFile={handleFile}></InputFileBase>
            </Stack>
            <LoadingButton
                type="submit"
                variant="outlined"
                loading={buttonLoading}
                fullWidth
            >{"Salvar"}</LoadingButton>
        </form>
    );
}