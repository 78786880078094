import Document from "class/document/Document";
import User from "class/person/user/User";
import Person from "../Person";
export default class Employee {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {null}
     * @public
     */
    occupation = null;
    /**
     * @type {Document|null}
     * @public
     */
    socialSecurity = new Document();
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
}