// assets
import { IconSchool, IconLanguage, IconCategory, IconHexagons, IconCalendarDue, IconTestPipe } from "@tabler/icons";

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const secretary = {
    id: 'secretary',
    title: 'Coordenação',
    type: 'collapse',
    icon: IconSchool,
    children: [
        {
            id: 'secretary_module_classes',
            title: 'Modulos Ativos',
            type: 'item',
            icon: IconCategory,
            url: '/coordenacao/modulos-turma',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'secretary_classes',
            title: 'Turmas',
            type: 'item',
            icon: IconCategory,
            url: '/coordenacao/turmas',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'secretary_languages',
            title: 'Idiomas',
            type: 'item',
            icon: IconLanguage,
            url: '/coordenacao/idiomas',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'secretary_niveis',
            title: 'Níveis',
            type: 'item',
            icon: IconHexagons,
            url: '/coordenacao/niveis',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'secretary_assessments',
            title: 'Avaliações',
            type: 'item',
            icon: IconTestPipe,
            url: '/coordenacao/avaliacoes',
            target: false,
            breadcrumbs: false
        },
        {
            id: 'secretary_schedule',
            title: 'Agendas',
            type: 'item',
            icon: IconCalendarDue,
            url: '/coordenacao/agenda',
            target: false,
            breadcrumbs: false
        }

    ]
};

export default secretary;
