import Customer from "class/customer/Customer";
import Employee from "class/person/employee/Employee";

export default class Classe {
    /**
     * @type {number}
     * @public
     */
    id = null;
     /**
     * @type {string}
     * @public
     */
    title = '';
    /**
     * @type {string}
     * @public
     */
    description = '';
    /**
     * @type {Customer}
     * @public
     */
    customer = new Customer();
    /**
     * @type {Employee}
     * @public
     */
    employee = new Employee();
}