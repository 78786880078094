import { Dialog, AppBar, Toolbar, IconButton, Typography, Grid, Box } from "@mui/material";
import ObjectResponseList from "class/ObjectResponseList";
import Item from "class/schedule/Item";
import Schedule from "class/schedule/Schedule";
import React, { useState, cloneElement, useEffect } from "react";
import FormItem from "./FormItem";
import { IconSquare, IconSquareX, IconX } from "@tabler/icons";
import DialogBase from "ui-component/populated/base/DialogBase";
import DataGridItem from "./DataGridItem";

/**
 * @param {Object} props
 * @param {Schedule} props.schedule
 * @param {React.ReactNode} props.buttonOpenModal
 */
export default function DialogFormItem({
    schedule,
    buttonOpenModal,
    title
}) {
    /**
     * @type {[ObjectResponseList<Item>, React.Dispatch<ObjectResponseList<Item>>]}
     */
    const [ items, setItems ] = useState(new ObjectResponseList());
    const itemInit = new Item();
    itemInit.schedule = schedule;
    /**
     * @type {[Item, React.Dispatch<Item>]}
     */
    const [ item, setItem ] = useState(itemInit);
    /**
     * @param {Item} item 
     */
    function updateItemList(item) {
        const index = items.rows.findIndex(any => any.id == item.id);
        if (index > -1)
            setItems(prev => {
                let newList = [ ...prev.rows ];
                newList[index] = item;
                return {
                    ...prev,
                    rows : newList
                };
            });
        else
            setItems(prev => {
                return {
                    ...prev,
                    totalRows : prev.totalRows + 1,
                    rows : [
                        item,
                        ...prev.rows
                    ]
                };
            });
    }

    return <DialogBase title={title} buttonOpenModal={buttonOpenModal}>
        <Box sx={{p: 2}}>
            <FormItem schedule={schedule} id={item.id} itemsList={items.rows} updateItemList={updateItemList}></FormItem>
            <Grid
                container 
                sx={{
                    '& > * > * ': { width: '100%' },
                    marginTop : '8px'
                }}
            >
                <Grid item xs={12}>
                    <DataGridItem stateItem={[item, setItem]} stateList={[items, setItems]}></DataGridItem>
                </Grid>
            </Grid>
        </Box>
    </DialogBase>;
}