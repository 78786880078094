import Teacher from 'class/person/teacher/Teacher';
import Schedule from 'class/schedule/Schedule';
import DayOfWeek from 'class/utils/DayOfWeek';

export default class Item {
    /**
     * @type {null|number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    title = ''; 
    /**
     * @type {number}
     * @public
     */
    type = 0; 
    /**
     * @type {DayOfWeek}
     * @public
     */
    dayOfTheWeek = new DayOfWeek();
    /**
     * @type {Date}
     * @public
     */ 
    startDate = new Date(); 
    /**
     * @type {Date}
     * @public
     */
    endDate = new Date(); 
    /**
     * @type {string}
     * @public
     */
    startTime = '00:00'; 
    /**
     * @type {string}
     * @public
     */
    endTime = '00:00'; 
    /**
     * @type {Schedule}
     * @public
     */
    schedule = new Schedule();
    /**
     * @type {Teacher}
     * @public
     */
    teacher = new Teacher();
    toString() {
        const data = {
            ...this
        };

        // data.startDate = data.startDate.toJSON().substring(0, 10);
        // data.endDate = data.endDate.toJSON().substring(0, 10);
        return data;
    }
    toJSON() {
        const data = {
            ...this
        };

        data.startDate = data.startDate.toJSON().substring(0, 10);
        data.endDate = data.endDate.toJSON().substring(0, 10);

        console.log("JSON.stringify(data) 2: ", data);

        return data;
    }
}