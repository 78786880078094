import ObjectResponseList from "class/ObjectResponseList";
import React, { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import Classe from "class/secretary/Classe";
import ModalFormClasse from "ui-component/populated/secretery/classe/ModalFormClasse";
import DataGridClasseModule from "ui-component/populated/secretery/classeModule/DataGridClasseModule";

export default function ClasseModuleView () {
    /**
     * @type {[ObjectResponseList<Classe[]>, React.Dispatch<ObjectResponseList<Classe[]>>]} stateClasse
     */
    const [ classeModules, setClasseModules ] = useState({
        rows : [],
        totalRows : 0
    });

    return (
        <MainCard
            title='Modulos Ativos em Turmas'
            sx={{ 
                width: { xs: "100%"},
                height: "100%",
                margin: "0px auto"
            }}
            // secondary={<ModalFormClasse stateList={[ classeModules, setClasseModules ]} label="Adicionar"></ModalFormClasse>}
        >
            <DataGridClasseModule stateList={[ classeModules, setClasseModules ]} />
        </MainCard>
    );
}