import Language from "class/secretary/Language";
import LanguageService from "service/verbify/secretary/LanguageSevice";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {{
*     language:Language,
*     setLanguage:(language:Language) => void 
* }} param0 
* @returns 
*/
export default function FormSeachLanguage ({
    language,
    setLanguage,
    loadList,
    required
}) {
    const service = new LanguageService();
    const search = (...props) => service.searchFor(...props)
    return <FormSearch
        required={required}
        searchPatch={'/buscar/idioma'}
        searchTable={'language'}
        searchColumn={'title'}
        label="Idioma"
        item={language}
        setItem={setLanguage}
        loadList={loadList ?? search}
        valueExtractor={
            /**
             * @param {Language} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Language} option 
             * @returns 
            */
            (option) => ((option?.id ?? 0) <= 0) ? '' : `${option.id} - ${option.title}`
        }
    ></FormSearch>
}