import Proposal from 'class/proposal/Proposal';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<Proposal>
 */
export default class ProposalService extends BaseService {
    static urlBase = "proposta";
    constructor() {
        super(ProposalService.urlBase);
    }
}