import Student from 'class/person/student/Student';
import ClasseModule from 'class/secretary/classe/ClasseModule';
import Customer from 'class/customer/Customer';
export default class StudentModule {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {Date}
     * @public
     */
    startDate = new Date();
    /**
     * @type {Date}
     * @public
     */
    endDate = null;
    /**
     * @type {number}
     * @public
     */
    percentageOfStudent = 100;
     /**
     * @type {number}
     * @public
     */
     percentageOfStudentAVA = 100;
    /**
     * @type {Student}
     * @public
     */
    student = new Student();
    /**
     * @type {ClasseModule}
     * @public
     */
    classeModule = new ClasseModule();
    /**
     * @type {Customer}
     * @public
     */
    customer = new Customer();
}