import CustomFormResponse from "class/customForm/response/CustomFormResponse";
import CustomFormQuestion from "../CustomFormQuestion";

export default class CustomFormResponseQuestion {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    value = "";
    /**
     * @type {CustomFormQuestion}
     * @public
     */
    customFormResponse = new CustomFormResponse();
    /**
     * @type {CustomFormQuestion}
     * @public
     */
    customFormQuestion = new CustomFormQuestion();
}