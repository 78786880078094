import Categoria from "class/omie/geral/Categoria";
import CategoriaService from "service/verbify/omie/geral/CategoriaService";
import FormSearch from "ui-component/FormSeach";

/**
 * 
 * @param {Object} props
 * @param {Categoria} props.categoria
 * @param {React.Dispatch<React.SetStateAction<Categoria>>} props.setCategoria
 * @param {string|undefined|null} props.label
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<Categoria[], any> | null} props.loadList
 * @param {boolean|null|undefined} props.required
 * @returns 
 */
export default function FormSearchCategoria({
    categoria,
    setCategoria,
    label,
    loadList,
    required
}) {
    return <FormSearch
        required={required}
        searchColumn={'descricao'}
        label={label ?? 'Categoria'}
        item={categoria}
        setItem={setCategoria}
        loadList={loadList ? loadList : (searchPatch, search, searchTable, searchColumn) => {
            const categoriaService = new CategoriaService();
            return categoriaService.buscar({
                descricao : search
            });
        }}
        minimumLength={0}
        fullWidth={true}
        valueExtractor={
            /**
             * @param {Schedule} option 
             * @returns {string}
             */
            (option) => option?.codigo
        }
        textExtractor={
            /**
             * @param {Schedule} option 
             * @returns {string}
            */
            (option) => {
                return option?.codigo?.length > 0 ? `${option?.codigo ?? ''} - ${option?.descricao ?? ''}` : '';
            }
        }
    ></FormSearch>;
}