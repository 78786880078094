import CustomForm from "class/customForm/CustomForm";
import CustomFormResponse from "class/customForm/response/CustomFormResponse";
import StudentModule from "class/person/student/StudentModule";

export default class Ace {
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
     * @type {string}
     * @public
     */
    responseDate = "";
    /**
     * @type {string}
     * @public
     */
    referenceDate = "";
    /**
     * @type {StudentModule | null}
     * @public
     */
    classeModuleStudent = new StudentModule()
    /**
     * @type {CustomForm | null}
     * @public
     */
    customForm = null;
    /**
     * @type {CustomFormResponse}
     * @public
     */
    customFormResponse = new CustomFormResponse();
}