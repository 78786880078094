import { FormControl, OutlinedInput, Stack, TextField } from "@mui/material";
import Categoria from "class/omie/geral/Categoria";
import Cidade from "class/omie/geral/Cidade";
import { useEffect, useState } from "react";
import CidadeService from "service/verbify/omie/geral/CidadeService";
import FormSearch from "ui-component/FormSeach";

/**
 * 
 * @param {Object} props
 * @param {Cidade} props.cidade
 * @param {React.Dispatch<React.SetStateAction<Cidade>>} props.setCidade
 * @param {string|undefined|null} props.label
 * @param {boolean|null|undefined} props.required
 * @returns 
 */
export default function FormSearchCidade({
    cidade,
    setCidade,
    label,
    required
}) {
    const cidadeService = new CidadeService();

    const [ list, setList ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if ((cidade?.cUF?.length ?? 0) > 1) {
            setLoading(true);
            cidadeService.list({}, { uf : cidade?.cUF }).then((response) => {
                let result = response.data.rows;
                if (result.msgErro) result = [];
                setList(result);
            }).catch(function (ex) {
                setList([]);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [cidade?.cUF]);

    return <Stack
        spacing={2}
        marginTop={1}
    >
        <TextField
            required
            fullWidth
            value={cidade?.cUF ?? ''}
            onChange={(e) => setCidade({
                ...cidade,
                cUF : (e.target.value ?? '').toUpperCase()
            })}
            label="UF"
            placeholder="UF"
            variant="outlined"
            inputProps={{ maxLength: 45 }}
        ></TextField>
        <FormSearch
            required={required}
            searchColumn={'cNome'}
            label={label ?? 'Cidade'}
            item={cidade}
            setItem={setCidade}
            stateList={[list, setList]}
            fullWidth
            stateLodingParam={[ loading, setLoading ]}
            loadList={() => Promise.resolve({ data : { rows : [] }})}
            minimumLength={0}
            valueExtractor={
                (option) => option?.cCod
            }
            textExtractor={
                (option) => option?.cCod?.length > 0 ? `${option?.cNome  ?? ''}` : ''
            }
        ></FormSearch>
    </Stack>;
}
