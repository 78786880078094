/**
 * @param {Object} props 
 * @param {number?} props.classeId
 * @param {(item: Classe) => void} props.setItem
 * @returns {React.JSX.Element}
 */

import { Class } from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ObjectResponseList from "class/ObjectResponseList";
import Classe from "class/secretary/Classe";
import { useEffect, useState } from "react";
import ClasseService from "service/verbify/secretary/ClasseService";
import DataGridListClasseModuleStudentForClasse from "./DataGridListClasseModuleStudentForClasse";
import DataGridListClasseModuleTeacherForClasse from "./DataGridListClasseModuleTeacherForClasse";


/**
    * 
    * @param {Object} param0 
    * @param {number} param0.classeId    
    * @param {Classe} param0.classeData    
    * @returns 
*/
export default function ReportClasse({ classeId, classeData, setItem, item }) {

    const classeService = new ClasseService();

    const [classe, setClasse] = useState(classeData ?? new Classe())

    useEffect(() => {
        if (classeId && classeId > 0)
            classeService.search(classeId)
                .then(resp => {
                    console.log(resp.data, "ONFONAOFNOAFNOFANOIBNFOABNAO");
                    setClasse(prev => {
                        return {
                            ...prev,
                            ...resp.data
                        }
                    })
                })
    }, [])

    useEffect(() => {
        console.log(classe, "ONFONAOFNOAFNOFANOIBNFOABNAO");
    }, [classe])

    const stateListStudents = useState([]);
    const stateListTeachers = useState([]);
    const theme = useTheme();

    return (
        <>
            <Box width={"100%"} bgcolor={theme.palette.primary.light} p={3} borderRadius={theme.shape.borderRadius}>
                <Box p={3} bgcolor={theme.palette.primary.main}>
                    <Grid container xl={12}>
                        <Grid item xl={4}>
                            <Typography variant="h4 " color={theme.palette.primary.light}>Idioma: {classe.title}</Typography>
                        </Grid>
                        <Grid item xl={4}>
                            <Typography variant="h4 " color={theme.palette.primary.light}>Cliente: {classe.customer.person.name}</Typography>
                        </Grid>
                        <Grid item xl={4}>
                            <Typography variant="h4 " color={theme.palette.primary.light}>Coordenador: {classe.employee.person.name}</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h4 " color={theme.palette.primary.light} mt={2}>Detalhes: {classe.description}</Typography>
                </Box>
                <Box mt={3}>
                    <Typography variant="h3">Lista de Alunos</Typography>
                    {
                        classe.id > 0 && <DataGridListClasseModuleStudentForClasse
                            stateList={stateListStudents}
                            classe={classe}
                        ></DataGridListClasseModuleStudentForClasse>
                    }
                </Box>
                <Box mt={3}>
                    <Typography variant="h3">Lista de Professores</Typography>
                    {
                        classe.id > 0 && <DataGridListClasseModuleTeacherForClasse 
                            stateList={stateListTeachers}
                            classe={classe}
                        ></DataGridListClasseModuleTeacherForClasse>
                    }
                </Box>
            </Box>
        </>
    );
}