export default class ObjectRequest {
    /**
     * @type {number}
     * @public
     * @static
     */
    static perPageDefault = 15;
    /**
     * @type {string}
     * @public
     */
    column = 'id';
    /**
     * @type {'ASC'|'DESC'}
     * @public
     */
    order = 'DESC';
     /**
     * @type {number}
     * @public
     */
    page = 1;
     /**
     * @type {number}
     * @public
     */
    perPage = ObjectRequest.perPageDefault;
    /**
     * @type {number}
     * @public
     */
    totalRows = 0;
    /**
     * @type {[]}
     * @public
     */
    filters = [];
}