import { Button, Grid, TextField } from "@mui/material";
import FormSearchStudent from "../FormSearchTeacher";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import ClasseModule from "class/secretary/classe/ClasseModule";
import TeacherModule from "class/person/teacher/TeacherModule";
import TeacherModuleService from "service/verbify/user/teacher/TeacherModuleService";
import FormSearchTeacher from "../FormSearchTeacher";
import FormSearchRecurrence from "ui-component/populated/utils/dayOfWeek/FormSearchRecurrence";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";

/**
 * @param {Object} props 
 * @param {ClasseModule} props.classeModule
 * @param {(studentModuleUpdateList:TeacherModule) => void} props.updateList
 * @param {[TeacherModule, React.Dispatch<TeacherModule>]} props.stateTeacherModule
 * @returns 
 */
export default function FormTeacherModule({
    classeModule,
    updateList,
    stateTeacherModule
}) {
    const teacherModuleService = new TeacherModuleService({classeModule});
    /**
     * @type {[boolean, React.Dispatch<boolean>]}
     */
    const [ buttonLoading, setButtonLoading ] = useState(false);
    /**
     * @type {[TeacherModule, React.Dispatch<React.Dispatch<TeacherModule>>]}
     */
    const stateDataTeacherModule = useState(new TeacherModule());
    const [ teacherModule, setTeacherModule ] = stateTeacherModule ?? stateDataTeacherModule;

    function limpar() {
        setTeacherModule(new TeacherModule());
        setButtonLoading(false);
    }
    /**
     * Salva as modificações da language no banco de dados
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} ev
     * @returns {void}
     */
    function save(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        console.log(teacherModule.endDate, "fhfaoafooajfooaf");
        let newTeacherModule = { 
            ...teacherModule,
            classeModule : classeModule,
            startDate : typeof teacherModule.startDate == "string" ? teacherModule.startDate : teacherModule.startDate.toJSON().substring(0, 10),
            endDate : typeof teacherModule.endDate == "string" ? teacherModule.endDate : teacherModule.endDate == null ? null : teacherModule.endDate.toJSON().substring(0, 10)
        };

        if (!isNumber(newTeacherModule.paymentPeriod))
            newTeacherModule.paymentPeriod = newTeacherModule.paymentPeriod.id;

        const isUpdate = isNumber(newTeacherModule.id) && newTeacherModule.id > 0;

        setButtonLoading(true);
        
        (isUpdate ? teacherModuleService.update(newTeacherModule) : teacherModuleService.insert(newTeacherModule))
            .then(response => {
                newTeacherModule = isUpdate ? newTeacherModule : { ...newTeacherModule, id : response.data.model.id };

                updateList(newTeacherModule);
                limpar();
            }).catch(ex => {
                console.log(ex);
            }).finally(() => setButtonLoading(false));

    }
    return <form
        onSubmit={save.bind(this)}
        style={{
            marginTop: 8,
            padding: 2
        }}
    >
        <Grid 
            container 
            spacing={1} 
            sx={{
                '& > * > * ': { width: '100%' },
                padding : 2
            }}
        >
            <Grid item xs={12} sm={4} md={3}>
                <FormSearchRecurrence
                    required
                    label="Pagamento por"
                    recurrence={teacherModule.paymentPeriod}
                    setRecurrence={(recurrence) => {
                        setTeacherModule(prev => {
                            return {
                                ...prev,
                                paymentPeriod : recurrence
                            }
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <TextField
                    label={"Valor por hora"}
                    required
                    type="number"
                    onChange={(e) => {
                        setTeacherModule(prev => {
                            if (Number(e.target.value) >= 0)
                                prev.price = Number(e.target.value);
                            return {
                                ...prev
                            };
                        });
                    }}
                    value={teacherModule.price}
                ></TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <FormSearchTeacher
                    required={true}
                    setTeacher={(teacher) => {
                        setTeacherModule(prev => {
                            prev.teacher = teacher;
                            return {
                                ...prev
                            };
                        })
                    }}
                    teacher={teacherModule.teacher}
                ></FormSearchTeacher>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <DateField
                    label={"Data inicial"}
                    value={dayjs(teacherModule?.startDate)}
                    onChange={(newValue) => setTeacherModule(prev => {
                        return {
                            ...prev,
                            startDate : new Date(Date.parse(newValue.$d))
                        };
                    })}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <DateField
                    label={"Data final"}
                    value={teacherModule?.endDate ? dayjs(teacherModule?.endDate) : null}
                    onChange={(newValue) => setTeacherModule(prev => {
                        return {
                            ...prev,
                            endDate : new Date(Date.parse(newValue.$d))
                        };
                    })}
                />
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    loading={buttonLoading}
                >{"Salvar"}</LoadingButton>
            </Grid>

            {(isNumber(teacherModule?.id) && teacherModule?.id > 0) && <Grid item xs={12}>
                <Button
                    variant="outlined"
                    color='error'
                    onClick={(e) => limpar()}
                >Cancelar</Button>
            </Grid>}
        </Grid>
    </form>
}