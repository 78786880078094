import Classe from "class/secretary/Classe";
import ClasseService from "service/verbify/secretary/ClasseService";
import FormSearch from "ui-component/FormSeach";

/**
 * @param {Object} props
 * @param {Classe} props.classe
 * @param {(classe:SetClass) => void} props.setClasse
 * @param {string} props.label
 * @param {(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<AxiosResponse<{ rows: D[]}, any>} props.loadList,
 * @param {boolean} props.required
 * @returns 
 */
export default function FormSeachClasse({
    classe,
    setClasse,
    label,
    loadList,
    modal,
    required
}) {
    const classeService = new ClasseService();
    const funcList = (searchPatch, search, searchTable, searchColumn) => classeService.searchFor(searchPatch, search, searchTable, searchColumn)
    return <FormSearch
        modal={modal}
        required={required}
        searchPatch={'/buscar/turma'}
        searchTable={'classe'}
        searchColumn={'title'}
        label={label ?? "Classe"}
        item={classe}
        setItem={setClasse}
        loadList={loadList ?? funcList}
        valueExtractor={
            /**
             * @param {Classe} option 
             * @returns {string}
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {Classe} option 
             * @returns {string}
            */
            (option) => option.id > 0 ? `${option.id} - ${option.title}` : ''
        }
    ></FormSearch>
}