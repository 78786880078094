import Product from "class/opportunity/product/Product"
import FormSearch from "ui-component/FormSeach"

/**
 * @param {{
 *     product:Product,
 *     setProduct:(product:Product) => void,
 *     loadList:(searchPatch: string, search: string, searchTable: string, searchColumn: string) => Promise<{rows: D[]}
 *     required?:boolean
 * }} param0 
 * @returns 
 */
export default function FormSearchProduct({
    product,
    setProduct,
    loadList,
    required,
    modal
}) {
    return (
        <FormSearch
            modal={modal}
            required={required}
            searchPatch={'/buscar/produto'}
            searchTable={'product'}
            searchColumn={'title'}
            label="Produto"
            item={product}
            setItem={setProduct}
            loadList={loadList}
            valueExtractor={
                /**
                 * @param {Product} option 
                 * @returns 
                 */
                (option) => option?.id
            }
            textExtractor={
                /**
                 * @param {Product} option 
                 * @returns 
                */
                (option) => !option || option.id == undefined ? '' : `${option.id} - ${option.title}`
            }
        ></FormSearch>
    )
}