import ObjectResponse from "class/ObjectResponse";
import verbifyFile from "./verbify/configFile";

/**
 * @template T
 */
export default class BaseFileService {
    /**
     * @param {string} baseUrl 
     */
    constructor (baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * @param {string} path 
     * @returns {Promise<AxiosResponse<T, any>>}
     */
    search(path) {
        return verbifyFile.get(`${this.baseUrl}?file=${encodeURIComponent(path)}`);
    }
    /**
     * @param {T} data 
     * @returns {Promise<AxiosResponse<ObjectResponse<T>, any>>}
     */
    insert(data) {
        return verbifyFile.post(this.baseUrl, data);
    }
}