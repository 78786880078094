import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import DataGridPayments from "ui-component/populated/payment/DataGridPayments";

export default function PaymentAdmin() {
    const [loading, setLoading] = useState(false);
    return <MainCard
        title="Pagamentos"
    >
        <LoadingButton
            variant="outlined" 
            style={{ width : '100%', marginBottom : 10 }}
            loading={loading}
        >Gerar notas</LoadingButton>
        <DataGridPayments 
        />
    </MainCard>
}