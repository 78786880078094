
import React, { useState } from 'react';
import ModalFormDocument from './ModalFormDocument';
import { Box, Divider, Typography } from '@mui/material';

/**
 * 
 * @param {[ Document, React.Dispatch<Document> ]} stateDocument 
 * @template T
 * @param {{ func: (id: string|number, key: T)=>void, key:'stateDocument'|'federalDocument' }} callback 
 * @returns 
 */
export default function CardDocument({ stateDocument, callback, description, label }) {
    const state = useState(new Document());
    const [document, setDocument] = stateDocument ?? state;

    return (
        <Box minWidth={320} bgcolor={'primary.light'} p={2} borderRadius={2} boxShadow={3}>
            <Typography>Tipo: {document.type}</Typography>
            <Typography>ID: {document.id}</Typography>
            <Typography>Título: {document.name}</Typography>
            <Typography>Número: {document.codigo}</Typography>
            <Divider sx={{ mt: 1, mb: 1, border: 'none' }} ></Divider>
            <ModalFormDocument
                label={label ?? `Editar ${document.type}`}
                description={description}
                stateDocument={[document, setDocument]}
                callback={[callback?.func, callback?.key] ?? false}
            >
            </ModalFormDocument>
        </Box>
    );
}