import FormSearch from "ui-component/FormSeach"

export default function InputSearchPaymentPeriod({
    item,
    setItem,
    loadList,
    required
}) {
    return (
        <FormSearch
            required={required}
            searchPatch={'/buscar/perido-de-pagamento'}
            searchTable={'label'}
            searchColumn={'label'}
            label="Periodo de Pagamento"
            item={item}
            setItem={setItem}
            loadList={loadList}
            valueExtractor={
                (option) => option?.id
            }
            minimumLength={0}
            textExtractor={
                (option) => option.id == 0 ? '' : `${option.id} - ${option.label}`
            }
        ></FormSearch>
    )
}