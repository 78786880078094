import Person from "class/person/Person";
import financial from "menu-items/financial";
import sales from "menu-items/sales";
import secretary from "menu-items/secretary";
import student from "menu-items/student";
import { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext({});

const GlobalContextComponent = ({ children }) => {
    const [persons, setPersons] = useState([])
    
    const [menus, setMenus] = useState({
        id: 'school',
        title: 'Área administrativa',
        type: 'group',
        children: []
    })

    const [person, setPerson] = useState(new Person())

    useEffect(() => {
        setMenus(prev => {
            const data = prev;
            if(
                data.children.findIndex(menu => menu.id === financial.id) === -1
                &&
                (person?.user?.employee || person?.user?.admin > 0)
            ) data.children.push(financial)
            
            if(
                data.children.findIndex(menu => menu.id === secretary.id) === -1
                &&
                (person?.user?.teacher || person?.user?.admin > 0)
            ) data.children.push(secretary)
            
            if(
                data.children.findIndex(menu => menu.id === sales.id) === -1
                &&
                (person?.user?.employee || person?.user?.admin > 0)
            ) data.children.push(sales)

            // if(
            //     data.children.findIndex(menu => menu.id === student.id) === -1
            //     &&
            //     (person?.user?.student || person?.user?.admin > 0)
            // ) data.children.push(student)
            
            return data
        })

    }, [person?.id])

    const context = {
        persons: { persons, setPersons },
        person: { person, setPerson },
        menus: [ menus, setMenus ],
    }

    return <GlobalContext.Provider value={context}>
        {children}
    </GlobalContext.Provider>
}

export default GlobalContextComponent;