import DayOfWeek from "class/utils/DayOfWeek";
import DayOfWeekService from "service/verbify/utils/DayOfWeekService";
import FormSearch from "ui-component/FormSeach";
/**
 * @param {Object} props
 * @param {DayOfWeek} props.dayOfWeek
 * @param {(dayOfWeek:DayOfWeek) => void} props.setDayOfWeek
 * @returns 
 */
export default function FormSeachDayOfWeek({
    dayOfWeek,
    setDayOfWeek,
    label,
    disabled,
    required
}) {
    const dayOfWeekService = new DayOfWeekService();
    return <FormSearch
        required={required}
        disabled={disabled}
        loadList={(...props) => dayOfWeekService.searchFor(props[3], props[1])}
        searchPatch={'/buscar/modulo'}
        searchTable={'module'}
        searchColumn={'label'}
        label={label ?? "Dia da semana"}
        item={dayOfWeek}
        setItem={setDayOfWeek}
        minimumLength={0}
        valueExtractor={
            /**
             * @param {DayOfWeek} option 
             * @returns 
             */
            (option) => option?.id
        }
        textExtractor={
            /**
             * @param {DayOfWeek} option 
             * @returns 
            */
            (option) => !option || option?.id == 0 ? '' : `${option.label}`
        }
    />
}