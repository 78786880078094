import Categoria from "class/omie/geral/Categoria";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class CategoriaService extends BaseService {
    static _baseUrl = 'omie/geral/categoria';
    constructor () {
        super(CategoriaService._baseUrl);
    }
    /**
     * @param {ObjectRequest} currentPage 
     * @param {Categoria} categoria 
     * @returns {Promise<import("axios").AxiosResponse<Categoria[], any>>}
     */
    buscar ({
        descricao
    }) {
        return verbify.get(`${this.baseUrl}/buscar?descricao=${descricao}`);
    }
}