import { CheckBox } from "@mui/icons-material";
import ObjectResponseList from "class/ObjectResponseList";
import Payment from "class/payment/Payment";
import { useState } from "react";
import PaymentService from "service/verbify/payment/PaymentService";
import DataGridBase from "ui-component/populated/base/DataGridBase";

const columns = [
    { 
        field: 'parcelName', 
        renderCell : ({ row }) => `${row.numberInstallment}º`, 
        headerName: 'Parcela', 
        width : 100 
    },
    { field: 'dueDate', headerName: 'Vencimento', width : 100 },
    { field: 'valuePredicted', headerName: 'Valor (R$)', width : 120 },
    { field: 'beneficiaryName', valueGetter : ({ row }) => row.beneficiary.name, headerName : 'Beneficiario', width : 250 },
    { field: 'debtorName', valueGetter : ({ row }) => row.debtor.name, headerName : 'Pagador', width : 150 }
];

export default function DataGridPayments({
    onChangeRow,
    getStatePage
}) {
    /** @type {[ObjectResponseList<Payment>, React.Dispatch<React.SetStateAction<ObjectResponseList<Payment<any>>>>]} */
    const [ payments, setPayments ] = useState(new ObjectResponseList());
    const paymentServices = new PaymentService();

    /** @param {Payment} payment */
    function getKey (payment) {
        return payment.id;
    }

    /**  */
    function loadList(page) {
        return paymentServices.list({page});
    }

    return <DataGridBase
        keyExtractor={getKey} 
        columns={columns}
        getStatePage={getStatePage}
        functionListItens={loadList}
        stateItens={[payments, setPayments]}
        mapRow={(row) => Object.assign(new Payment(), row)}
        checkboxSelection
        onChangeRow={onChangeRow}
    ></DataGridBase>;
}