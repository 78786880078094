import AbsenceType from 'class/absenceType/AbsenceType';
import BaseService from 'service/BaseService';
/**
 * @extends BaseService<AbsenceType>
 */
export default class AbsenceTypeService extends BaseService {
    static urlBase = "tipo-de-ausencia";
    constructor() {
        super(AbsenceTypeService.urlBase);
    }
}