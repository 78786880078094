import ClasseModule from 'class/secretary/classe/ClasseModule';
import BaseService from 'service/BaseService';
import verbify from 'service/verbify/config';
import classeModuleStudentPrice from 'class/secretary/classe/student/price/ClasseModuleStudentPrice';
/**
 * @extends {BaseService<ClasseModule>}
 * @class ClasseModuleService
 */
export default class ClasseModuleStudentPriceService extends BaseService {
    static urlBase = "turma-modulo-aluno/{classeModuleStudent}/tabela-de-preco/{classeModuleStudentPrice}";
    /** 
     * @param {classeModuleStudentPrice} classeModuleStudentPrice
     */
    static hydrateURL(classeModuleStudentPrice) {
        let url = ClasseModuleStudentPriceService.urlBase;
        if(classeModuleStudentPrice?.classeModuleStudent?.id > 0) url = url.replace('{classeModuleStudent}', (classeModuleStudentPrice?.classeModuleStudent?.id ?? 0)?.toString())
        else return url

        if(classeModuleStudentPrice?.id > 0) url = url.replace('{classeModuleStudentPrice}', (classeModuleStudentPrice?.id ?? 0)?.toString())
        else url = url.replace('/{classeModuleStudentPrice}', "")
        
        return url
    }
    constructor(classeModuleStudentPrice) {
        super(ClasseModuleStudentPriceService.hydrateURL(classeModuleStudentPrice));
    }
    search(id, type = "list") {

        return verbify.get(`${this.baseUrl}?type=${type}`);
    }
}