import { lazy } from "react";

import Loadable from 'ui-component/Loadable';
import ProfileStudent from "views/profiles/ProfileStudent";
import MinimalLayout from "layout/MinimalLayout";

const OnboardingTeacher = Loadable(lazy(() => import('views/onboarding/OnboardingTeacher')))
const ProfileTeacher = Loadable(lazy(() => import('views/profiles/ProfileTeacher')))
const AddUser = Loadable(lazy(() => import('views/profiles/AddUser')))

const OnboardingRoutes = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: '/onboarding',
            element: <OnboardingTeacher />
        },
        {
            path: '/onboarding/cliente', 
            element: <AddUser />
        },
        {
            path: '/onboarding/professor', 
            element: <ProfileTeacher />
        },
        {
            path: '/onboarding/aluno', 
            element: <ProfileStudent />
        }
    ]
}

export default OnboardingRoutes;