import Person from "class/person/Person";

export default class Customer {
    constructor (data) {
        if(!data) return;
        this.id = data?.id;
        this.segment = data?.segment;
        this.person = data?.person;
    }
    /**
     * @type {number}
     * @public
     */
    id = null;
    /**
    * @type {string}
    * @public
    */
    segment = '';
    /**
     * @type {Person}
     * @public
     */
    person = new Person();
}