import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import StudentModule from "class/person/student/StudentModule";
import StudentModuleService from "service/verbify/user/student/StudentModuleService";
import ClasseModule from "class/secretary/classe/ClasseModule";
// import FormSearchCustomer from "views/customer/Forms/FormSeachCustomer";

/**
 * @param {Object} props 
 * @param {ClasseModule} props.classeModule
 * @param {(studentModuleUpdateList:StudentModule) => void} props.updateList
 * @param {[StudentModule, React.Dispatch<ClasseModule>]} props.stateStudentModule
 * @returns 
 */
export default function FormReportFrequencySheet({
    classeModule,
    stateStudentModule
}) {
    const studentModuleService = new StudentModuleService({ classeModule });
    /**
     * @type {[boolean, React.Dispatch<boolean>]} state
     */
    const [buttonLoading, setButtonLoading] = useState(false);
    /**
     * @type {[StudentModule, React.Dispatch<React.SetStateAction<StudentModule>>]}
     */
    const [studentModule, setStudentModule] = stateStudentModule;

    const [studentFrequency, setStudentFrequency] = useState({});
    function calc(e) {
        setButtonLoading(true);
        try {

            studentModuleService.vReportFrequencySheetConsolidada(studentModule)
                .then(resp => {
                    if(resp.data.totalRows > 0) setStudentFrequency(resp.data.rows[0])
                })

        } catch (error) {
            console.log(error);
        } finally {
            setButtonLoading(false);
        }
    }
    useEffect(() => {
        console.log(studentFrequency);
    }, [studentFrequency])
    return studentFrequency.classeModuleStudentId > 0 ?
        <Typography>
            {(studentFrequency.frequencyInHours > 0 && studentFrequency.totaInlHours > 0) ? new Intl.NumberFormat('pt-BR', {style: 'percent',maximumFractionDigits: 2, }).format((studentFrequency.frequencyInHours / studentFrequency.totaInlHours)) : "Sem dadosn"}
        </Typography>
        :
        <Grid item xs={12}>
            <Button
                variant="outlined"
                onClick={(e) => calc(e)}
            >Calcular</Button>
        </Grid>
}