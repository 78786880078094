import StudentModule from "class/person/student/StudentModule";
import FrequencySheet from "../FrequencySheet";

export default class AssessmentStudentNote {
    /** @type {number} */
    id = null;

    /** @type {number} */
    note = '';
    
    /** @type {number} */
    engagement = '';
    
    /** @type {string} */
    description = '';

    /** @type {StudentModule} */
    classeModuleStudent = new StudentModule();

    /** @type {FrequencySheet} */
    frequencySheet = new FrequencySheet();
}