import React, { useEffect, useState } from 'react';
import FormSeachType from './type/FormSeachType';
import Type from 'class/schedule/item/Type';
import { Button, Grid, TextField } from '@mui/material';
import { DateField, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Item from 'class/schedule/Item';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import ItemService from 'service/verbify/schedule/ItemService';
import FormSeachDayOfWeek from 'ui-component/populated/utils/dayOfWeek/FormSeachDayOfWeek';
import DayOfWeek from 'class/utils/DayOfWeek';
import Schedule from 'class/schedule/Schedule';
import ObjectResponseList from 'class/ObjectResponseList';
import DataType from 'data/schedule/item/DataType';
import DataDayOfWeek from 'data/utils/DataDayOfWeek';
import FormSearchTeacher from 'ui-component/populated/user/teacher/FormSearchTeacher';

/**
 * @param {Object} props
 * @param {number} props.id
 * @param {(item:Item) => void} props.updateItemList
 * @param {Item[]} props.itemsList
 * @param {Schedule} props.schedule
 * @param {(Item) => void} props.callback
 * @param {[Item, (Item) => void]} props.stateItem
 */
export default function FormItem({
    id,
    updateItemList,
    itemsList,
    callback,
    stateItem,
    schedule
}) {
    const itemService = new ItemService();

    const initItem = new Item();
    initItem.schedule = schedule;

    const state = useState(initItem);
    const [ item, setItem ] = stateItem ?? state;

    /**
     * @type {[boolean, React.Dispatch<boolean>]} state
     */
    const [ buttonLoading, setButtonLoading ] = useState(false);

    const [starTime, setStarTime] = useState(dayjs('2022-04-17T00:00'));
    const [endTime, setEndTime] = useState(dayjs('2022-04-17T00:00'));

    function save(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        let dataItem = new Item();
        const newItem = Object.assign(dataItem, item);
        newItem.dayOfTheWeek = typeof newItem.dayOfTheWeek === "number" ? newItem.dayOfTheWeek : DataDayOfWeek.find(f => f.numberDay == newItem.dayOfTheWeek.numberDay).numberDay;
        newItem.type = typeof newItem.type === "number" ? newItem.type : DataType.find(f => f.id == newItem.type.id).id;

        const isUpdate = isNumber(newItem.id) && newItem.id > 0;

        setButtonLoading(true);

        (isUpdate ? itemService.update(newItem) : itemService.insert(newItem))
            .then(response => {
                const data = isUpdate ? newItem : {
                    ...newItem,
                    id : response.data.model.id
                }
                if(updateItemList) updateItemList(data);
                if(callback) callback(data)
                limpar();
            }).catch(ex => console.log(ex))
            .finally(() => {
                setButtonLoading(false);
            });
    }

    function limpar() {
        const newItem = new Item();
        newItem.schedule = schedule;
        setItem(newItem);
        setStarTime(dayjs('2022-04-17T00:00'));
        setEndTime(dayjs('2022-04-17T00:00'));
    }

    useEffect(() => {
        if (isNumber(id) && Number(id) > 0) {            
            const newItem = Object.assign(new Item(), itemsList?.find(f => f.id == id));
            newItem.schedule = schedule;
            newItem.dayOfTheWeek = typeof newItem.dayOfTheWeek === "number" ? DataDayOfWeek.find(f => f.numberDay == newItem.dayOfTheWeek) : newItem.dayOfTheWeek;
            newItem.type = typeof newItem.type === "number" ? DataType.find(f => f.id == newItem.type) : newItem.type;
            newItem.startDate = new Date(newItem.startDate);
            newItem.endDate = new Date(newItem.endDate);
            console.log("New Item: ", newItem);
            setItem(newItem);
            setStarTime(dayjs("2022-04-17T" + newItem.startTime));
            setEndTime(dayjs("2022-04-17T" + newItem.endTime));
        }
    }, [id]);

    return <form onSubmit={save.bind(this)}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <Grid container spacing={1} sx={{
                '& > * > * ': { width: '100%' },
                padding : 0
            }}>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label="Titulo" 
                        placeholder="Titulo" 
                        variant="outlined"
                        value={item.title}
                        onChange={(e) => setItem(prev => {
                            return {
                                ...prev,
                                title : e.target.value
                            };
                        })}
                        inputProps={{ maxLength : 45 }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormSearchTeacher
                        required={true}
                        teacher={item.teacher}
                        setTeacher={(teacher) => setItem(prev => {
                            return {
                                ...prev,
                                teacher: teacher
                            }
                        })}

                    ></FormSearchTeacher>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormSeachDayOfWeek
                        disabled={item?.dayOfTheWeek?.disable ? item?.dayOfTheWeek?.disable : false}
                        required={false}
                        dayOfWeek={item?.dayOfTheWeek?.disable ? null : item.dayOfTheWeek}
                        setDayOfWeek={(dayOfWeek) => {
                            setItem(prev => {
                                let dataType = new DayOfWeek();
                                prev.dayOfTheWeek = Object.assign(dataType, dayOfWeek);
                                return {
                                    ...prev
                                };
                            })
                        }}
                    ></FormSeachDayOfWeek>
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormSeachType
                        required
                        setType={(type) => setItem(prev => {
                            let dataType = new Type();
                            prev.type = Object.assign(dataType, type);
                            return {
                                ...prev
                            };
                        })}
                        type={item.type}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <DateField
                        label={"Data inicial"}
                        value={dayjs(item.startDate)}
                        onChange={(newValue) => setItem(prev => {
                            return {
                                ...prev,
                                startDate : new Date(Date.parse(newValue.$d))
                            };
                        })}
                        slotProps={{ textField: { fullWidth: true } }}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <DateField
                        label={"Data final"}
                        value={dayjs(item.endDate)}
                        minDate={item.startDate.toDateString() ?? null}
                        onChange={(newValue) => setItem(prev => {
                            return {
                                ...prev,
                                endDate : new Date(Date.parse(newValue.$d))
                            };
                        })}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TimeField 
                        required 
                        value={dayjs(starTime)}
                        label={"horario inical"}
                        onChange={(newValue) => {
                            setStarTime(newValue);
                            setItem(prev => {
                                return {
                                    ...prev,
                                    startTime : `${new String(newValue.$H).padStart(2, '0')}:${new String(newValue.$m).toString().padStart(2, '0')}`
                                }
                            })
                        }}
                    ></TimeField>
                </Grid>

                <Grid item xs={12} md={3}>
                    <TimeField 
                        required
                        value={dayjs(endTime)}
                        minTime={dayjs(item?.endTime).hour() ?? null}
                        label={"horario final"}
                        onChange={(newValue) => {
                            setEndTime(newValue);
                            setItem(prev => {
                                return {
                                    ...prev,
                                    endTime : `${newValue.$H.toString().padStart(2, '0')}:${newValue.$m.toString().padStart(2, '0')}`
                                }
                            })
                        }}    
                    ></TimeField>
                </Grid>

                <Grid item xs={12}>
                    <LoadingButton 
                        type="submit"
                        variant="outlined"
                        loading={buttonLoading}
                    >{"Salvar"}</LoadingButton>
                </Grid>
        
                {(isNumber(item?.id) && item?.id > 0) && <Grid item xs={12}>
                    <Button
                        onClick={(e) => limpar()}
                        variant="outlined"
                        color='error'
                    >Cancelar</Button>
                </Grid>}
            </Grid>
        </LocalizationProvider>
    </form>;
}