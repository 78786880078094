import ServicoMunicipal from "class/omie/servico/ServicoMunicipal";
import BaseService from "service/BaseService";
import verbify from "service/verbify/config";

export default class ServicoMunicipalService extends BaseService {
    /**
     * @private
     */
    static _baseUrl = 'omie/servico/servicoMunicipal';
    constructor () {
        super(ServicoMunicipalService._baseUrl);
    }
    /**
     * @returns {Promise<import("axios").AxiosResponse<ObjectResponseList<ServicoMunicipal>, any>>}
     */
    list () {
        return verbify.get(`${this.baseUrl}`);
    }
}